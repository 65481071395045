import React, { useEffect, useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
import { Roles } from "../models";
import _ from "lodash";
import UserContext from '../contexts/UserContext';

/**
 * Generates a list of role options based on permissions and roles.
 *
 * @param {Object} permissions - The permissions object to check roles against.
 * @returns {Array} - Array of roles with read permissions.
 */
const getRoleOptions = (permissions) => {
    return _.reduce(Roles, (result, value) => {
        if (permissions[value]?.Read) {
            result.push({ value, label: value });
        }
        return result;
    }, []);
}

const Home = (props) => {
    const [fullName, setFullName] = useState();
    const [roleOptions, setRoleOptions] = useState();
    const [currentRoutes, setCurrentRoutes] = useState();
    const currentUser = useContext(UserContext);

    useEffect(() => {
        if (!currentUser || !currentUser.currentUser || !currentUser.currentUser.currentGroup) return;

        const newRoleOptions = getRoleOptions(currentUser.currentUser.currentGroup);

        if (JSON.stringify(newRoleOptions) !== JSON.stringify(roleOptions)) {
            setRoleOptions(newRoleOptions);
        }
        // eslint-disable-next-line
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser || !currentUser.currentUser || !currentUser.currentUser.currentGroup) return;

        const getRoutes = (routes) => {
            return routes.flatMap((route, key) => {
                if (route.collapse) return getRoutes(route.views);
                if (route.permission.includes(currentUser.currentUser.currentGroup)) {
                    const CustomComponent = route.component;
                    return (
                        <Route
                            path={route.layout + route.path}
                            key={key}
                            component={() => <CustomComponent {...props} permissions={currentUser.permissions} roleOptions={roleOptions} />}
                        />
                    );
                }
                return null;
            });
        };

        setFullName(currentUser.currentUser.attributes.given_name + " " + currentUser.currentUser.attributes.family_name);
        setCurrentRoutes(getRoutes(routes));
    }, [currentUser, props, roleOptions]);

    if (!currentUser || !currentUser.currentUser) {
        console.error("Invalid user context"); // Logging for better debugging
        return null; // Render nothing if there's no valid user context
    }

    return (
        <div className="wrapper">
            <Sidebar
                routes={routes}
                image={null}
                background="black"
                username={currentUser.currentUser.username || "User"}
                currentUser={currentUser.currentUser || "User"}
                fullName={fullName}
                groups={props.groups}
                onUserIdChange={props.onUserIdChange}
            />
            <div className="main-panel">
                <AdminNavbar signOut={props.signOut} />
                <div className="content">
                    <Switch>{currentRoutes}</Switch>
                </div>
                <AdminFooter />
                <div
                    className="close-layer"
                    onClick={() => document.documentElement.classList.toggle("nav-open")}
                />
            </div>
        </div>
    );
}

export default Home;
