import React from "react";
import { Button, Tooltip, OverlayTrigger, Container } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const DeleteItemButton = ({
    permissions,
    action,
    item,
    hideAlert,
    notificationAlertRef,
    setAlert,
}) => {

    if (permissions) {
        return (
            <OverlayTrigger
                href="#"
                onClick={(e) => e.preventDefault()}
                overlay={
                    <Tooltip id="tooltip-981231696">
                        Delete
                    </Tooltip>
                }>
                <Button
                    className="btn-link btn-xs"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault()
                        setAlert(
                            <SweetAlert
                                showCancel={true}
                                title="Confirm Deletion"
                                style={{ display: "block", overflow: "hidden", width: "50%" }}
                                onConfirm={() => {

                                    action(item, hideAlert, notificationAlertRef)
                                }}
                                onCancel={() => hideAlert()}
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle={"danger"}
                            >
                                <Container>
                                    <h4>Are you sure you want to delete this item?</h4>
                                </Container>

                            </SweetAlert>
                        );
                    }
                    }
                    variant="danger">
                    <i className="fas fa-times"></i>
                </Button>
            </OverlayTrigger>
        )
    } else {
        return null
    }
};

export default DeleteItemButton;
