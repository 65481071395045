export const getDistrictsAndBuildings = /* GraphQL */ `
  query GetDistricts($id: ID!) {
    getDistricts(id: $id) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        items {
          id
          name
          identifier
          district_id
          district_name
          street
          city
          state
          zip
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      schoolYear {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const usersByRoleAndStatus = /* GraphQL */ `
  query UsersByRoleAndStatus(
    $role: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRoleAndStatus(
      role: $role
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listUsersByRole = /* GraphQL */ `
  query UsersByRoleAndStatus(
    $role: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $buildingFilter: ModelUsersBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRoleAndStatus(
      role: $role
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items{
        id
        userId
        stateId
        accessCardId
        email
        firstName
        lastName
        image {
          bucket
          key
          region
        }
        role
        cohort
        permissions
        cognito_user
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        buildings (filter: $buildingFilter){
          items {
            buildingsId
            buildings{
              id
              name
              identifier
            }
            id
            usersId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      userId
      stateId
      accessCardId
      email
      firstName
      lastName
      role
      cohort
      permissions
      cognito_user
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listClasses = /* GraphQL */ `
  query ListClasses(
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        schoolYear {
          id
          name
          startDate
          endDate
          district_name
          active
          district_id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        building {
          id
          name
          identifier
          district_id
          district_name
          street
          city
          state
          zip
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        grade
        users {
            items {
                id
            }
            nextToken
            startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getClassesEdit = /* GraphQL */ `
  query GetClasses($id: ID!) {
    getClasses(id: $id) {
      id
      systemClassId
      credits
      stateClassId
      name
      description
      subject
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      schoolYear {
        id
        name
        startDate
        endDate
        active
        _version
      }
      building {
        id
        name
        identifier
      }
      instructor_classes(filter: {status: {eq: Active}}) {
        items {
          id
          instructor_id
          _version
          instructor {
            lastName
            firstName
            _version
            id
          }
        }
      }
      students_classes(filter: {status: {eq: Active}}) {
        items {
          id
          stateId
          status
          _version
          student {
            firstName
            id
            lastName
            status
            _version
          }
        }
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;
export const getUsersBuildings = /* GraphQL */ `
  query GetUsersBuildings($id: ID!) {
    getUsersBuildings(id: $id) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        firstName
        lastName
        image {
          bucket
          key
          region
        }
        role
        cohort
        permissions
        cognito_user
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        ais_support {
          nextToken
          startedAt
        }
        demographics {
          nextToken
          startedAt
        }
        buildings {
          nextToken
          startedAt
        }
        classes {
          nextToken
          startedAt
        }
        district {
          id
          name
          street
          city
          state
          zip
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        student_records {
          nextToken
          startedAt
        }
        student_standard_records {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        district {
          id
          name
          street
          city
          state
          zip
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        classes {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listUsersClassesByClassId = /* GraphQL */ `
  query ListUsersClassesByClassId(
    $classesId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listUsersClasses(filter: { classesId: { eq: $classesId } }, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersId
        classesId
        users {
          id
          userId
          stateId
          accessCardId
          email
          firstName
          lastName
          role
          cohort
          permissions
          cognito_user
          cognito_created
          active
          in_school
          allow_lunch
          allow_building_entry
          current_class_id
          district_id
          district_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        classes {
          id
          systemClassId
          name
          description
          subject
          schoolYear_name
          schoolYear_id
          building_name
          building_id
          district_id
          district_name
          color
          rgbActive
          rgbNotActive
          grade
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        district {
          id
          name
          street
          city
          state
          zip
          phone
          website
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $filter: ModelDistrictsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistricts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        street
        city
        state
        zip
        phone
        website
        buildings {
          nextToken
          startedAt
        }
        schoolYear {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listUsersClasses = /* GraphQL */ `
query ListUsersClasses($limit: Int, $nextToken: String) {
  listUsersClasses(limit: $limit, nextToken: $nextToken) {
    items {
      id
      classesId
      usersId
      _version
    }
    nextToken
  }
}
`;

export const deleteUsersClasses = /* GraphQL */ `
  mutation DeleteUsersClasses(
    $input: DeleteUsersClassesInput!
    $condition: ModelUsersClassesConditionInput
  ) {
    deleteUsersClasses(input: $input, condition: $condition) {
      id
      usersId
      classesId
    }
  }
`;

export const getTeacher = /* GraphQL */ `
  query GetUsers(
      $id: ID!
      $schoolYear_name: ModelStringKeyConditionInput
  ) {
    getUsers(id: $id) {
      userId
      stateId
      lastName
      firstName
      role
      permissions
      district_id
      buildings {
        items {
          users {
            id
            firstName
            lastName
            role
            stateId
            active
          }
          buildings {
            id
            name
          }
        }
      }
    }

  }
`;

export const getTeacherClasses = /* GraphQL */ `
  query GetUsers(
    $id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
  ) {
    getUsers(id: $id) {
      userId
      stateId
      lastName
      firstName
      role
      permissions
      district_id
      instructor_classes(schoolYear_name: $schoolYear_name) {
        items {
          id
          name
          description
          instructor_id
          userId
          stateId
          class_id
          systemClassId
          schoolYear_id
          schoolYear_name
          district_id
        }
      }
      buildings {
        items {
          users {
            id
            firstName
            lastName
            role
            stateId
            active
          }
          buildings {
            id
            name
          }
        }
      }
    }
  }
`;
export const listRecordTypes = /* GraphQL */ `
    query ListRecordTypes(
        $filter: ModelRecordTypeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listRecordTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                description
                district_id
                building_id
                standard_id
                standard_name
                all_classes
                multiple
                dataTable_name
                assignAble
                schemaOnly
                active
                lineItems {
                    items {
                      id
                      recordTypeId
                      lineItemTypeId
                      _deleted
                      _version
                        lineItemType {
                            name
                            id
                            index
                            description
                        }
                    }    
                }
                grade
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;

export const getClassRoster = /* GraphQL */ `
  query StudentsClassesByClass_idAndSchoolYear_name(
    $class_id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsClassesByClass_idAndSchoolYear_name(
      class_id: $class_id
      schoolYear_name: $schoolYear_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        student_id
        userId
        stateId
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        student {
          id
          userId
          stateId
          email
          status
          firstName
          lastName
          role
          cohort
 
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        file {
          bucket
          key
          region
          __typename
        }
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsByIs_definitionAndType = /* GraphQL */ `
  query UploadsByIs_definitionAndType(
    $is_definition: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsByIs_definitionAndType(
      is_definition: $is_definition
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        definition
        is_definition
        district_id
        owner
        file {
          bucket
          key
          region
          __typename
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;
export const getLineItemsForRecord = /* GraphQL */ `
  query RecordTypeLineItemsByRecordTypeId(
    $recordTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypeLineItemsByRecordTypeId(
      recordTypeId: $recordTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesBySchoolYear_id = /* GraphQL */ `
  query ClassesBySchoolYear_id(
    $schoolYear_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesBySchoolYear_id(
      schoolYear_id: $schoolYear_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
          id
          systemClassId
          credits
          stateClassId
          name
          description
          subject
          schoolYear_name
          schoolYear_id
          building_name
          building_id
          district_id
          district_name
          color
          rgbActive
          rgbNotActive
          schoolYear {
              id
              name
              startDate
              endDate
              district_name
              active
          }
          building {
              id
              name
              identifier
          }
          grade
        _version
      }
      nextToken
      startedAt
    }
  }
  `;

export const recordTypeLineItemsByRecordTypeId = /* GraphQL */ `
  query RecordTypeLineItemsByRecordTypeId(
    $recordTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypeLineItemsByRecordTypeId(
      recordTypeId: $recordTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        recordType {
          id
          name
          description
          district_id
          building_id
          standard_id
          standard_name
          all_classes
          multiple
          dataTable_name
          assignAble
          schemaOnly
          active
          grade
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lineItemType {
          id
          name
          index
          description
          district_id
          type
          setOptions
          definedOptions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
