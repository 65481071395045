/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import TeachersDashboard from "./dashboards/teachers/Dashboard";
import Dashboard from "dashboards/Dashboard";
import UsersView from "views/Tables/UsersView";
import ClassesView from "views/Tables/ClassesView";
import BuildingsView from "views/Tables/BuildingsView";
import SchoolYearView from "./views/Tables/SchoolYearView";
import DistrictsView from "./views/Tables/DistrictsView";
import MyClassesView from "./views/Pages/MyClassesView";
import StudentSearch from "./views/Pages/StudentSearch";
import UploadedFilesView   from "./views/Tables/UploadedFiles";
import LineItemView from "./views/Tables/LineItemView";
import RecordTypeView from "./views/Tables/RecordTypeView";
import FileDefinitionsView from "./views/Tables/FileDefinitionsView";
import SystemSettings from "./views/Pages/SystemSettings";
import QuickSiteMgmt from "./views/Pages/QuickSiteMgmt";
import StudentView from "./views/Pages/StudentView";
import AddStudentRecord from "./views/Pages/AddStudentRecord";
import Wizard from "./views/Forms/UploadWizard/Wizard";
import Noah from "./views/Pages/NoaV2";
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCalendar } from '@fortawesome/free-regular-svg-icons';
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "./views/Pages/ChangePassword";
library.add(faCalendar);
library.add(faMagnifyingGlass);


let routes = [
  {
    path: "/dashboard",
    layout: "/home",
    permission: ["SuperAdmin","DistrictAdmin","BuildingAdmin"],
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard
  },
  {
    path: "/dashboard",
    layout: "/home",
    permission: ["Teachers"],
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard
  },
  {
    path: "/students/:id",
    layout: "/home",
    permission: ["SuperAdmin","DistrictAdmin","BuildingAdmin","Teachers"],
    name: "Student Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: StudentView,
    hidden: true
  },
  {
    path: "/add-student-record/:id",
    layout: "/home",
    permission: ["SuperAdmin","DistrictAdmin","BuildingAdmin","Teachers"],
    name: "Add Student Record",
    icon: "nc-icon nc-chart-pie-35",
    component: AddStudentRecord,
    hidden: true
  },
  {

    path: "/my-classes-view",
    name: "My Classes",
    layout: "/home",
    permission: ["SuperAdmin","Teachers","Staff","Students"],
    icon: "ark-icon ark-presentation",
    component: MyClassesView
  },
  {

    path: "/all-learners",
    name: "Learners",
    layout: "/home",
    permission: ["SuperAdmin","DistrictAdmin","BuildingAdmin"],
    icon: "ark-icon ark-presentation",
    component: StudentSearch
  },
  {

    path: "/noa",
    name: "N.O.A Beta",
    layout: "/home",
    permission: ["SuperAdmin",],
    icon: "fa-icon fa fa-magnifying-glass",
    component: Noah
  },
  {
    collapse: true,
    path: "/system-management",
    name: "System Management",
    state: "opnedSystemManagement",
    permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","Installer"],
    icon: "nc-icon nc-settings-90",
    views: [
      {
        path: "/system-settings",
        layout: "/home",
        permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","Installer"],
        name: "System Settings",
        iconMini: "ark-icon ark-cogwheel",
        component: SystemSettings
      },
      {
        path: "/dataManagement",
        layout: "/home",
        permission: ["SuperAdmin"],
        name: "Data Management",
        iconMini: "ark-icon ark-statistics",
        component: QuickSiteMgmt
      },
      {
        path: "/buildings",
        layout: "/home",
        permission: ["SuperAdmin","Installer","SystemAdmin","DistrictAdmin"],
        name: "Buildings Setup",
        iconMini: "ark-icon ark-building",
        component: BuildingsView
      },
      {
        path: "/users-view",
        layout: "/home",
        permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin", "BuildingAdmin", "Installer"],
        name: "User Administration",
        iconMini: "ark-icon ark-profile",
        component: UsersView
      },
      {
        path: "/classes-view",
        layout: "/home",
        permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin", "BuildingAdmin", "Installer"],
        name: "Classes Administration",
        iconMini: "ark-icon ark-presentation",
        component: ClassesView
      },
      {
        path: "/school-year-view",
        layout: "/home",
        permission: ["SuperAdmin","SystemAdmin","DistrictAdmin"],
        name: "School Year Setup",
        iconMini: "ark-icon ark-calendar",
        component: SchoolYearView
      },
      {
        path: "/districts-view",
        layout: "/home",
        permission: ["SuperAdmin","SystemAdmin","DistrictAdmin"],
        name: "District Setup",
        iconMini: "ark-icon ark-district",
        component: DistrictsView
      },
      {
        collapse: true,
        path: "/file-processing",
        name: "Data Processing",
        state: "openDataProcessing",
        permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin", "BuildingAdmin"],
        icon: "ark-icon ark-archive-drawer",
        views: [
          {
            path: "/files",
            layout: "/home",
            permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin"],
            name: "Uploaded Data",
            iconMini: "ark-icon ark-folder",
            component: UploadedFilesView
          },
          {
            path: "/new-file-definition",
            layout: "/home",
            permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin"],
            name: "File Setup",
            iconMini: "ark-icon ark-file-settings",
            component: FileDefinitionsView
          },
          {
            path: "/new-data-wizard",
            layout: "/home",
            permission: ["SuperAdmin", "SystemAdmin", "DistrictAdmin"],
            name: "File Upload Wizard",
            iconMini: "ark-icon ark-cloud-upload",
            component: Wizard
          }
        ]
      },
      {
        path: "/change-password",
        layout: "/home",
        permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","BuildingAdmin","Installer","Teachers","Staff","Students"],
        name: "Change Password",
        mini: "C",
        component: ChangePassword,
        hidden: true
      },
      {
        collapse: true,
        path: "/record-setup",
        name: "Custom Forms",
        state: "openRecordSetup",
        permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","BuildingAdmin"],
        icon: "ark-icon ark-data-settings",
        views: [
          {
            path: "/record-type-view",
            layout: "/home",
            permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","BuildingAdmin"],
            name: "Form Type",
            iconMini: "ark-icon ark-database",
            component: RecordTypeView
          },
          {
            path: "/line-item-view",
            layout: "/home",
            permission: ["SuperAdmin","SystemAdmin","DistrictAdmin","BuildingAdmin"],
            name: "Fields",
            iconMini: "ark-icon ark-row-table",
            component: LineItemView
          }
          ]
      }
    ]
  }
];
export default routes;
