import { API, graphqlOperation } from "aws-amplify";
import notificationOptions from "../views/Components/Notifications";
import { usersBuildingsByUsersId, usersClassesByUsersId } from "../graphql/queries";
import { listUsersByRole, updateUsers } from "../graphql/customQueries";
import { createUsers, deleteUsers } from "../graphql/mutations";
import { createUsersBuildings, deleteUsersBuildings, deleteUsersClasses } from "../graphql/mutations";
import { getUsers } from "../graphql/queries";

export const getUsersData = async (role) => {
    try {
        const usersResult = await API.graphql(graphqlOperation(listUsersByRole, { role: role }));
        return usersResult.data.usersByRole.items;
    } catch (error) {
        console.error("Error fetching users data:", error);
        return [];
    }
};

const mapNewBuildingsToInput = (newBuildings) => {
    return newBuildings.map((building) => ({
        input: {
            usersId: building.usersId,
            buildingsId: building.buildingsId,
        },
    }));
};

const mapDeletedBuildingsToInput = (deletedBuildings) => {
    return deletedBuildings.map((building) => ({
        input: {
            id: building.id,
            _version: building._version,
        },
    }));
};

async function createAuthAccount(user) {
    try {
        let apiName = 'oneschool';
        let path = '/users/new';
        let myInit = {
            body: {
                "email": user.data.createUsers.email,
                "firstName": user.data.createUsers.firstName,
                "lastName": user.data.createUsers.lastName,
                "role": user.data.createUsers.role,
                "district_id": user.data.createUsers.district_id,
                "district_name": user.data.createUsers.district_name,
                "permissions": user.data.createUsers.permissions,
                "user_id": user.data.createUsers.id,
                "status": "Active",
                "active": true
            },
        };
        await API.post(apiName, path, myInit);
        return true;
    } catch (error) {
        console.debug('error signing up:', error);
        return false;
    }
}

export const createNewUsers = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        let buildings = [];
        if (formData.hasOwnProperty("buildings")) {
            buildings = formData.buildings;
        }
        delete formData.buildings;
        delete formData.values;

        if (!formData.hasOwnProperty("cohort")) {
            formData.cohort = "0000";
        }
        if (!formData.hasOwnProperty("stateId")) {
            formData.stateId = formData.usersId;
        }
        const user = await API.graphql({
            query: createUsers,
            variables: { input: formData },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        if (buildings !== null) {
            buildings.map(async (building) => {
                const data = {
                    query: createUsersBuildings,
                    variables: { input: { usersId: user.data.createUsers.id, buildingsId: building.id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                }
                await API.graphql(data);
            });
        }

        if (formData.cognito_user) {
            if (await createAuthAccount(user)) {
                hideAlert();
                let o = notificationOptions("User Created Successfully", "success");
                notificationAlertRef.current.notificationAlert(o);
            } else {
                hideAlert();
                let o = notificationOptions(`Error While Creating App User Account`, "danger");
                notificationAlertRef.current.notificationAlert(o);
            }
        } else {
            hideAlert();
            let o = notificationOptions("User Created Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
        }

    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Creating User ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const uUsers = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        const userResponse = await API.graphql(graphqlOperation(getUsers, { id: current.id }));
        const user = userResponse.data.getUsers;
        let updateMulti = false;
        let buildings = {};
        if (formData.hasOwnProperty("buildings")) {
            buildings = formData.buildings;
            delete formData.buildings;
            updateMulti = true;
        }

        let updatedUserInput = {...user, ...formData};

        const propertiesToDelete = [ "createdAt", "updatedAt", "_deleted", "_lastChangedAt", "__typename"];
        Object.keys(updatedUserInput).forEach((property) => {
            if(updatedUserInput[property] instanceof Object || updatedUserInput[property] instanceof Array){
                delete updatedUserInput[property];
            }
            if(propertiesToDelete.includes(property)){
                delete updatedUserInput[property];
            }
        });
        console.log("updatedUserInput", updatedUserInput)
        const updatedUserResponse = await API.graphql({
            query: updateUsers,
            variables: { input: {...updatedUserInput} },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        const updatedUser = updatedUserResponse.data.updateUsers;

        if (updateMulti) {
            console.debug("buildings", buildings);
            const userBuildingsResponse = await API.graphql(graphqlOperation(usersBuildingsByUsersId, {
                usersId: current.id,
                filter: {_deleted: {ne: true}}
            }));
            console.debug("userBuildingsResponse", userBuildingsResponse);
            const userBuildingsToUpdate = userBuildingsResponse.data.usersBuildingsByUsersId.items;
            console.debug("userBuildingsToUpdate", userBuildingsToUpdate);
            const userBuildingsMap = userBuildingsToUpdate.reduce((map, ub) => {
                map[ub.buildingsId] = ub;
                return map;
            }, {});
            console.debug("userBuildingsMap", userBuildingsMap)

            const newBuildings = [];
            const deletedBuildings = [];

            if (buildings != null) {
                buildings.forEach((building) => {
                    if (userBuildingsMap.hasOwnProperty(building.id)) {
                        console.log(`"Building ${building.id} already exists for user ${current.id}"`);
                        console.log("building", building);
                        console.log(`userBuildingsMap`, userBuildingsMap);
                        delete userBuildingsMap[`${building.id}`];
                    } else {
                        console.debug(`"Building ${building.id} does not exist for user ${current.id}, adding building"`);
                        newBuildings.push({
                            usersId: current.id,
                            buildingsId: building.id,
                        });
                    }
                });
            }

            for (const ubId in userBuildingsMap) {
                console.debug(`"Building ${ubId} was removed from ${current.id}"`);
                deletedBuildings.push({ id: userBuildingsMap[ubId].id, _version: userBuildingsMap[ubId]._version });
            }

            if (newBuildings.length > 0) {
                const newBuildingsInputArray = mapNewBuildingsToInput(newBuildings);
                await Promise.all(
                    newBuildingsInputArray.map((buildingInput) =>
                        API.graphql(
                            graphqlOperation(createUsersBuildings, buildingInput)
                        )
                    )
                );
            }

            if (deletedBuildings.length > 0) {
                const deletedBuildingsInputArray = mapDeletedBuildingsToInput(deletedBuildings);
                console.log("deletedBuildingsInputArray", deletedBuildingsInputArray);
                await Promise.all(
                    deletedBuildingsInputArray.map((buildingInput) =>
                        API.graphql(
                            graphqlOperation(deleteUsersBuildings, {
                                ...buildingInput
                            })
                        )
                    )
                );
            }
        }



        if (formData.cognito_user && user.cognito_user === false) {
            if (await createAuthAccount(updatedUser)) {
                hideAlert();
                let o = notificationOptions("User Updated Successfully", "success");
                notificationAlertRef.current.notificationAlert(o);
            } else {
                hideAlert();
                let o = notificationOptions(`User Updated Successfully`, "danger");
                notificationAlertRef.current.notificationAlert(o);
            }
        } else {
            hideAlert();
            let o = notificationOptions("User Updated Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
        }
    } catch (e) {
        console.error("Error:", e);
        hideAlert();
        let o = notificationOptions(`Error While Updating Users ${ e }`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};



export const dUsers = async (item, hideAlert, notificationAlertRef) => {
    try {
        const res = await API.graphql({
            query: deleteUsers,
            variables: { input: { id: item.id, _version: item._version } },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        if (res) {

            hideAlert();
            let o = notificationOptions("User Was Deleted Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
        }

    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
    // get all the user class relationships for the user from the userClasses table using usersClassesByUsersId
    try{
        const usersClasses = await API.graphql(graphqlOperation(usersClassesByUsersId, {usersId: item.id}));
        // Use the deleteUsersClasses mutation to delete the user class relationships in parallel
        console.debug("fetched users classes", usersClasses);
        await Promise.all(usersClasses.data.usersClassesByUsersId.items.map(async (userClass) => {
            API.graphql(graphqlOperation(deleteUsersClasses, {input: {id: userClass.id, _version: userClass._version}}));
        }));
        console.log("Deleted all users classes");
    } catch (e) {
        console.log(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users Classes ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }

    // get all the user building relationships for the user from the usersBuildings table using usersBuildingsByUsersId
    try{
        const usersBuildings = await API.graphql(graphqlOperation(usersBuildingsByUsersId, {usersId: item.id}));
        // Use the deleteUsersBuildings mutation to delete the user building relationships in parallel
        console.log("fetched users buildings", usersBuildings);
        await Promise.all(usersBuildings.data.usersBuildingsByUsersId.items.map(async (userBuilding) => {
            API.graphql(graphqlOperation(deleteUsersBuildings, {input: {id: userBuilding.id, _version: userBuilding._version}}));
        }));
        console.log("Deleted all users buildings");
    } catch (e) {
        console.log(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users Buildings ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};
