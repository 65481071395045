export const accessRights = {
        user: {},
        Students : [],
        Guardian : [],
        Teachers : {
                Students: {
                        Read: true,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Staff: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Classes: {
                        Read: true,
                        Create: false,
                        Update: true,
                        Delete: false
                },
                Buildings: {
                        Read: true,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Guardian: {
                            Read: true,
                            Create: false,
                            Update: false,
                            Delete: false
                        },
                Assessments: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                RecordType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true,
                        StudentCreate: true,
                        StudentUpdate: true,
                        StudentDelete: false,
                        StudentRead: true
                },
                LineItemType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                SchoolYear: {
                        Read: true,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Districts: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                }
        },
        BuildingAdmin : {
                Students: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Teachers: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Staff: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Classes: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Buildings: {
                        Read: true,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Guardian: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                RecordType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                LineItemType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Assessments: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                SchoolYear: {
                        Read: true,
                        Create: false,
                        Update: false,
                        Delete: false
                },
            Districts: {
                    Read: false,
                    Create: false,
                    Update: false,
                    Delete: false
            }
        },
        DistrictAdmin : {
                Students: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Teachers: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Staff: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Classes: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Administrators: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Buildings: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Guardian: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                RecordType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                LineItemType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Assessments: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                SchoolYear: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                BuildingAdmin: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Districts: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                }
        },
        SystemAdmin : {
                Student: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Teachers: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Staff: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Buildings: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Guardian: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Assessments: {
                        Read: false,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                SchoolYear: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                RecordType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                LineItemType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                BuildingAdmin: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                DistrictAdmin:{
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Districts: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                }
        },
        SuperAdmin : {
                Students: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Districts: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: false
                },
                Teachers: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Staff: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Administrators: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Buildings: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Guardians: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },

                Classes:{
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                RecordType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true,
                        StudentCreate: true,
                        StudentUpdate: true,
                        StudentDelete: false,
                        StudentRead: true
                },
                LineItemType: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Assessments: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                SchoolYear: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                BuildingAdmin: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                DistrictAdmin:{
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                SystemAdmin:{
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                }
        },
        Installer : {
                Students: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Staff: {
                        Read: true,
                        Create: true,
                        Update: true,
                        Delete: true
                },
                Classes: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Buildings: {
                        Read: true,
                        Create: true,
                        Update: false,
                        Delete: false
                },
                Guardian: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                Assessments: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                },
                SchoolYear: {
                        Read: false,
                        Create: false,
                        Update: false,
                        Delete: false
                }
        },
}

