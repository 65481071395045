import React, { useState, useEffect, useContext, useRef } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/TableV2';
import {listUsersByRole} from '../../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { Col, Container, Row, Card,Button } from 'react-bootstrap';
import NotificationAlert from "react-notification-alert";
import Embed from "../../components/QuickSite/Embed";
import ShowDashboardButton from "../../components/Buttons/ShowDashboardButton";
import "./MyClasses.scss"



const StudentSearch = (props) => {
    //eslint-disable-next-line
    const currentUser = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const notificationAlertRef = useRef(null);
    // eslint-disable-next-line
    const [alert, setAlert] = React.useState(null);
    const [showTable, setShowTable] = useState(false);
    const [showDashboard, setShowDashboard] = useState(true);
    const [dashBoardParams, setDashBoardParams] = useState({});
    const [buildingCount, setBuildingCount] = useState(0);


    const columns = [
        {
            Header: 'First Name',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
        },
        {
            Header: 'Building',
            accessor: 'buildings',
        },
        {
            Header: "Dashboard",
            id: "modify",
            Cell: ({row}) => {
                return (
                    <ShowDashboardButton
                        row={row.original}
                        setShowTable={setShowTable}
                        setDashBoardParams={setDashBoardParams}
                        setShowDashboard={setShowDashboard}
                    />

                )

            }
        }
    ]
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let nextToken = null;
                let allItems = [];
                const mapInside = "student";
                do {
                    const result = await API.graphql(graphqlOperation(listUsersByRole, {
                        ...{ role: "Students", status: {eq: "Active"}, limit: 1000},
                        // Fetch 10 items per request
                        nextToken: nextToken  // Pass the nextToken from the previous request (null for the first request)
                    }));
                    if( Object.values(result.data)[0].items &&
                        Object.values(result.data)[0].items.length > 0) {
                        allItems = [...allItems, ...Object.values(result.data)[0].items];
                    }
                    if (Object.values(result.data)[0].nextToken) nextToken = Object.values(result.data)[0].nextToken
                    else nextToken=null;


                } while (nextToken);
                // If props.mapInside exists, that means that instead of taking items we need to take items.mapInside for each item
               let buildings = []
                if (mapInside) {
                    setData(allItems.map((item) => {
                        try {
                            if (item.buildings  && item.buildings.items){
                                const buildingName = item.buildings.items[0].buildings.name;
                                if (buildingName && !buildings.includes(buildingName)) {
                                    buildings.push(buildingName);
                                }
                            }
                            return item;
                        }
                        catch {
                            return [];
                        }

                    }));
                    setBuildingCount(buildings.length);

                } else {
                    setData([...allItems]);
                }
            } catch (error) {
                //console.log("Error fetching some data data:", error);
                // setData(error.data[propQueryName].items);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <Container fluid>
                <Row className="justify-content-center">
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-backpack text-warning"></i>
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Students</p>
                                            <Card.Title as="h4">{data.length}</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="3" sm="6">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-map-big text-success"></i>
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Buildings</p>
                                            <Card.Title as="h4">{buildingCount}</Card.Title>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            <Row className="justify-content-left">
                <Col lg="8" sm="8"  hidden={showDashboard}>
                    {!showDashboard && (
                    <Button
                        className="btn-round"
                        variant="dark"
                        onClick={() => {
                            setShowTable(false)
                            setShowDashboard(true)
                        }
                        }
                    >
                        <span className="btn-label justify-content-center">
                          <i className="nc-icon nc-zoom-split"> Back to Search</i>

                        </span>
                    </Button>
                    )}

                </Col>
            </Row>
            <Row>
                {alert}
                <NotificationAlert ref={notificationAlertRef} />
                <Col xs={12} md={12} lg={12} hidden={showTable} className={"justify-content-center"}>
                    {!showTable && (
                        <div>
                            <Table
                                permission={true}
                                headers={columns}
                                data={data}
                                loading={loading}
                                CreatItemAction={null}
                                option={false}
                                itemType="Student Search"
                            />
                        </div>
                    )}
                </Col>
                <Row  hidden={showDashboard}>
                    {!showDashboard && (
                        <Embed
                            containerDiv = "MyClass-Tab-dashboardContainer"
                            embedType="dashboard"
                            dashboardParams={dashBoardParams}
                            dashboardView={"StudentView"}
                            {...props}
                        />
                    )}
                </Row >
            </Row>
        </Container>
    );
}

export default StudentSearch;
