export function  setInitialSettings(systemSettings) {
    const init = {
        Dashboards:{
            DistrictAdmin:{ value: "", name: ""},
            BuildingAdmin:{ value: "", name: ""},
            Teachers:{ value: "", name: ""},
            StudentHistory:{ value: "", name: ""},
            Students:{ value: "", name: ""},
            Guardians:{ value: "", name: ""},
            SuperAdmin:{ value: "", name: ""}

         },
        DashboardRoles:{
            DistrictAdmin:{ value: "", name: ""},
            BuildingAdmin:{ value: "", name: ""},
            Teachers:{ value: "", name: ""},
            StudentHistory:{ value: "", name: ""},
            Students:{ value: "", name: ""},
            Guardians:{ value: "", name: ""},
            SuperAdmin: { value: "", name: ""}
        }
    }
    if(systemSettings === null || systemSettings === undefined || !systemSettings || !systemSettings["settings"]){
        return {...init}
    }
        const updated = JSON.parse(systemSettings["settings"])
            let t = {}
            if (Object.hasOwn(updated,"Dashboards")){
                t.Dashboards = {...init.Dashboards, ...updated.Dashboards}
            }
            else {
                t.Dashboards = {...init.Dashboards}
            }
            if (Object.hasOwn(updated,"DashboardRoles")) {
                t.DashboardRoles = {...init.DashboardRoles, ...updated.DashboardRoles}
            } else {
                t.DashboardRoles = {...init.DashboardRoles}
            }
            return {...t}


}

export function initSettingsOptions(options){
    const init = {value:[], name:[]}
    if(options === null || options === undefined){
        return {...init}
    }
    return {...options}
}
