import React, {useEffect,useContext} from "react";
import Select from "react-select";
import {handleDashboardChanges} from "../../utils/db_systemSystems";
import {get_dashboard_list,getIAMRoles} from "../../functions/apiCalls";
import {SubscribeSystemSettings}  from "../../utils/subscriptions/systemSettings";
import {setInitialSettings,initSettingsOptions} from "../../functions/initSettings";
import UserContext from '../../contexts/UserContext';
// react-bootstrap components
import {
    Card,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
// AMPLIFY COMPONENTS
const settingsGroups = [
    {label: "District Administration", role: "DistrictAdmin", disable: false},
    {label: "Building Administration", role: "BuildingAdmin", disable: false},
    {label: "Classroom Teacher's", role: "Teachers", disable: false},
    {label: "Student History", role: "StudentHistory", disable: false},
    {label: "Student's", role: "Students", disable: true},
    {label: "Parent/Guardian", role: "Guardians", disable: true},
    {label: "Super System Admin", role: "SuperAdmin", disable: true}]
function SystemSettings(props){

    const [settingsOptionsState, setSettingsOptionsState] = React.useState({dashboardIds:[], rolearns:[]});
    const [stateSettings, setStateSettings] = React.useState(null);
    const systemSettings = React.useMemo(() => (setInitialSettings(stateSettings)) , [stateSettings]);
    const settingsOptions = React.useMemo(() => (initSettingsOptions(settingsOptionsState)) , [settingsOptionsState]);
    const currentUser = useContext(UserContext);

    SubscribeSystemSettings(currentUser.currentUser.district_id, setStateSettings);
    useEffect(
        () => {
            console.debug("UseEffect SystemSettings")
            console.debug(settingsOptions)
        }, [settingsOptions]
    )
    useEffect(() => {
        async function fetchData() {
            try {
                const roleOptions = await getIAMRoles();
                const dashboardOptions = await get_dashboard_list();

                setSettingsOptionsState(prevState => ({
                    ...prevState,
                    dashboardIds: [...dashboardOptions],
                    rolearns: [...roleOptions],
                }));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
        fetchData();
    }, []);


return (
    <>
        <Container fluid>
            <Row>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Dashboard Settings</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group as={Col}>
                                    {settingsGroups.map((group) => (
                                    <Form.Group as={Row} key={group.role+"-Group"}>
                                        <Form.Label column sm={4}>
                                            {group.label}
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                isDisabled={group.disable}
                                                id={group.role+"-id"}
                                                name={group.role}
                                                options={settingsOptions.dashboardIds}
                                                value={settingsOptions.dashboardIds.find((settingsOptions) => settingsOptions.value === systemSettings.Dashboards[group.role].value)}
                                                onChange={(e) => handleDashboardChanges(e, group.role,  props.user.attributes["custom:district_id"], "Dashboards")}
                                            />
                                        </Col>
                                    </Form.Group>
                                ))}
                                </Form.Group>
                                </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6">
                    <Card>
                        <Card.Header>
                            <Card.Title as="h4">Dashboard Role Settings</Card.Title>
                        </Card.Header>
                        <Card.Body>
                             <Form>
                                <Form.Group as={Col}>
                                    {settingsGroups.map((group) => (
                                        group.role !== "StudentHistory" ? (
                                        <Form.Group as={Row} key={group.role+"-roleArnKey"}>
                                        <Form.Label column sm={4}>
                                            {group.label}
                                        </Form.Label>
                                        <Col sm={6}>
                                            <Select
                                                className="react-select info"
                                                classNamePrefix="react-select"
                                                isDisabled={group.disable}
                                                id={group.role+"-rolearnid"}
                                                name={group.role}
                                                options={settingsOptions.rolearns}
                                                value={settingsOptions.rolearns.find((settingsOptions) => settingsOptions.value === systemSettings.DashboardRoles[group.role].value)}
                                                onChange={(e) => handleDashboardChanges(e, group.role,  props.user.attributes["custom:district_id"], "DashboardRoles")}
                                            />
                                        </Col>
                                    </Form.Group>) : null
                                 ))}
                            </Form.Group>
                         </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
    </>
)

}

export default SystemSettings;
