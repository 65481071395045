import React, { useEffect, useState, useMemo } from 'react';
// import UserContext from '../../contexts/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { Table as BootstrapTable, Dropdown, DropdownButton, Pagination, Container, Row, Col } from 'react-bootstrap';
import "./Table.scss"

// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length;

    return (
        <input
            className="column-filter"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}
const Table = (props) => {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(25);
    const [pageCount, setPageCount] = useState(0);
    const CreatItemAction = props.CreatItemAction;
    const getRowActions = props.getRowActions;
    const propQuery = props.query;
    const propQueryParams = props.queryParams;
    const propQueryName = props.queryName;
    let propMapInside = null;
    console.debug("Query params: ", propQueryParams);
    if (props.hasOwnProperty("mapInside")) {
        propMapInside = props.mapInside;
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let nextToken = null;
                let allItems = [];
                do {
                    const result = await API.graphql(graphqlOperation(propQuery, {
                        ...propQueryParams,
                         // Fetch 10 items per request
                        nextToken: nextToken  // Pass the nextToken from the previous request (null for the first request)
                    }));
                    if( Object.values(result.data)[0].items &&
                        Object.values(result.data)[0].items.length > 0) {
                        allItems = [...allItems, ...Object.values(result.data)[0].items];
                    }
                    if (Object.values(result.data)[0].nextToken) nextToken = Object.values(result.data)[0].nextToken
                    else nextToken=null;


                } while (nextToken);
                // If props.mapInside exists, that means that instead of taking items we need to take items.mapInside for each item
                if (propMapInside) {
                    setData(allItems.map((item) => {
                        try {
                            return item[propMapInside];
                        }
                        catch {
                            return [];
                        }

                    }));
                } else {
                    console.debug("All Items: ",...allItems);
                    setData([...allItems]);
                }
            } catch (error) {
                //console.log("Error fetching some data data:", error);
               // setData(error.data[propQueryName].items);
            }
            setLoading(false);
        };

        fetchData();
    }, [propQuery, propQueryParams, propQueryName, propMapInside]);

    useEffect(() => {
        setPageCount(Math.ceil(data.length / pageSize));
    }, [data, pageSize]);


    const columns = useMemo(
        () => props.headers.map((header) => ({ Header: header, accessor: header, Filter: DefaultColumnFilter })),
        [props.headers]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        // setPageSize: updatePageSize,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            getRowActions,
            CreatItemAction,
            defaultColumn: {Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize },
        },
        useFilters,
        useSortBy,
        usePagination
    );
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(Number(newPageSize));
    };

    return (
        <Container fluid>
            { loading && (
                <Row>
                    <Col xs={12} className="text-center justify-content-center p-5">
                        <img src={require('../../assets/img/preloader.gif')}alt="loading..." />
                    </Col>
                </Row>
            )}
            {/* table structure */}
            {CreatItemAction ? <CreatItemAction /> : null}
            <BootstrapTable {...getTableProps()} striped bordered hover responsive hidden={loading}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-center column-width">
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                </th>
                            ))}
                            {props.option && <th className="text-center">Actions</th>}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {!loading &&
                        page.map((row) => {
                            prepareRow(row);
                            if (row.original._deleted){
                                // console.debug("skipping deleted row", row);
                                return null;
                            } else {

                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            let value;
                                            if (typeof cell.value === 'object' && cell.value !== null && cell.value.hasOwnProperty('items')) {
                                                if (cell.value.items[0] !== null) {
                                                    value = cell.value.items.map(x => {
                                                        if(x?._deleted){
                                                            return null;
                                                        }
                                                        if (x.hasOwnProperty('buildings')) {
                                                            //console.log("x.buildings.name: ", JSON.stringify(x.buildings.name))
                                                            if(typeof x.buildings.name === "string"){
                                                                return x.buildings.name;
                                                            } else {
                                                                console.log("x.buildings.name: ", JSON.stringify(x.buildings.name))
                                                                return null
                                                            }
                                                        } else if (x.hasOwnProperty('lineItems')) {
                                                            return x.lineItems.name;
                                                        } else {
                                                            return '';
                                                        }
                                                    }).join(', ');
                                                } else {
                                                    value = '';
                                                }
                                            } else {
                                                if (cell.value && typeof cell.value === "object") {
                                                    const names = cell.value.map((item) => {
                                                        if (item.hasOwnProperty('name')) {
                                                            return item.name;
                                                        }
                                                        return null;  // Return null or some default value if 'name' property doesn't exist
                                                    });
                                                    value = names.join(', ');  // Join the names into a string separated by commas
                                                    console.debug("Cell Value Object: ", value);
                                                }

                                                else {
                                                    value = cell.value;
                                                }
                                            }
                                            return <td {...cell.getCellProps()} className="text-center h4">{value}</td>;
                                        })}
                                        <td>
                                        {getRowActions(row).map((action, index) => {
                                                const ActionComponent = action.component;
                                                return <ActionComponent key={index} {...action.props} />;
                                            })
                                        }
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </BootstrapTable>

           <Row>
               <Col xs={12}>
                    <Pagination>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        <Pagination.Item  className="pagination-item" >{pageIndex + 1}</Pagination.Item>
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
               </Col>
               <Col xs={12} md={6}>
                    <DropdownButton
                        onSelect={handlePageSizeChange}
                        title={`Show ${pageSize} items`}>
                        {[25, 50, 75, 100].map((size) => (
                            <Dropdown.Item key={size} eventKey={size}>
                                {size}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
               </Col>
               </Row>
        </Container>
    );
};

export default Table;

