import React from "react";
import Embed from "../../components/QuickSite/Embed";
import {
    Container,
    Row
} from "react-bootstrap";

export default function QucikSiteMgmt(props) {
    return (
        <>
             <Container fluid>
                   <Row>
                    <Embed
                        containerDiv = "dashboardContainer"
                        embedType={"Admin"}
                        dashboardParams={{}}
                        {...props}/>
                   </Row>
             </Container>
            </>
    )
}
