import React, { useContext, useRef, useState, useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/TableV2';
import {uploadsByIs_definitionAndType} from '../../graphql/customQueries';
import NotificationAlert from "react-notification-alert";
import { NewFileDefinition} from '../Forms/FormFields';
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import EditItemButton from "../../components/Buttons/EditItemButton";
import {API, graphqlOperation} from "aws-amplify";
import {cFileType,uFileType} from "../../utils/db_file_definitions_graph";


const FileDefinitionsView = React.forwardRef((props, ref) => {
    const currentUser = useContext(UserContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const notificationAlertRef = useRef(null);
    const newItemForm = {
        name: "" ,
        type:"",
        type_id: "0000",
        is_definition: "Yes",
        schoolyear_id: "0000",
        owner: currentUser.selectedUser.value,
        status: "NA",
        definition:[{col: "textbox14", mapping: "Exam", type: "string"},{col: "StudentID_1", mapping: "stateId", type: "Student_GUID"},{col: "ScaledScore_1", mapping: "score", type: "number"},{col: "TeacherName", mapping: "instructor_id", type: "Teacher_Name_Mapping"}],
        district_id: currentUser.currentUser.district_id,
    };
    const [alert, setAlert] = React.useState(null);
    //eslint-disable-next-line
    const hideAlert = () => {
        setAlert(null);
    };
    const columns = [
        {
            Header: 'File Name',
            accessor: 'name'
        },
        {
            Header: 'Table Name',
            accessor: 'type',
        },
        {
            Header: "Modify",
            id: "modify",
            Cell: ({row}) => {
                return (
                    <EditItemButton
                        permissions={true}
                        action={uFileType}
                        FormFields={NewFileDefinition}
                        item={row.original}
                        editItem={true}
                        hideAlert={hideAlert}
                        notificationAlertRef={notificationAlertRef}
                        setAlert={setAlert}
                        itemType={"File Definition"}
                    />

                )

            }
        }
    ]
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const result = await API.graphql(
                    graphqlOperation(uploadsByIs_definitionAndType, {
                        ...{is_definition: "Yes"}
                    })
                );
                // If props.mapInside exists, that means that instead of taking items we need to take items.mapInside for each item
                setData([...Object.values(result.data)[0].items]);

            } catch (error) {
                console.log("Error fetching some data data:", error);
                // setData(error.data[propQueryName].items);
            }
            setLoading(false);
        };

        fetchData();
    }, []);
    function CreatItemAction (){
        if(currentUser){
            return(
                <CreateItemButton
                    permissions={true}
                    action={cFileType}
                    currentFormData={newItemForm}
                    FormFields={NewFileDefinition}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`File Definition`}
                />)
        } else {
            return null
        }
    }
    console.log(columns)
    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
            <Table
                permission={true}
                headers={columns}
                data={data}
                loading={loading}
                CreatItemAction={CreatItemAction}
                option={false}
                itemType="File Definition"
            />
        </div>
    )
});

export default FileDefinitionsView;
