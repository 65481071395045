import {API, graphqlOperation} from "aws-amplify";
import {createBuildings, updateBuildings, deleteBuildings, deleteUsersBuildings} from "../graphql/mutations";
import notificationOptions from "../views/Components/Notifications";
import {usersBuildingsByBuildingsId} from "../graphql/queries";

export const createBuilding = async (formData, current, hideAlert, notificationAlertRef) => {
    const propertiesToDelete = ["createdAt", "updatedAt", "_deleted", "_lastChangedAt", "values", "district", ]
    propertiesToDelete.forEach((property) => {
        if (formData.hasOwnProperty(property)) {
            delete formData[property];
        }
    });
    console.debug("formData", formData);
    try {
        API.graphql({
            query: createBuildings,
            variables: {input: {...formData}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            let o = notificationOptions("Item Created Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        });
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Creating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }

};

export const updateBuilding = async (formData, current, hideAlert, notificationAlertRef) => {
    const propertiesToDelete = ["createdAt", "updatedAt", "_deleted", "_lastChangedAt", "values", "district", ]
    propertiesToDelete.forEach((property) => {
        if (formData.hasOwnProperty(property)) {
            delete formData[property];
        }
    });
    try {
        API.graphql({
            query: updateBuildings,
            variables: {input: {...formData}},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => {
            let o = notificationOptions("Item Updated Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        });
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Updating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const deleteBuilding = async (item, hideAlert, notificationAlertRef) => {
    try {
        await API.graphql(
            graphqlOperation(deleteBuildings, {
                input: { id: item.id, _version: item._version },
            })
        );
        hideAlert();
        let o = notificationOptions("Item Deleted Successfully", "success");
        notificationAlertRef.current.notificationAlert(o);
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }

    try{
        const usersBuildings = await API.graphql(graphqlOperation(usersBuildingsByBuildingsId, {buildingsId: item.id}));
        // Use the deleteUsersBuildings mutation to delete the user building relationships in parallel
        console.debug("fetched users buildings", usersBuildings);
        await Promise.all(usersBuildings.data.usersBuildingsByUsersId.items.map(async (userBuilding) => {
            API.graphql(graphqlOperation(deleteUsersBuildings, {input: {id: userBuilding.id, _version: userBuilding._version}}));
        }));
        console.log("Deleted all users buildings");
    } catch (e) {
        console.log(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users Buildings ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}
