// UsersView.js
import React, {useState, useContext, useRef, useEffect} from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import {listUsersByRole} from '../../graphql/customQueries';
import { Dropdown } from 'react-bootstrap';
import { createNewUsers, uUsers, dUsers } from '../../utils/db_users_graph';
import { UserForm } from '../Forms/FormFields';
import { Roles } from "../../models";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import EditItemButton from "../../components/Buttons/EditItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";


const UsersView = (props) => {
    const currentUser = useContext(UserContext);
    const [queryRole] = React.useState(Roles.STAFF);
    const [role, setRole] = useState('Students');
    const [query, setQuery] = useState(listUsersByRole);
    const [queryParams, setQueryParams] = useState({ role: role, status: {eq: "Active"}, limit: 1000});
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);
    const newUserFormData = { role: queryRole, district_id: currentUser.currentUser.district_id, district_name: currentUser.currentUser.district_name, values: { building_id: null } };
   const [headers, setHeaders] = useState(['userId',
       'firstName',
       'lastName',
       'role',
       'buildings'])
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['firstName', 'lastName']);
            } else {
                setHeaders([
                    'firstName',
                    'lastName',
                    'role',
                    'buildings']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    const hideAlert = () => {
        setAlert(null);
    };
    useEffect(() => {
        console.debug("Role: ", role);
        setQuery(listUsersByRole);
        setQueryParams({ role: role, status: {eq: "Active"}, limit: 1000});
    }, [role]);
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions[role] &&
            (currentUser.permissions[role].Update || currentUser.permissions[role].Delete)) {
            setShowActions(true);
        }
    }, [currentUser, role]);
    function CreatItemAction (){
        if(currentUser && currentUser.permissions[role].Create){
            return(
                <CreateItemButton
                permissions={currentUser.permissions[role].Create}
                action={createNewUsers}
                currentFormData={newUserFormData}
                FormFields={UserForm}
                editItem={false}
                hideAlert={hideAlert}
                notificationAlertRef={notificationAlertRef}
                setAlert={setAlert}
                itemType={`${role} User`}
            />)
        } else {
            return null
        }
    }

    const getRowActions = (row) => {
        const actions = []; // default actions
        //if (currentUser.permissions[role].create) actions.push(createNewUsers);
        if (currentUser && currentUser.permissions[role].Update){
            actions.push({
                component: EditItemButton,
                props: {
                   //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions[role].Update,
                    action: uUsers,
                    FormFields: UserForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: `${role} User`
                }
            });
        }
        if (currentUser && currentUser.permissions[role].Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions[role].Delete,
                    action: dUsers,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: `${role} User`
                }
            });
        }
        return actions;
    };

    const handleRoleChange = (e) => {
        setRole(e);
    };




    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
            <Dropdown id="role-select" onSelect={handleRoleChange}>
                <Dropdown.Toggle className="dropdownBlue" variant="primary" id="dropdown-basic">
                    {role || 'Select Role'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item eventKey="Students">Students</Dropdown.Item>
                    <Dropdown.Item eventKey="Teachers">Teachers</Dropdown.Item>
                    <Dropdown.Item eventKey="Administrators">Administrators</Dropdown.Item>
                    <Dropdown.Item eventKey="Staff">Staff</Dropdown.Item>
                    <Dropdown.Item eventKey="Guardians">Guardians</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {role && (
                <Table
                    permission={currentUser.permissions[role]}
                    headers={headers}
                    query={query}
                    queryName="usersByRoleAndStatus"
                    queryParams={queryParams}
                    CreatItemAction={CreatItemAction}
                    getRowActions={getRowActions}
                    option={showActions}
                    showStudentLink={false}
                    itemType={`${role} User`}
                />
            )}
        </div>
    );
};

export default UsersView;

