import React, {useContext, useEffect, useState} from "react";
// react component used to create charts
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from 'react-router-dom';
// react component that creates a form divided into multiple steps
import ReactWizard from "react-bootstrap-wizard";
// react-bootstrap components
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Step1 from "./Step1.js";
import Step2 from "./Step2.js";
import Step3 from "./Step3.js";
import {API, Storage} from "aws-amplify";
import {uploadsByIs_definitionAndType} from "../../../graphql/customQueries";
import { listSchoolYears, listUploads } from '../../../graphql/queries';
import {createUploads} from "../../../graphql/mutations";
import UserContext from "../../../contexts/UserContext";



function Wizard() {
  const [config, setConfig] = useState("");
  const [alertState, setAlertState] = React.useState(false);
  const [fileTypes, setFileTypes] = React.useState([]);
  const [schoolYears, setSchoolYears] = React.useState([]);
  const currentUser = useContext(UserContext);
  const [status, setStatus] = React.useState({fileErrors: false,statusList:[]});
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [showProgressBar, setShowProgressBar] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState('');
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const history = useHistory();  // Get the history object

    useEffect(() => {
        if(status.statusList.length > 0) {
            setUploadComplete(true);
        } else setUploadComplete(false);
    }, [status]);
    useEffect(() => {
        if(uploadComplete){
            let message = "Files Uploaded!";
            let type = "success";
            if(status.fileErrors){
                message = "Some files were not uploaded!";
                type = "warning";
            }
                    setAlertState(
                        <SweetAlert
                            type={type}
                            style={{ display: "block", marginTop: "-100px" }}
                            title={message}
                            onConfirm={() =>{
                                setAlertState(null)
                                history.push("/home/files")
                            }}
                            confirmBtnBsStyle="info"
                        >
                            <div className="sweet-alert-text">
                                {status.statusList.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            </div>
                        </SweetAlert>
                    )
                    setStatus({fileErrors: false,statusList:[]})
                    setUploadComplete(false)
                }
        // eslint-disable-next-line
    },[uploadComplete])

    useEffect(() => {
        let isMounted = true;  // flag to track component mount state

        async function fetchFileTypes() {
            try {
                const params = {is_definition: "Yes"}
                const types = await API.graphql({query: uploadsByIs_definitionAndType, variables: params});
                console.debug("Types: ", types)
                if (isMounted) setFileTypes([...Object.values(types.data)[0].items]);
            } catch (e) {
                console.debug("Error: ", e);
            }
        }


        async function fetchConfig() {
            const response = await API.get('oneschool', '/config/athena-bucket');
            if (isMounted) setConfig(response.bucket);
        }

        async function fetchSchoolYears() {
            try {
                const years = await API.graphql({query: listSchoolYears});
                if (isMounted) setSchoolYears([...Object.values(years.data)[0].items]);
            } catch (e) {
                console.error("Error: ", e);
            }
        }

        fetchFileTypes();
        fetchConfig();
        fetchSchoolYears();

        return () => { isMounted = false; };  // cleanup function to prevent memory leaks
    },[]);
  const steps = [
    { stepName: "Select Files", component: Step1},
    { stepName: "Upload Validation", component: Step2, stepProps: {fileTypes: fileTypes, schoolYears: schoolYears}},
    { stepName: "Complete Upload", component: Step3, stepProps: {showProgressBar: showProgressBar, currentFile: currentFile, uploadProgress: uploadProgress, status: status, setStatus: setStatus}},
  ];
    async function handleUpload(allStates) {
            const newStatus = [...status.statusList];
            let errorFound = false// create a copy of the current status
            const totalFiles = allStates["Upload Validation"].data.length;
            let completedFiles = 0;
            let overallProgress = (completedFiles / totalFiles) * 100;
            setShowProgressBar(true);
            setUploadProgress(overallProgress);;
            await Promise.all(allStates["Upload Validation"].data.map(async (file) => {
                // Update the current file before starting the upload
                setCurrentFile(file.name);
                const duplicate = await API.graphql({query: listUploads, variables: {filter: {name: {eq: file.name}}}});
                if (duplicate.data.listUploads.items.length > 0) {
                    errorFound = true;
                    newStatus.push(`File ${file.name} already exists. Please rename the file and try again.`)
                    completedFiles += 1;
                    overallProgress = (completedFiles / totalFiles) * 100;
                    setUploadProgress(overallProgress);
                    return null;
                }
                try {
                    await Storage.put(`uploads/${file.type}/${file.name}`, file.file, {
                        bucket: config,
                        acl: 'bucket-owner-full-control'
                    });
                } catch (error) {
                    console.error('Error uploading file: ', error);
                    newStatus.push(`Error uploading file ${file.name}. Please try again.`)
                    completedFiles += 1;
                    overallProgress = (completedFiles / totalFiles) * 100;
                    setUploadProgress(overallProgress);
                    return null;
                }

                const fileForDB = {
                    file: {bucket: config, region: 'us-east-1', key: `uploads/${file.type}/${file.name}`},
                    name: file.name,
                    type: file.type,
                    type_id: file.type_id,
                    schoolyear_id: currentUser.activeSchoolYear.schoolYear_id,
                    owner: currentUser.selectedUser.value,
                    district_id: currentUser.currentUser.district_id,
                    status: "UPLOADED",
                    is_definition: "No"
                }
                console.debug("File for DB: ", fileForDB)
                try {
                    await API.graphql({
                        query: createUploads,
                        variables: {
                            input: {
                                ...fileForDB

                            },
                        },
                    });
                    newStatus.push(`File ${file.name} uploaded to successfully.`)
                    completedFiles += 1;
                    overallProgress = (completedFiles / totalFiles) * 100;
                    setUploadProgress(overallProgress);
                } catch (e) {
                    console.error("Error Adding File to DB: ", e);
                    errorFound = true;
                    newStatus.push(`Error adding file ${file.name} to database. Please try again.`)
                    completedFiles += 1;
                    overallProgress = (completedFiles / totalFiles) * 100;
                    setUploadProgress(overallProgress);
                }
                }));
            setStatus({fileErrors: errorFound, statusList:[...newStatus]})



    }

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <ReactWizard
              steps={steps}
              //instance={setInstance}
              navSteps
              title="Data Upload Wizard"
              headerTextCenter
              validate
              color="blue"
              previousButtonText="Back"
              nextButtonText="Next"
              finishButtonClasses="btn-info btn-wd"
              nextButtonClasses="btn-info btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonClick={handleUpload}
            />
          </Col>
        </Row>
      </Container>
      {alertState}
    </>
  );
}

export default Wizard;
