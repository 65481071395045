import React, {useContext, useRef, useState, useEffect} from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import {listBuildings} from '../../graphql/customQueries';
import { createBuilding, deleteBuilding, updateBuilding } from "../../utils/db_buildings_graph";
import {BuildingForm} from '../Forms/FormFields';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";
// import { Roles } from "../../models";
const BuildingsView = (props) => {
    const currentUser = useContext(UserContext);
    const newItemForm = {values: {}};
    const [showActions, setShowActions] = useState(false);
    //eslint-disable-next-line
    const [query, setQuery] = useState(listBuildings);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] = useState({ filter: { district_id: { eq: currentUser.district_id } } });
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    };
    const [headers,setHeaders] = useState([
        'name',
        'district_name'
    ]);
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['name']);
            } else {
                setHeaders([
                    'name',
                    'district_name']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    function CreatItemAction (){
        if(currentUser &&
            currentUser.permissions.Buildings &&
            currentUser.permissions.Buildings.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.Buildings.Create}
                    action={createBuilding}
                    currentFormData={newItemForm}
                    FormFields={BuildingForm}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`Building`}
                />)
        } else {
            return null
        }
    }


    const getRowActions = (row) => {
        const actions = []; // default actions
        if (currentUser &&
            currentUser.permissions.Buildings &&
            currentUser.permissions.Buildings.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Buildings.Update,
                    action: updateBuilding,
                    FormFields: BuildingForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Building"
                }
            });
        }
        if (currentUser &&
            currentUser.permissions.Buildings &&
            currentUser.permissions.Buildings.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Buildings.Delete,
                    action: deleteBuilding,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Building"
                }
            });
        }
        return actions;
    };
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.Buildings &&
            (currentUser.permissions.Buildings.Update || currentUser.permissions.Buildings.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
        <Table
            permission={currentUser.permissions.Buildings}
            headers={headers}
            query={query}
            queryName="listBuildings"
            queryParams={queryParams}
            CreatItemAction={CreatItemAction}
            getRowActions={getRowActions}
            option={showActions}
            itemType="Building"
        />
        </div>
    )
}

export default BuildingsView;
