import React, {useEffect} from "react";
import { Row, Col} from "react-bootstrap";
// react component that creates a dropdown menu for selection
import FileUploadTable from "../../../components/Tables/FileUploadTable";


const Step2 = React.forwardRef((props, ref) => {
    const [fileTypes, setFileTypes] = React.useState([]);
    const [data, setData] = React.useState([]);

    useEffect(() => {
        const selectedFiles = props.wizardData["Select Files"]?.selectedFiles;
        if(selectedFiles && selectedFiles.length > 0) {
            setData([...selectedFiles]);
        }
    }, [props.wizardData]);


    useEffect(() => {
        if (props.fileTypes && props.fileTypes.length > 0){
            setFileTypes([...props.fileTypes])
            //console.log(props.fileTypes)
        }
    }, [props.fileTypes]);
    const onDataChange = (data) => {
        setData([...data]);
    }
    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            return true;
        },
        state: {data},
    }));
  return (
    <div className="wizard-step">
      <Row>
        <Col md="12">
            <FileUploadTable
                fileTypes={fileTypes}
                data={data}
                setData={onDataChange}
                schoolYears={props.schoolYears}
            />
        </Col>
      </Row>
    </div>
  );
});

export default Step2;
