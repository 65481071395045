import React, {useState, useContext}from 'react';
import {Row, Form} from "react-bootstrap";
import {API,graphqlOperation} from "aws-amplify";
import {usersByRoleAndStatus} from "../../graphql/customQueries";
import {useEffect} from "react";
import Select from "react-select";
import UserContext from '../../contexts/UserContext';


async function getOptions(group) {

    if (group === "SuperAdmin") {
        const allOptions = [];
        const roles = ["Teachers", "Administrators", "Staff"];

        roles.map(async (role) => {
            let name = "";
            switch (role) {
                case "Teachers":
                    name = "T"
                    break;
                case "Staff":
                    name = "S"
                    break;
                case "Administrators":
                    name = "A"
                    break;
                default:
                    name = "U"
            }
           // console.debug("newUsersByRole: ", usersByRoleAndStatus)
            try {
                    const T = await API.graphql(graphqlOperation(usersByRoleAndStatus, {
                        role: role,
                        status: {eq: "Active"},
                        limit: 1000
                    }))
                    console.debug("T: ", {...T})
                    T.data.usersByRoleAndStatus.items.map((u) => {
                        allOptions.push({
                            value: u.id,
                            label: name + "-" + u.firstName + " " + u.lastName,
                            group: u.permissions,
                            email: u.email
                        })
                        return u;
                    });

            }catch (error) {
                console.error("Error loading users by role and status:")
            }

        })
        return allOptions;
    }
}
function ViewAs({ onUserIdChange, setGroup}) {

    const [options, setOptions] = useState(['1']);
    const [selectedValue, setSelectedValue] = useState([]);
    // eslint-disable-next-line
    const [originalUser, setOriginalUser] = useState({});
    const [currentGroup, setCurrentGroup] = useState('');
    const currentUser = useContext(UserContext);
    // eslint-disable-next-line
    const {selectedUser, setSelectedUser} = useContext(UserContext);

    useEffect(() => {
        if(currentUser !== null && currentUser !== undefined) {
            try {
                setCurrentGroup(currentUser.currentUser.currentGroup);
                getOptions(currentUser.currentUser.currentGroup).then((items) => setOptions(items));
                setOriginalUser(currentUser.currentUser);
            }
            catch (error) {
                console.error("Error loading stored details:", error);
            }

        }

    }, [currentUser]);

    function onTeacherSelect(selectedOption) {
        setSelectedValue(selectedOption);
        onUserIdChange(selectedOption.value);
        setSelectedUser( {...selectedOption}); // Update the selected user in the context
    }
    return (
        <div>
            {currentGroup === 'SuperAdmin' ? (
                <Form.Group>
                    <Row sm='7' className='text-black'>
                        <Select
                            className='react-select info align-middle font-weight-bold, text-transform: Capitalize'
                            classNamePrefix='react-select'
                            onChange={(value) => {
                                onTeacherSelect(value);
                            }}
                            value={selectedValue}
                            options={options}
                            optionsKey={(options) => options.value}
                        />
                    </Row>
                </Form.Group>
            ) : null}
        </div>
    );
}

export default ViewAs;
