import React, {useContext} from 'react';
import {Container} from "react-bootstrap";
import {useDropzone} from 'react-dropzone';
import {Label} from "reactstrap";
import "./Style.scss"
import UserContext from "../../contexts/UserContext";


  function UploadFile({ selectedFiles, setSelectedFiles}) {
    const currentUser = useContext(UserContext);
    let fileId = 0;


    const {getRootProps, getInputProps} = useDropzone({
        // Disable click and keydown behavior
        multiple: true,
        maxFiles: 20,
        onDrop: onDrop
    });

    let index=0;
    const files = selectedFiles.map(file => (
    <li key={`${file.name}-${index++}`}>
        {file.name} - {file.file.size} bytes
    </li>
    ));

    function  onDrop(files) {
        if (files.length > 20) {
            alert("You can only upload 20 files at a time.");
            return;
        }
      const newFiles = [];
        files.forEach(file => {
            const fileName = file.name.toLowerCase();
            if (!fileName.endsWith(".csv") && !fileName.endsWith(".xlsx")) {
                alert("You can only upload .csv or .xlsx files.");
            }
            else {
                newFiles.push({
                    id: fileId++,
                    name: file.name,
                    type:"",
                    schoolyear_id: currentUser.activeSchoolYear.schoolYear_id,
                    file: file
                });
            }
        });
        setSelectedFiles([...selectedFiles, ...newFiles]);

    }

    return (
        <Container className={"form-file-upload"}>
            <div {...getRootProps({className: 'dropzone'})}>

                <input {...getInputProps()} />
                <Label id={"label-file-upload"}>
                   <span>
                   <p>Drop files here, or click to select files</p>

                   <br />
                    <span >
                    <i className="ark-icon ark-cloud-upload ark-icon-lg"/>
                    </span>

                    <br />
                       <h4>Selected Files</h4>
                       <ul>{files}</ul>
                   </span>

                </Label>

            </div>
        </Container>

    );
}

export default UploadFile;
