import React, {useContext, useEffect} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch,useHistory } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@fortawesome/fontawesome-svg-core/styles.css'
import "@fortawesome/free-regular-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";
import UserContext from './contexts/UserContext';
import {Authenticator } from '@aws-amplify/ui-react';
import AuthLayout from "layouts/Auth.js";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
// eslint-disable-next-line
/**/


const Start = () => {
    const history = useHistory();
    const currentUser = useContext(UserContext);
    useEffect(() => {
       const handleReload = () => {
          console.debug('reloading');
        };

        window.addEventListener('beforeunload', handleReload);

        // cleanup function
        return () => {
            window.removeEventListener('beforeunload', handleReload);
        };
    }, [history,currentUser]);
    return (

        <Switch>
            <Route path="/" render={(props) => <App {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        </Switch>

    );
}
root.render(
    <Authenticator.Provider>
        <BrowserRouter>
            <Start />
        </BrowserRouter>
    </Authenticator.Provider>
);
//
