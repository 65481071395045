import React, { useState, useEffect, useContext, useRef } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import { getTeacherClasses, getClassRoster } from '../../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';
import { Tab, Tabs, Col, Container, Row } from 'react-bootstrap';
import AddStudentRecord from "../Components/AddStudentRecord";
import AddRecordButton from "../../components/Buttons/AddRecord";
import NotificationAlert from "react-notification-alert";
import Embed from "../../components/QuickSite/Embed";
import ShowDashboardButton from "../../components/Buttons/ShowDashboardButton";
import "./MyClasses.scss"

const MyClassesView = (props) => {
    const currentUser = useContext(UserContext);
    const [userClasses, setUserClasses] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [classesFetched, setClassesFetched] = useState(false);
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = useState(null);
    // eslint-disable-next-line
    const [query, setQuery] = useState(getClassRoster);
    const initialQueryParams = {limit: 10000, classesId: "y"};
    // eslint-disable-next-line
    const [queryParams, setQueryParams] = useState(initialQueryParams);
    const [currentQueryParams, setCurrentQueryParams] = useState({ ...initialQueryParams });
    const [recordParams, setRecordParams] = useState({});
    const [showAddRecord, setShowAddRecord] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [showDashboard, setShowDashboard] = useState(true);
    const [dashBoardParams, setDashBoardParams] = useState({ params: { studentId: "1234" } });
    const [headers, setHeaders] = useState([
        'firstName',
        'lastName',
        'stateId',
        'cohort',
    ]);
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['firstName', 'lastName']);
            } else {
                setHeaders(['firstName', 'lastName', 'stateId', 'cohort']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    // Fetch user's classes
    useEffect(() => {
        const fetchClasses = async () => {
            let userValue = currentUser.selectedUser
                ? currentUser.selectedUser.value
                : currentUser.currentUser.attributes["custom:user_id"];

            try {
                if (userValue) {
                    let nextToken = null;
                    let allItems = [];
                    do {
                        const result = await API.graphql(graphqlOperation(getTeacherClasses, {
                            id: userValue,
                            schoolYear_name: { eq: currentUser.activeSchoolYear.schoolYear_name },
                            limit: 300,  // Fetch 10 items per request
                            nextToken: nextToken  // Pass the nextToken from the previous request (null for the first request)
                        }));
                        if(result.data.getUsers &&
                            result.data.getUsers.instructor_classes &&
                            result.data.getUsers.instructor_classes.items &&
                            result.data.getUsers.instructor_classes.items.length > 0) {
                            allItems = [...allItems, ...result.data.getUsers.instructor_classes.items];
                        }
                        if(result.data.getUsers &&
                            result.data.getUsers.nextToken)
                            nextToken = result.data.getUsers.nextToken;

                    } while (nextToken);
                    if (allItems.length >  0) {
                        setUserClasses([...allItems]);
                        setClassesFetched(true);
                    }
                }
            } catch (error) {
                console.error("Error fetching user classes:", error);
                setAlert('Error fetching user classes. Please try again.');
            }
        };

        fetchClasses();
    }, [currentUser]);

    // Set class parameters based on user classes fetched
    useEffect(() => {
        if (userClasses.length > 0) {
            const firstClass = userClasses[0];
            setCurrentQueryParams(prevParams => ({
                ...prevParams,
                class_id: firstClass.class_id
            }));
        }
    }, [userClasses]);

    const handleTabClick = (index) => {
        setSelectedTab(index);
        setShowTable(false);
        setShowAddRecord(true);
        setShowDashboard(true);

        const selectedClass = userClasses[index]?.classes;
        if (selectedClass) {
            setCurrentQueryParams(prevParams => ({
                ...prevParams,
                class_id: selectedClass.class_id
            }));
        }
    };

    const getRowActions = (row) => {
        const actions = [];
        // Assuming default actions or permissions can be added here
        actions.push({
            component: AddRecordButton,
            props: {
                row: row.original,
                setShowTable,
                setShowAddRecord,
                setRecordParams,
                currentQueryParams,
                notificationAlertRef
            }
        });
        actions.push({
            component: ShowDashboardButton,
            props: {
                row: row.original,
                setShowTable,
                setShowAddRecord,
                setDashBoardParams,
                setShowDashboard
            }
        });
        return actions;
    };

    return (
        <Container fluid>
            <Row>
                {alert}
                <NotificationAlert ref={notificationAlertRef} />
                <Tabs activeKey={selectedTab} onSelect={handleTabClick}>
                    {userClasses.map((classItem, index) => (
                        <Tab
                            key={`${classItem.class_id}-${index}`}
                            eventKey={index}
                            title={classItem.name}
                        />
                    ))}
                </Tabs>
                <Col xs={12} md={8} lg={12} hidden={showTable} className={"justify-content-center"}>
                    {classesFetched && userClasses.length > 0 && !showTable && (
                        <div>
                            <h3>{userClasses[selectedTab].name}</h3>
                            <Table
                                key={`${userClasses[selectedTab].class_id}-${selectedTab}`}
                                permission={currentUser.permissions["Assessments"]}
                                headers={headers}
                                query={query}
                                queryName="studentsClassesByClass_idAndSchoolYear_name"
                                queryParams={currentQueryParams}
                                getRowActions={getRowActions}
                                itemType="Class"
                                mapInside="student"
                                option={true}
                                shouldRefresh={true}
                            />
                        </div>
                    )}
                </Col>
                <Col xs={12} md={8} lg={8} hidden={showAddRecord} className={"justify-content-center"}>
                    {!showAddRecord && (
                        <Row>
                            <AddStudentRecord
                                setShowAddRecord={setShowAddRecord}
                                setShowTable={setShowTable}
                                notificationAlertRef={notificationAlertRef}
                                {...recordParams}
                            />
                        </Row>
                    )}
                </Col>
                <Row hidden={showDashboard} className={"justify-content-center"}>
                    {!showDashboard && (
                        <Embed
                            containerDiv = "dashboardContainer"
                            embedType="dashboard"
                            dashboardParams={dashBoardParams}
                            dashboardView={"StudentView"}
                            {...props}
                        />
                    )}
                </Row>
            </Row>
        </Container>
    );
}

export default MyClassesView;
