import React from "react";
import {Form, Col} from "react-bootstrap";

import Select from "react-select";


const SchoolYearSelection = ({row, onRowChange, schoolYears, currentSchoolYear}) => {
        return(
            <Form sm="7" md="8" lg="12">
                <Form.Group key={`typeselectform-${row.id}`}>
                    <Col sm="7" md="8" lg="12">
                    <Select
                        key={`typeselect-${row.id}`}
                        appendTo="body"
                        className="react-select info"
                        classNamePrefix="react-select"
                        getOptionLabel={(schoolYears) => schoolYears.name}
                        getOptionValue={(schoolYears) => schoolYears.id}
                        options={schoolYears}
                        optionsKey={(options) => options.id}
                        onChange={(value) => onRowChange(row, value.id, "schoolyear_id")}
                        value={schoolYears.find((schoolYears) => schoolYears.id === row.original.schoolyear_id)}
                        placeholder="Select School Year"
                        />
                    </Col>
                </Form.Group>
            </Form>
        )

};
export default SchoolYearSelection;
