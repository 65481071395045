import {
    createRecordType,
    updateRecordType,
    deleteRecordType,
    createRecordTypeLineItems,
    deleteRecordTypeLineItems
} from '../graphql/mutations';
import {getLineItemsForRecord} from '../graphql/customQueries';
import notificationOptions from '../views/Components/Notifications';
import { API } from 'aws-amplify';
const propertiesToDelete = [ "createdAt", "updatedAt", "_deleted", "_lastChangedAt", "__typename", "values"];
export const cRecordType = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        let lineItems = [];
        Object.keys(formData).forEach((property) => {
            if(formData[property] instanceof Object || formData[property] instanceof Array){
                if (formData.hasOwnProperty('lineItems')) {
                    lineItems = formData.lineItems;
                    delete formData['lineItems'];
                }
                else delete formData[property];
            }
            if(propertiesToDelete.includes(property)){
                delete formData[property];
            }
        });

        const result = await API.graphql({
            query: createRecordType,
            variables: {
                input: {
                    ...formData,

                },
            },
        });

        // Add RecordTypeLineItems
        if (lineItems.length > 0) {
            lineItems.map(async (lineItem) => {

                const data = {
                    query: createRecordTypeLineItems,
                    variables: { input: { recordTypeId: result.data.createRecordType.id, lineItemTypeId: lineItem.id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                }
                await API.graphql(data);
            });
        }

        const { data } = result;
        if (data) {
            let o = notificationOptions('Record Created Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Record While Creating Items${e}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const uRecordType = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        let itemUpdate = { ...current, ...formData}
        let lineItems = [];
        Object.keys(itemUpdate).forEach((property) => {
            if(itemUpdate[property] instanceof Object || itemUpdate[property] instanceof Array){
                if (itemUpdate.hasOwnProperty('lineItems')) {
                    lineItems = itemUpdate.lineItems;
                    delete itemUpdate['lineItems'];
                }
                else delete itemUpdate[property];
            }
            if(propertiesToDelete.includes(property)){
                delete itemUpdate[property];
            }
        });
        const result = await API.graphql({
            query: updateRecordType,
            variables: {
                input: {
                    ...itemUpdate,
                },
            },
        });
        const { data } = result;
        if(lineItems.length > 0) {
            let currentLineItems = await API.graphql({
                query: getLineItemsForRecord,
                variables: {
                    recordTypeId: current.id,
                    filter: {_deleted: {ne: true}}
                }
            });
            currentLineItems = Object.values(currentLineItems.data)[0].items;
            const recordLineItemMap = currentLineItems.reduce((map, rl) => {
                map[rl.lineItemTypeId] = rl;
                return map;
            }, {});

            const newLineItems = lineItems.filter((rl) => !recordLineItemMap[rl.id]);
            const userLineItemIds = new Set(lineItems.map(rl => rl.id))
            const deletedLineItems = currentLineItems.filter((item) => !userLineItemIds.has(item.lineItemTypeId));
            if(newLineItems.length > 0){
                newLineItems.map(async (lineItem) => {
                    const data = {
                        query: createRecordTypeLineItems,
                        variables: { input: { recordTypeId: current.id, lineItemTypeId: lineItem.id } },
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                    }
                    await API.graphql(data);
                });
            }
            if(deletedLineItems.length > 0){
                deletedLineItems.map(async (item) => {
                    const data = {
                        query: deleteRecordTypeLineItems,
                        variables: {input: {id: item.id, _version: item._version}},
                        authMode: 'AMAZON_COGNITO_USER_POOLS',
                    }
                    await API.graphql(data);
                });
            }
        }



        if (data) {
            let o = notificationOptions('Record Updated Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Record While Updating Items${e}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const dRecordType = async (item, hideAlert, notificationAlertRef) => {
    try{
        const result = await API.graphql(
            {
                query: deleteRecordType,
                variables: {
                    input: {
                        id: item.id,
                        _version: item._version
                    }
                }
            }
        );

        const { data } = result;
        if (data) {
            hideAlert();
            let o = notificationOptions('Record Deleted Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Record While Deleting Items${e}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}
