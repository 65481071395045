import { createSchoolYears, updateSchoolYears, deleteSchoolYears } from "../graphql/mutations";
import notificationOptions from "../views/Components/Notifications";
import { API } from "aws-amplify";

export const createSchoolYear = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        if (formData.hasOwnProperty("values")) {
            delete formData["values"];
        }
        const result = await API.graphql({
            query: createSchoolYears,
            variables: {
                input: {
                    ...formData
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("School Year Created Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`School Year While Creating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const updateSchoolYear = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        const propertiesToDelete = ["createdAt", "updatedAt", "_deleted","__typename", "_lastChangedAt", "values", "district","classes","student_records" ]
        propertiesToDelete.forEach((property) => {
            if (formData.hasOwnProperty(property)) {
                delete formData[property];
            }
        });

        const result = await API.graphql({
            query: updateSchoolYears,
            variables: {
                input: {
                    ...formData
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("School Year Updated Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`School Year While Creating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const deleteSchoolYear = async (item, hideAlert, notificationAlertRef) => {
    try {
        const result = await API.graphql({
            query: deleteSchoolYears,
            variables: {
                input: {
                    id: item.id,
                    _version: item._version
                }
            }
        });
        const { data } = result;
        if (data) {
            let o = notificationOptions("School Year Deleted Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`School Year While Deleting Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}
