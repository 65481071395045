/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://32jddzqstj.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "oneschool",
            "endpoint": "https://zf70d0cpde.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://37l3wfubjncx5ok6c5etd45ipm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hb6fx3e2erhcvlxjtk6g7x3lou",
    "aws_cognito_identity_pool_id": "us-east-1:aa54d648-ce22-4ce6-993b-64bd9671e1d5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_LRNa6xQ3P",
    "aws_user_pools_web_client_id": "2324bkl99fqq3bklpbs3s5rd0a",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "ZONEINFO"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "oneschoolviewv253bef2a4c3f04e9abd5e3d672269f376160103-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
