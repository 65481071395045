import React, { useEffect, useContext, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { get_db_systemSystems } from "../../utils/db_systemSystems";
import UserContext from '../../contexts/UserContext';
import {embedDashboard} from "amazon-quicksight-embedding-sdk";
import getSubdomain from "../../functions/getSubdomain";
import {Container} from "react-bootstrap";
// Moved styles to the top for clarity
const useStyles = theme => ({
    loading: {
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: 4,
    },
});



function Embed({dashboardView, dashboardParams, embedType }) {
    // Destructured props for cleaner referencing

    // State initialization
    const [loader, setLoader] = useState(true);
    const [dashboard, setDashboard] = useState(null);
    const [dashboardSettings, setDashboardSettings] = useState({ id: "", roleArn: "" });
    const [quickSiteData, setQuickSiteData] = useState(null);
    const [params] = useState({...dashboardParams});
    const currentUser = useContext(UserContext);
    const subdomain = getSubdomain();
    useEffect(() => {
        // Fetch system settings based on district_id
        get_db_systemSystems(currentUser.currentUser.district_id).then((data) => {
            const settings = JSON.parse(data.settings);
            let currentGroup = "";

            // Determining which dashboard ID to use
            if (dashboardView === "StudentView") {
                currentGroup = "StudentHistory";
            } else if (currentUser.selectedUser) {
                currentGroup = currentUser.selectedUser.group;
            }

            const dashboardId = settings.Dashboards[currentGroup || "StudentHistory"].value;

            setDashboardSettings({
                id: dashboardId,
                roleArn: settings.DashboardRoles[currentUser.selectedUser.group].value
            });
        });
        // eslint-disable-next-line
    }, [currentUser.currentUser, currentUser.selectedUser]);
    // Removed duplicated logic

    useEffect(() => {
        if (quickSiteData) {
            const containerDiv = document.getElementById("dashboardContainer");

            // Constructed the options object for embedding dashboard
            const options = {
                url: quickSiteData.data.data.EmbedUrl,
                container: containerDiv,
                parameters: embedType === "Admin" ? null : {...params},
                iframeResizeOnSheetChange: true,
                height: embedType === "Admin" ? "1000px" : "AutoFit",
                width: "100%",
                ...(embedType !== "Admin" && { scrolling: "yes", footerPaddingEnabled: true })
            };

            // Initialize the dashboard if not present
            if (!dashboard) {
                setDashboard(embedDashboard(options));
                setLoader(false);
            } else {
                dashboard.setParameters({...params});

            }
        }
        // eslint-disable-next-line
    }, [params, quickSiteData, embedType]);

    useEffect(() => {
        async function getQuickSightDashboardEmbedURL() {
            try {
                const data = await Auth.currentSession();
                const jwtToken = data.getIdToken().getJwtToken();
                const accessToken = data.getAccessToken().getJwtToken();
                const { sub: payloadSub } = data.idToken.payload;
                const email = currentUser.selectedUser.email;

                if (!dashboardSettings.id || !dashboardSettings.roleArn) {
                    // console.debug("No Dashboard Settings Found");
                    return;
                }

                const localParams = {
                    headers: {},
                    response: true,
                    queryStringParameters: {
                        jwtToken,
                        accessToken,
                        payloadSub,
                        email,
                        dashboardId: dashboardSettings.id,
                        roleArn: dashboardSettings.roleArn,
                        group: currentUser.selectedUser.group,
                        subdomain: subdomain,
                        embedType
                    }
                };

                const quicksight = await API.get('oneschool', '/getQuickSiteURL', localParams);
                setQuickSiteData(quicksight);
            } catch (err) {
                console.error("Error fetching QuickSight Dashboard Embed URL:", err);
                setLoader(false); // Optionally stop the loader if there's an error.
            }
        }

        getQuickSightDashboardEmbedURL();
    }, [dashboardSettings, embedType, currentUser.selectedUser.group, currentUser.selectedUser.email, subdomain]);

    return (
      <Container id="dashboardContainer" style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
            {loader && (
                <div><img src={require('../../assets/img/preloader.gif')} alt="loading..." /></div>
            )}

      </Container>
    );
}

export default withStyles(useStyles)(Embed);
