import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useResizeColumns, useFlexLayout,useRowSelect } from 'react-table';
import { Table as BootstrapTable, Container } from 'react-bootstrap';
import "./Table.scss"
import UploadFileType from "../Buttons/UploadFileType";
import SchoolYearSelection  from "../Buttons/SchoolYearSelection";

// Define a default UI for filtering
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)
const FileUploadTable = (props) => {

    const [fileTypes, setFileTypes] = useState([...props.fileTypes]);
    useEffect(() => {
        if (props.fileTypes && props.fileTypes.length > 0){
            setFileTypes([...props.fileTypes])
            console.debug(props.fileTypes)
        }
    }, [props.fileTypes]);

    const onRowChange = (row, value, col) => {
        const updatedData = [...props.data];
        if (col === "type"){
            updatedData[row.index].type_id = value.id;
            updatedData[row.index].type = value.name;
        } else updatedData[row.index][col] = value;
        props.setData(updatedData);

    }
    const COLUMNS = [
        {
            Header: 'File Name',
            accessor: 'name'
        },
        {
            Header: 'Table Name',
            accessor: 'type',
            Cell: ({row}) => {

                return (
                    <UploadFileType
                        row={row}
                        onRowChange={onRowChange}
                        fileTypes={fileTypes}
                    />
                )
            }
        },
        {
            Header: "School Year",
            Cell: ({row}) => {
                return (
                    <SchoolYearSelection
                        row={row}
                        onRowChange={onRowChange}
                        schoolYears={props.schoolYears}
                    />
                )
            }
        }
    ];
    // eslint-disable-next-line
    const columns = useMemo(() => COLUMNS, [fileTypes,props.data, props.schoolYears]);




    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: props.data,
        },
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.allColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    disableResizing: false,
                    minWidth: 35,
                    width: 50,
                    maxWidth: 100,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );
    //console.log("Data: ", props.data)
    //let lastId = 0;
    return (
        <Container fluid>
            {/* table structure */}
            <BootstrapTable {...getTableProps()} striped bordered hover responsive>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} className="text-center column-width">
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr key={`${row.name}-${row.id}`} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return(
                                            <td   className="rt-td overflow-visible"
                                                // For educational purposes, let's color the
                                                // cell depending on what type it is given
                                                // from the useGroupBy hook
                                                  {...cell.getCellProps()}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })
                }
                </tbody>
            </BootstrapTable>
        </Container>
    );
};

export default FileUploadTable;

