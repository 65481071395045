import React, { useContext,useRef, useState, useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import { listLineItemTypes } from '../../graphql/queries';
import { createLineItemTypes, updateLineItemTypes, deleteLineItemTypes } from "../../utils/db_lineItemTypes_graph";
import {LineItemForm} from '../Forms/FormFields';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";



const LineItemView = (props) => {
    const currentUser = useContext(UserContext);
    // const [queryRole, setQueryRole] = React.useState(Roles.STAFF);
    const newItemForm = { district_id: currentUser.currentUser.district_id, definedOptions:[]};
    //eslint-disable-next-line
    const [query, setQuery] = useState(listLineItemTypes);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] = useState({});
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);
    const [headers,setHeaders] = useState([
        'name',
        'description',
        'type'
    ]);
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['name', "type"]);
            } else {
                setHeaders([
                    'name',
                    'description',
                    'type']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.LineItemType &&
            (currentUser.permissions.LineItemType.Update || currentUser.permissions.LineItemType.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    const hideAlert = () => {
        setAlert(null);
    };
    function CreatItemAction (){
        if(currentUser &&
            currentUser.permissions.LineItemType &&
            currentUser.permissions.LineItemType.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.LineItemType.Create}
                    action={createLineItemTypes}
                    currentFormData={newItemForm}
                    FormFields={LineItemForm}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`Line Item`}
                />)
        } else {
            return null
        }
    }
    const getRowActions = (row) => {
        const actions = []; // default actions
        if (currentUser &&
            currentUser.permissions.LineItemType &&
            currentUser.permissions.LineItemType.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.LineItemType.Update,
                    action: updateLineItemTypes,
                    FormFields: LineItemForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Line Item"
                }
            });
        }
        if (currentUser &&
            currentUser.permissions.LineItemType &&
            currentUser.permissions.LineItemType.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.LineItemType.Delete,
                    action: deleteLineItemTypes,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Line Item"
                }
            });
        }
        return actions;
    };
    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
            <Table
                permission={currentUser.permissions.LineItemType}
                headers={headers}
                query={query}
                queryName="listLineItemTypes"
                queryParams={queryParams}
                CreatItemAction={CreatItemAction}
                getRowActions={getRowActions}
                option={showActions}
                itemType="Line Item"
            />
        </div>
    )
}

export default LineItemView;
