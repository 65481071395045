import {systemSettingsByDistrict_id} from "../graphql/queries";
import {createSystemSettings, updateSystemSettings} from "../graphql/mutations";
import {API} from 'aws-amplify';

export const  get_db_systemSystems = async (district_id) =>  {
    const input = {district_id: district_id};
    const apiData = await API.graphql({query: systemSettingsByDistrict_id, variables: input});
    if (apiData.data.systemSettingsByDistrict_id.items.length > 0) {
        return apiData.data.systemSettingsByDistrict_id.items[0];
    }
    return {};


}


export const handleDashboardChanges = (e, dashboard, district_id,settingType) => {
    //console.error(e)
    let data = {}

    if(dashboard==="DistrictAdmin"){
        data["SuperAdmin"] = {
            value: e.value,
            name: e.label
        }
    }
    data[dashboard] = {
        value: e.value,
        name: e.label
    }

        console.debug(district_id)
        createUpdateSystemSettings(data, district_id,settingType)
}
const createUpdateSystemSettings = async (data, district_id, setting) => {
    const currentSettings = await get_db_systemSystems(district_id);
    if (Object.keys(currentSettings).length !== 0) {
        const temp = JSON.parse(currentSettings.settings);
        temp[setting] = {...temp[setting], ...data};
        const updateItem = {
            id: currentSettings.id,
            district_id: district_id,
            settings: JSON.stringify(temp),
            _version: currentSettings._version
        }
        console.debug("Updating Settings: ", {...updateItem})
       const  result = await API.graphql({
           query: updateSystemSettings,
           variables: {input: {...updateItem}},
           authMode: 'AMAZON_COGNITO_USER_POOLS' });
       console.debug(result);
    } else {
        //console.debug("no Settings Found: ", district_id)
        const i = {}
        let item = {}
        i[setting] = {...data};
        const stringI = JSON.stringify(i);
        item.district_id=district_id
        item.settings = stringI;
       // console.debug("Creating Settings: ", {...item})
        try {
            API.graphql({
                query: createSystemSettings,
                variables: {input: {...item}},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).then((r) => {
                console.debug("Settings Created")
                console.debug("Results", r)
            });
        }catch(e)  {
            console.debug("Error Creating Settings: ", e)
        }
    }

}
