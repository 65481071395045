import React from "react";
import { useParams } from 'react-router-dom';
import { Container, Row } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import Embed from "../../components/QuickSite/Embed";
//import UserContext from '../../contexts/UserContext';
export default function StudentView(props) {
    const id = useParams().id;
   // const currentUser = useContext(UserContext);
    const location = useLocation();

    return (
        <>
            <h1>{location.state?.student.name}</h1>

            <Container fluid>
                <Row>
                    <Embed
                        containerDiv = "dashboardContainer"
                        embedType="dashboard"
                        dashboardParams={{studentId: id}}
                        dashboardView={"StudentView"}
                        {...props}/>
                </Row>
            </Container>
        </>
    );
}
