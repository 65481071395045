import React from "react";
import { ProgressBar } from 'react-bootstrap';
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
const Step3 = React.forwardRef((props, ref) => {
  const isValidated = () => {
    return true;
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));


  return (
    <div className="wizard-step">

        {props.showProgressBar ? <Container>
          <Row>
            <Col className="text-center text-space">
              <ProgressBar animated now={props.uploadProgress} label={`${props.uploadProgress}%`} />
              <div className="text-center text-space"> Uploading {props.currentFile} </div>
            </Col>
          </Row>
            </Container> :
            <h2 className="text-center text-space"> Click on "<b>Finish</b>" to upload your files.</h2>

        }


    </div>
  );
});

export default Step3;
