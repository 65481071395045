import React, { useContext, useRef, useState, useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import { listRecordTypes } from '../../graphql/customQueries';
import { cRecordType, uRecordType, dRecordType } from "../../utils/db_recordType_graph";
import {RecordTypeForm} from '../Forms/FormFields';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";



const headers = [
    'name',
    'description',
];

const RecordTypeView = (props) => {
    const currentUser = useContext(UserContext);
    // const [queryRole, setQueryRole] = React.useState(Roles.STAFF);
    const newItemForm = { district_id: currentUser.currentUser.district_id, values:{classes: null, lineItems: null}};
    //eslint-disable-next-line
    const [query, setQuery] = useState(listRecordTypes);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] = useState({});
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.RecordType &&
            (currentUser.permissions.RecordType.Update || currentUser.permissions.RecordType.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    const hideAlert = () => {
        setAlert(null);
    };
    function CreatItemAction (){
        if(currentUser &&
            currentUser.permissions.SchoolYear &&
            currentUser.permissions.SchoolYear.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.RecordType.Create}
                    action={cRecordType}
                    currentFormData={newItemForm}
                    FormFields={RecordTypeForm}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`Record Type`}
                />)
        } else {
            return null
        }
    }
    const getRowActions = (row) => {
        const actions = []; // default actions
        if (currentUser &&
            currentUser.permissions.RecordType &&
            currentUser.permissions.RecordType.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.RecordType.Update,
                    action: uRecordType,
                    FormFields: RecordTypeForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Record Type"
                }
            });
        }
        if (currentUser &&
            currentUser.permissions.RecordType &&
            currentUser.permissions.RecordType.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.RecordType.Delete,
                    action: dRecordType,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Record Type"
                }
            });
        }
        return actions;
    };
    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
            <Table
                permission={currentUser.permissions.RecordType}
                headers={headers}
                query={query}
                queryName="listRecordTypes"
                queryParams={queryParams}
                CreatItemAction={CreatItemAction}
                getRowActions={getRowActions}
                option={showActions}
                itemType="Record Type"
            />
        </div>
    )
}

export default RecordTypeView;
