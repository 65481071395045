import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { API, graphqlOperation } from 'aws-amplify';
import AlertForm from "../Forms/AlertForm";

const addUsersToClass = async ({selected, itemQuery, itemType}) => {
    if (itemType === "Class") {
        selected["students"] = [];
        selected["instructors"] = [];
        if (itemQuery) {
            const r = await API.graphql(
                graphqlOperation(itemQuery, {
                    id: selected.id,
                }))
            const i = Object.values(r.data)[0];
            if(i.hasOwnProperty("students_classes")){
                i.students_classes.items.forEach(item => {
                    selected["students"].push({
                        id: item.student.id,
                        firstName: item.student.firstName,
                        lastName: item.student.lastName,
                        role: "Students"
                    });
                });
            }
            if(i.hasOwnProperty("instructor_classes")){
                i.instructor_classes.items.forEach(item => {
                    selected["instructors"].push({
                        id: item.instructor.id,
                        firstName: item.instructor.firstName,
                        lastName: item.instructor.lastName,
                        role: "Teachers"
                    });
                });
            }

            return selected;
        }else {
            if (selected["users"].hasOwnProperty("items") && (selected["users"].items.length) > 0) {
                selected["users"].items.forEach(item => {
                    if (item.users.role === "Students") {
                        selected["students"].push({
                            id: item.users.id,
                            firstName: item.users.firstName,
                            lastName: item.users.lastName,
                            role: item.users.role
                        });
                    } else if (item.users.role === "Teachers") {
                        selected["instructors"].push({
                            id: item.users.id,
                            firstName: item.users.firstName,
                            lastName: item.users.lastName,
                            role: item.users.role
                        });
                    }
                });
                return selected;
            }
        }
    }
    else return selected;

}
const EditItemButton =   ({
    permissions,
    action,
    FormFields,
    item,
    editItem,
    hideAlert,
    notificationAlertRef,
    setAlert,
itemType,
                              itemQuery
}) => {
    if (permissions) {
        return (
            <OverlayTrigger
                href="#"
                onClick={(e) => e.preventDefault()}
                overlay={
                    <Tooltip id="tooltip-981231696">
                        Edit
                    </Tooltip>
                }
            >
                <Button
                    className="btn-link btn-xs"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        addUsersToClass({selected: item, itemQuery: itemQuery, itemType: itemType}).then((selected) => {
                            if (selected.hasOwnProperty("buildings")){
                                if (selected["buildings"].hasOwnProperty("items") && (selected["buildings"].items.length) > 0) {
                                    selected["buildings"] = selected["buildings"].items.map(item => {
                                        if(item._deleted) return null;
                                        return {id: item.buildings.id, name: item.buildings.name};
                                    });
                                }
                            }
                            if (selected.hasOwnProperty("lineItems")){
                                if (selected["lineItems"].hasOwnProperty("items") && (selected["lineItems"].items.length) > 0) {
                                    selected["lineItems"] = selected["lineItems"].items.map(item => {
                                        if(item._deleted) return null;
                                        return {id: item.lineItemType.id, name: item.lineItemType.description};
                                    });
                                }
                            }

                            setAlert(
                                <AlertForm
                                    style={{overflow: "scroll"}}
                                    hideAlert={hideAlert}
                                    notificationAlertRef={notificationAlertRef}
                                    item={item}
                                    FormFields={FormFields}
                                    action={action}
                                    currentFormData={selected}
                                    editItem={editItem}
                                    itemType={"Update " + itemType}
                                />
                            );
                        });
                    }}
                    variant="success">
                    <i className="fas fa-edit"></i>
                </Button>
            </OverlayTrigger>
        );
    } else {
        return null;
    }
};

export default EditItemButton;

