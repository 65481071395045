import React, { useContext, useRef } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import { uploadsByIs_definitionAndType } from '../../graphql/customQueries';
import NotificationAlert from "react-notification-alert";




const headers = [
    'name',
    'type',
    'status',
];

const UploadedFilesView = React.forwardRef((props, ref) => {
    const currentUser = useContext(UserContext);
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    //eslint-disable-next-line
    const hideAlert = () => {
        setAlert(null);
    };
    const getRowActions = (row) => {
        // Assuming default actions or permissions can be added here
        return [];
    };
    console.debug("This is a debug message");
    console.log("This is a log message");
    console.debug("UploadedFilesView currentUser", currentUser);
    return (
        <div>
            {alert}

            <NotificationAlert ref={notificationAlertRef} />
            <Table
                permission={currentUser.permissions.RecordType}
                headers={headers}
                query={uploadsByIs_definitionAndType}
                queryName="listUploads"
                queryParams={{is_definition: "No"}}
                //CreatItemAction={None}
                getRowActions={getRowActions}
                option={false}
                itemType="Files"
            />
        </div>
    )
});

export default UploadedFilesView;
