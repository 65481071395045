import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import AlertForm from "../Forms/AlertForm";


const CreateItemButton = ({
    permissions,
    action,
    FormFields,
    currentFormData,
    editItem,
    hideAlert,
    notificationAlertRef,
    setAlert,
    itemType,
}) => {
    if (permissions) {
        return (
            <OverlayTrigger
                onClick={(e) => e.preventDefault()}
                overlay={
                    <Tooltip id="tooltip-48903503">
                        {`Add new ${itemType}`}
                    </Tooltip>
                }
            >
                <Button
                    className="btn-link btn-xs"
                    onClick={(e) => {
                        e.preventDefault();
                        setAlert(
                            <AlertForm
                                hideAlert={hideAlert}
                                notificationAlertRef={notificationAlertRef}
                                FormFields={FormFields}
                                action={action}
                                currentFormData={currentFormData}
                                editItem={editItem}
                                itemType={"New " + itemType}
                            />
                        );
                    }}
                    variant="Primary"
                >
                    <i className="nc-icon nc-simple-add icon-bold icon-big"></i>
                </Button>
            </OverlayTrigger>
        );
    } else {
        return null;
    }
};

export default CreateItemButton;
