/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInstructorClasses = /* GraphQL */ `
  subscription OnCreateInstructorClasses(
    $filter: ModelSubscriptionInstructorClassesFilterInput
  ) {
    onCreateInstructorClasses(filter: $filter) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInstructorClasses = /* GraphQL */ `
  subscription OnUpdateInstructorClasses(
    $filter: ModelSubscriptionInstructorClassesFilterInput
  ) {
    onUpdateInstructorClasses(filter: $filter) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInstructorClasses = /* GraphQL */ `
  subscription OnDeleteInstructorClasses(
    $filter: ModelSubscriptionInstructorClassesFilterInput
  ) {
    onDeleteInstructorClasses(filter: $filter) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateStudentsClasses = /* GraphQL */ `
  subscription OnCreateStudentsClasses(
    $filter: ModelSubscriptionStudentsClassesFilterInput
  ) {
    onCreateStudentsClasses(filter: $filter) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateStudentsClasses = /* GraphQL */ `
  subscription OnUpdateStudentsClasses(
    $filter: ModelSubscriptionStudentsClassesFilterInput
  ) {
    onUpdateStudentsClasses(filter: $filter) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteStudentsClasses = /* GraphQL */ `
  subscription OnDeleteStudentsClasses(
    $filter: ModelSubscriptionStudentsClassesFilterInput
  ) {
    onDeleteStudentsClasses(filter: $filter) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDemographics = /* GraphQL */ `
  subscription OnCreateDemographics(
    $filter: ModelSubscriptionDemographicsFilterInput
  ) {
    onCreateDemographics(filter: $filter) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDemographics = /* GraphQL */ `
  subscription OnUpdateDemographics(
    $filter: ModelSubscriptionDemographicsFilterInput
  ) {
    onUpdateDemographics(filter: $filter) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDemographics = /* GraphQL */ `
  subscription OnDeleteDemographics(
    $filter: ModelSubscriptionDemographicsFilterInput
  ) {
    onDeleteDemographics(filter: $filter) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateDistricts = /* GraphQL */ `
  subscription OnCreateDistricts(
    $filter: ModelSubscriptionDistrictsFilterInput
  ) {
    onCreateDistricts(filter: $filter) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateDistricts = /* GraphQL */ `
  subscription OnUpdateDistricts(
    $filter: ModelSubscriptionDistrictsFilterInput
  ) {
    onUpdateDistricts(filter: $filter) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteDistricts = /* GraphQL */ `
  subscription OnDeleteDistricts(
    $filter: ModelSubscriptionDistrictsFilterInput
  ) {
    onDeleteDistricts(filter: $filter) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateBuildings = /* GraphQL */ `
  subscription OnCreateBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onCreateBuildings(filter: $filter) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBuildings = /* GraphQL */ `
  subscription OnUpdateBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onUpdateBuildings(filter: $filter) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBuildings = /* GraphQL */ `
  subscription OnDeleteBuildings(
    $filter: ModelSubscriptionBuildingsFilterInput
  ) {
    onDeleteBuildings(filter: $filter) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAISSupport = /* GraphQL */ `
  subscription OnCreateAISSupport(
    $filter: ModelSubscriptionAISSupportFilterInput
  ) {
    onCreateAISSupport(filter: $filter) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAISSupport = /* GraphQL */ `
  subscription OnUpdateAISSupport(
    $filter: ModelSubscriptionAISSupportFilterInput
  ) {
    onUpdateAISSupport(filter: $filter) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAISSupport = /* GraphQL */ `
  subscription OnDeleteAISSupport(
    $filter: ModelSubscriptionAISSupportFilterInput
  ) {
    onDeleteAISSupport(filter: $filter) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSchoolYears = /* GraphQL */ `
  subscription OnCreateSchoolYears(
    $filter: ModelSubscriptionSchoolYearsFilterInput
  ) {
    onCreateSchoolYears(filter: $filter) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSchoolYears = /* GraphQL */ `
  subscription OnUpdateSchoolYears(
    $filter: ModelSubscriptionSchoolYearsFilterInput
  ) {
    onUpdateSchoolYears(filter: $filter) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSchoolYears = /* GraphQL */ `
  subscription OnDeleteSchoolYears(
    $filter: ModelSubscriptionSchoolYearsFilterInput
  ) {
    onDeleteSchoolYears(filter: $filter) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateGroups = /* GraphQL */ `
  subscription OnCreateGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onCreateGroups(filter: $filter) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateGroups = /* GraphQL */ `
  subscription OnUpdateGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onUpdateGroups(filter: $filter) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteGroups = /* GraphQL */ `
  subscription OnDeleteGroups($filter: ModelSubscriptionGroupsFilterInput) {
    onDeleteGroups(filter: $filter) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAlerts = /* GraphQL */ `
  subscription OnCreateAlerts($filter: ModelSubscriptionAlertsFilterInput) {
    onCreateAlerts(filter: $filter) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAlerts = /* GraphQL */ `
  subscription OnUpdateAlerts($filter: ModelSubscriptionAlertsFilterInput) {
    onUpdateAlerts(filter: $filter) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAlerts = /* GraphQL */ `
  subscription OnDeleteAlerts($filter: ModelSubscriptionAlertsFilterInput) {
    onDeleteAlerts(filter: $filter) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClasses = /* GraphQL */ `
  subscription OnCreateClasses($filter: ModelSubscriptionClassesFilterInput) {
    onCreateClasses(filter: $filter) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClasses = /* GraphQL */ `
  subscription OnUpdateClasses($filter: ModelSubscriptionClassesFilterInput) {
    onUpdateClasses(filter: $filter) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClasses = /* GraphQL */ `
  subscription OnDeleteClasses($filter: ModelSubscriptionClassesFilterInput) {
    onDeleteClasses(filter: $filter) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUploads = /* GraphQL */ `
  subscription OnCreateUploads($filter: ModelSubscriptionUploadsFilterInput) {
    onCreateUploads(filter: $filter) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUploads = /* GraphQL */ `
  subscription OnUpdateUploads($filter: ModelSubscriptionUploadsFilterInput) {
    onUpdateUploads(filter: $filter) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUploads = /* GraphQL */ `
  subscription OnDeleteUploads($filter: ModelSubscriptionUploadsFilterInput) {
    onDeleteUploads(filter: $filter) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRecordType = /* GraphQL */ `
  subscription OnCreateRecordType(
    $filter: ModelSubscriptionRecordTypeFilterInput
  ) {
    onCreateRecordType(filter: $filter) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRecordType = /* GraphQL */ `
  subscription OnUpdateRecordType(
    $filter: ModelSubscriptionRecordTypeFilterInput
  ) {
    onUpdateRecordType(filter: $filter) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRecordType = /* GraphQL */ `
  subscription OnDeleteRecordType(
    $filter: ModelSubscriptionRecordTypeFilterInput
  ) {
    onDeleteRecordType(filter: $filter) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAttendance = /* GraphQL */ `
  subscription OnCreateAttendance(
    $filter: ModelSubscriptionAttendanceFilterInput
  ) {
    onCreateAttendance(filter: $filter) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAttendance = /* GraphQL */ `
  subscription OnUpdateAttendance(
    $filter: ModelSubscriptionAttendanceFilterInput
  ) {
    onUpdateAttendance(filter: $filter) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAttendance = /* GraphQL */ `
  subscription OnDeleteAttendance(
    $filter: ModelSubscriptionAttendanceFilterInput
  ) {
    onDeleteAttendance(filter: $filter) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLineItemType = /* GraphQL */ `
  subscription OnCreateLineItemType(
    $filter: ModelSubscriptionLineItemTypeFilterInput
  ) {
    onCreateLineItemType(filter: $filter) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLineItemType = /* GraphQL */ `
  subscription OnUpdateLineItemType(
    $filter: ModelSubscriptionLineItemTypeFilterInput
  ) {
    onUpdateLineItemType(filter: $filter) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLineItemType = /* GraphQL */ `
  subscription OnDeleteLineItemType(
    $filter: ModelSubscriptionLineItemTypeFilterInput
  ) {
    onDeleteLineItemType(filter: $filter) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLearningStandards = /* GraphQL */ `
  subscription OnCreateLearningStandards(
    $filter: ModelSubscriptionLearningStandardsFilterInput
  ) {
    onCreateLearningStandards(filter: $filter) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLearningStandards = /* GraphQL */ `
  subscription OnUpdateLearningStandards(
    $filter: ModelSubscriptionLearningStandardsFilterInput
  ) {
    onUpdateLearningStandards(filter: $filter) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLearningStandards = /* GraphQL */ `
  subscription OnDeleteLearningStandards(
    $filter: ModelSubscriptionLearningStandardsFilterInput
  ) {
    onDeleteLearningStandards(filter: $filter) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLearningApplication = /* GraphQL */ `
  subscription OnCreateLearningApplication(
    $filter: ModelSubscriptionLearningApplicationFilterInput
  ) {
    onCreateLearningApplication(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLearningApplication = /* GraphQL */ `
  subscription OnUpdateLearningApplication(
    $filter: ModelSubscriptionLearningApplicationFilterInput
  ) {
    onUpdateLearningApplication(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLearningApplication = /* GraphQL */ `
  subscription OnDeleteLearningApplication(
    $filter: ModelSubscriptionLearningApplicationFilterInput
  ) {
    onDeleteLearningApplication(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLearningKnowledge = /* GraphQL */ `
  subscription OnCreateLearningKnowledge(
    $filter: ModelSubscriptionLearningKnowledgeFilterInput
  ) {
    onCreateLearningKnowledge(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLearningKnowledge = /* GraphQL */ `
  subscription OnUpdateLearningKnowledge(
    $filter: ModelSubscriptionLearningKnowledgeFilterInput
  ) {
    onUpdateLearningKnowledge(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLearningKnowledge = /* GraphQL */ `
  subscription OnDeleteLearningKnowledge(
    $filter: ModelSubscriptionLearningKnowledgeFilterInput
  ) {
    onDeleteLearningKnowledge(filter: $filter) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateStudentStandardsRecords = /* GraphQL */ `
  subscription OnCreateStudentStandardsRecords(
    $filter: ModelSubscriptionStudentStandardsRecordsFilterInput
  ) {
    onCreateStudentStandardsRecords(filter: $filter) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateStudentStandardsRecords = /* GraphQL */ `
  subscription OnUpdateStudentStandardsRecords(
    $filter: ModelSubscriptionStudentStandardsRecordsFilterInput
  ) {
    onUpdateStudentStandardsRecords(filter: $filter) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteStudentStandardsRecords = /* GraphQL */ `
  subscription OnDeleteStudentStandardsRecords(
    $filter: ModelSubscriptionStudentStandardsRecordsFilterInput
  ) {
    onDeleteStudentStandardsRecords(filter: $filter) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateStudentRecords = /* GraphQL */ `
  subscription OnCreateStudentRecords(
    $filter: ModelSubscriptionStudentRecordsFilterInput
  ) {
    onCreateStudentRecords(filter: $filter) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateStudentRecords = /* GraphQL */ `
  subscription OnUpdateStudentRecords(
    $filter: ModelSubscriptionStudentRecordsFilterInput
  ) {
    onUpdateStudentRecords(filter: $filter) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteStudentRecords = /* GraphQL */ `
  subscription OnDeleteStudentRecords(
    $filter: ModelSubscriptionStudentRecordsFilterInput
  ) {
    onDeleteStudentRecords(filter: $filter) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUserSettings = /* GraphQL */ `
  subscription OnCreateUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
  ) {
    onCreateUserSettings(filter: $filter) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUserSettings = /* GraphQL */ `
  subscription OnUpdateUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
  ) {
    onUpdateUserSettings(filter: $filter) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUserSettings = /* GraphQL */ `
  subscription OnDeleteUserSettings(
    $filter: ModelSubscriptionUserSettingsFilterInput
  ) {
    onDeleteUserSettings(filter: $filter) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSystemSettings = /* GraphQL */ `
  subscription OnCreateSystemSettings(
    $filter: ModelSubscriptionSystemSettingsFilterInput
  ) {
    onCreateSystemSettings(filter: $filter) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSystemSettings = /* GraphQL */ `
  subscription OnUpdateSystemSettings(
    $filter: ModelSubscriptionSystemSettingsFilterInput
  ) {
    onUpdateSystemSettings(filter: $filter) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSystemSettings = /* GraphQL */ `
  subscription OnDeleteSystemSettings(
    $filter: ModelSubscriptionSystemSettingsFilterInput
  ) {
    onDeleteSystemSettings(filter: $filter) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLLMQuery = /* GraphQL */ `
  subscription OnCreateLLMQuery($filter: ModelSubscriptionLLMQueryFilterInput) {
    onCreateLLMQuery(filter: $filter) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLLMQuery = /* GraphQL */ `
  subscription OnUpdateLLMQuery($filter: ModelSubscriptionLLMQueryFilterInput) {
    onUpdateLLMQuery(filter: $filter) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLLMQuery = /* GraphQL */ `
  subscription OnDeleteLLMQuery($filter: ModelSubscriptionLLMQueryFilterInput) {
    onDeleteLLMQuery(filter: $filter) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUsersGroups = /* GraphQL */ `
  subscription OnCreateUsersGroups(
    $filter: ModelSubscriptionUsersGroupsFilterInput
  ) {
    onCreateUsersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsersGroups = /* GraphQL */ `
  subscription OnUpdateUsersGroups(
    $filter: ModelSubscriptionUsersGroupsFilterInput
  ) {
    onUpdateUsersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsersGroups = /* GraphQL */ `
  subscription OnDeleteUsersGroups(
    $filter: ModelSubscriptionUsersGroupsFilterInput
  ) {
    onDeleteUsersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateManagersGroups = /* GraphQL */ `
  subscription OnCreateManagersGroups(
    $filter: ModelSubscriptionManagersGroupsFilterInput
  ) {
    onCreateManagersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateManagersGroups = /* GraphQL */ `
  subscription OnUpdateManagersGroups(
    $filter: ModelSubscriptionManagersGroupsFilterInput
  ) {
    onUpdateManagersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteManagersGroups = /* GraphQL */ `
  subscription OnDeleteManagersGroups(
    $filter: ModelSubscriptionManagersGroupsFilterInput
  ) {
    onDeleteManagersGroups(filter: $filter) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUsersBuildings = /* GraphQL */ `
  subscription OnCreateUsersBuildings(
    $filter: ModelSubscriptionUsersBuildingsFilterInput
  ) {
    onCreateUsersBuildings(filter: $filter) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsersBuildings = /* GraphQL */ `
  subscription OnUpdateUsersBuildings(
    $filter: ModelSubscriptionUsersBuildingsFilterInput
  ) {
    onUpdateUsersBuildings(filter: $filter) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsersBuildings = /* GraphQL */ `
  subscription OnDeleteUsersBuildings(
    $filter: ModelSubscriptionUsersBuildingsFilterInput
  ) {
    onDeleteUsersBuildings(filter: $filter) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUsersClasses = /* GraphQL */ `
  subscription OnCreateUsersClasses(
    $filter: ModelSubscriptionUsersClassesFilterInput
  ) {
    onCreateUsersClasses(filter: $filter) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsersClasses = /* GraphQL */ `
  subscription OnUpdateUsersClasses(
    $filter: ModelSubscriptionUsersClassesFilterInput
  ) {
    onUpdateUsersClasses(filter: $filter) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsersClasses = /* GraphQL */ `
  subscription OnDeleteUsersClasses(
    $filter: ModelSubscriptionUsersClassesFilterInput
  ) {
    onDeleteUsersClasses(filter: $filter) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClassesGroups = /* GraphQL */ `
  subscription OnCreateClassesGroups(
    $filter: ModelSubscriptionClassesGroupsFilterInput
  ) {
    onCreateClassesGroups(filter: $filter) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClassesGroups = /* GraphQL */ `
  subscription OnUpdateClassesGroups(
    $filter: ModelSubscriptionClassesGroupsFilterInput
  ) {
    onUpdateClassesGroups(filter: $filter) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClassesGroups = /* GraphQL */ `
  subscription OnDeleteClassesGroups(
    $filter: ModelSubscriptionClassesGroupsFilterInput
  ) {
    onDeleteClassesGroups(filter: $filter) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClassesRecordType = /* GraphQL */ `
  subscription OnCreateClassesRecordType(
    $filter: ModelSubscriptionClassesRecordTypeFilterInput
  ) {
    onCreateClassesRecordType(filter: $filter) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClassesRecordType = /* GraphQL */ `
  subscription OnUpdateClassesRecordType(
    $filter: ModelSubscriptionClassesRecordTypeFilterInput
  ) {
    onUpdateClassesRecordType(filter: $filter) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClassesRecordType = /* GraphQL */ `
  subscription OnDeleteClassesRecordType(
    $filter: ModelSubscriptionClassesRecordTypeFilterInput
  ) {
    onDeleteClassesRecordType(filter: $filter) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClassesLearningStandards = /* GraphQL */ `
  subscription OnCreateClassesLearningStandards(
    $filter: ModelSubscriptionClassesLearningStandardsFilterInput
  ) {
    onCreateClassesLearningStandards(filter: $filter) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClassesLearningStandards = /* GraphQL */ `
  subscription OnUpdateClassesLearningStandards(
    $filter: ModelSubscriptionClassesLearningStandardsFilterInput
  ) {
    onUpdateClassesLearningStandards(filter: $filter) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClassesLearningStandards = /* GraphQL */ `
  subscription OnDeleteClassesLearningStandards(
    $filter: ModelSubscriptionClassesLearningStandardsFilterInput
  ) {
    onDeleteClassesLearningStandards(filter: $filter) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateRecordTypeLineItems = /* GraphQL */ `
  subscription OnCreateRecordTypeLineItems(
    $filter: ModelSubscriptionRecordTypeLineItemsFilterInput
  ) {
    onCreateRecordTypeLineItems(filter: $filter) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateRecordTypeLineItems = /* GraphQL */ `
  subscription OnUpdateRecordTypeLineItems(
    $filter: ModelSubscriptionRecordTypeLineItemsFilterInput
  ) {
    onUpdateRecordTypeLineItems(filter: $filter) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteRecordTypeLineItems = /* GraphQL */ `
  subscription OnDeleteRecordTypeLineItems(
    $filter: ModelSubscriptionRecordTypeLineItemsFilterInput
  ) {
    onDeleteRecordTypeLineItems(filter: $filter) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
