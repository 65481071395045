import './App.css';
import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { listSchoolYears } from './graphql/queries';
import HomeLayout from './layouts/Home';
import AuthLayout from './layouts/Auth';
import UserContext from './contexts/UserContext';
import { accessRights } from "./permissionsMapping";

// AWS configuration
Amplify.configure({
  ...awsExports
});
Amplify.Logger.LOG_LEVEL = 'ERROR';

function App() {
  const { user, signOut, authStatus } = useAuthenticator(context => [context?.user, context.authStatus]);
  const [currentUser, setCurrentUser] = useState({});
  const [activeSchoolYear, setActiveSchoolYear] = useState({});
  const [permissions, setPermissions] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const production = ["production","main","master","prod","stg"];
  // Handle user sign-out and clear local storage
  const signUserOut = () => {
    localStorage.clear();
    signOut();

  };

  // Load stored user details and permissions from local storage
  const loadStoredDetails = () => {
    try {
      const storedUserContext = JSON.parse(localStorage.getItem('userContext'));
      let storedPermissions = JSON.parse(localStorage.getItem('permissions'));

      if (storedUserContext) {
        setCurrentUser(storedUserContext.currentUser || {});
        setSelectedUser(storedUserContext.selectedUser || null);
        setActiveSchoolYear(storedUserContext.activeSchoolYear || {});

        if (!storedPermissions && storedUserContext.currentUser) {
          storedPermissions = accessRights[storedUserContext.currentUser.currentGroup];
        }

        if (storedPermissions) {
          setPermissions(storedPermissions);
        }
      }
    }
    catch (error) {
      console.error("Error loading stored details:", error);
    }
  };

  // Handle authenticated user's data retrieval and local storage updates
  const handleAuthenticatedUser = async () => {
    if (!user?.signInUserSession?.accessToken?.payload?.['cognito:groups']) return;

    try {
      const result = await API.graphql(graphqlOperation(listSchoolYears, { filter: { active: { eq: true } } }));
      const schoolYear = result.data.listSchoolYears.items[0];

      const userDetails = {
        value: user.attributes['custom:user_id'],
        label: `${user.attributes.given_name} ${user.attributes.family_name}`,
        group: user.signInUserSession.accessToken.payload['cognito:groups'][0],
        email: user.attributes.email
      };
      const userPermissions = accessRights[user.signInUserSession.accessToken.payload['cognito:groups'][0]];
      const userData = {
        username: user.username,
        attributes: user.attributes,
        first_name: user.attributes.given_name,
        district_id: user.attributes['custom:district_id'],
        district_name: user.attributes['custom:district_name'],
        currentGroup: user.signInUserSession.accessToken.payload['cognito:groups'][0],
        email: user.attributes.email,
        consoleLog: console.log,
      };
      if (process.env.NODE_ENV in production && currentUser.currentUser.email.includes(
            "arktci.com")) {
        console.log('Production Mode')
        console.debug = function () {};
        console.log = function () {};
        console.error = function () {};

      }
      setSelectedUser(userDetails);
      setCurrentUser(userData);
      setActiveSchoolYear({ schoolYear_id: schoolYear.id, schoolYear_name: schoolYear.name });
      setPermissions(userPermissions);

      localStorage.setItem('userContext', JSON.stringify({
        currentUser: userData,
        selectedUser: userDetails,
        activeSchoolYear: { schoolYear_id: schoolYear.id, schoolYear_name: schoolYear.name },
      }));
      localStorage.setItem('permissions', JSON.stringify(userPermissions));

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //useEffect(loadStoredDetails, []);
  useEffect(() => {
    // Load details from storage when component mounts.
    // Check if the user is authenticated before trying to fetch data.
    if (authStatus === 'authenticated') {
      loadStoredDetails();
      // Define async function to fetch user data.
      const fetchUserData = async () => {
        try {
          await handleAuthenticatedUser();
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      // Invoke the async function.
      fetchUserData();
    }
    // eslint-disable-next-line
  }, [authStatus]);

  return (
      <UserContext.Provider value={{ currentUser, selectedUser, setSelectedUser, activeSchoolYear, permissions }}>
        {authStatus !== 'authenticated' ? <AuthLayout /> : <HomeLayout user={user} onUserIdChange={console.debug} signOut={signUserOut} authStatus={authStatus} />}
      </UserContext.Provider>
  );
}

export default App;
