import React, {useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import UserContext from '../../contexts/UserContext';
// react-bootstrap components
import {
  Button,
  Dropdown,
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";


const originalConsoleDebug = console.debug;
const originalConsoleError = console.error;
const originalConsoleLog = console.log;
const DebugMenu = ({isLoading, currentUser, debugState, setDebugState}) => {
  if (!isLoading && currentUser?.currentUser?.email.includes("arktci.com")) {
    return (
        <Dropdown.Item
            alignright={"true"}
            className={"text-secondary w-100  text-xs whitespace-normal overflow-hidden text-overflow-ellipsis"}
            onClick={() => setDebugState(prevState => !prevState)}
        >
          <i className="nc-icon nc-support-17"></i>
          Debug Mode: {debugState ? "Enabled" : "Disabled"}
        </Dropdown.Item>
    )
  }

  return null;
}
const handleDebugStateChange = (debugState, isLoading, currentUser, setIsLoading) => {
  if(isLoading || !currentUser?.currentUser){
    return;
  }
  setIsLoading(true);
  if(debugState){
    console.debug = originalConsoleDebug;
    console.log = originalConsoleLog;
    console.error = originalConsoleError;
    console.log('Console Debugging Enabled for ' + currentUser.currentUser.email )
  }
  else {
    console.log('Console Debugging Disabled for', currentUser.currentUser.email)
    console.debug = function () {};
    console.log = function () {};
    console.error = function () {};
  }
  setIsLoading(false);
}

function AdminNavbar({signOut}) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [debugState, setDebugState] = React.useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const currentUser = useContext(UserContext);
  useEffect(() => {
    if (currentUser?.currentUser?.email?.includes("arktci.com"))setDebugState(true);
  }, [currentUser]);
  useEffect(() => {
    if (!currentUser?.currentUser) return;
    handleDebugStateChange(debugState, isLoading, currentUser, setIsLoading);
  }, [debugState, isLoading, currentUser, setIsLoading]);



  return (
    <>
      <Navbar expand="md">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Dropdown as={Nav.Item} className={'dropdown-menu-right'}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                >
                  <i className="nc-icon nc-bullet-list-67"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    align="end"
                    alignright={"true"}
                    aria-labelledby="navbarDropdownMenuLink"
                    className={"whitespace-normal text-overflow-ellipsis "}
                    style={{minWidth: "14rem"}}
                >
                  <Dropdown.Item
                    alignright={"true"}
                    className={"text-secondary w-100  text-xs whitespace-normal overflow-hidden text-overflow-ellipsis"}
                    onClick={() => history.push('/home/change-password')}
                  >
                    <i className="nc-icon nc-key-25"></i>
                      Change Password
                  </Dropdown.Item>
                  <DebugMenu isLoading={isLoading}
                             currentUser={currentUser}
                             debugState={debugState}
                             setDebugState={setDebugState} />
                  <Dropdown.Item
                      alignright={"true"}
                      className="text-primary">
                    <i className="nc-icon nc-bell-55"></i>
                    {"Active: " + currentUser.activeSchoolYear?.schoolYear_name || "No Active School Year"}
                  </Dropdown.Item>

                  <Dropdown.Item
                    alignright={"true"}
                    className="text-danger"
                    onClick={ () => {
                      signOut()
                    }}>
                    <i className="nc-icon nc-button-power"></i>
                    Log out
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
