import React, { useState, useContext, useRef, useEffect } from 'react';
// import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import {getClassesEdit ,classesBySchoolYear_id } from '../../graphql/customQueries';
import { createClass, deleteClass, updateClass } from "../../utils/db_classes_graph";
import {ClassFormFields} from '../Forms/FormFields';
import UserContext from '../../contexts/UserContext';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";
import {Container} from "react-bootstrap";


const ClassesView = (props) => {
    // const currentUser = useContext(UserContext);
    // const [queryRole, setQueryRole] = React.useState(Roles.STAFF);
    const currentUser = useContext(UserContext);
    const newItemForm = {district_id: currentUser.currentUser.district_id, district_name: currentUser.currentUser.district_name, values: { buildings: null, students: null } };
    //eslint-disable-next-line
    const [query, setQuery] = useState(classesBySchoolYear_id);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] =  useState({schoolYear_id: currentUser.activeSchoolYear.schoolYear_id, limit: 1000});
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);

    const [headers,setHeaders] = useState([
        'name',
        'schoolYear_name',
        'building_name'
    ]);
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['name']);
            } else {
                setHeaders([
                    'name',
                    'schoolYear_name',
                    'building_name']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    const hideAlert = () => {
        setAlert(null);
    };
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.Classes &&
            (currentUser.permissions.Classes.Update || currentUser.permissions.Classes.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    function CreatItemAction (){
        if(currentUser &&
            currentUser.permissions.Classes &&
            currentUser.permissions.Classes.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.Classes.Create}
                    action={createClass}
                    currentFormData={newItemForm}
                    FormFields={ClassFormFields}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`Class`}
                />)
        } else {
            return null
        }
    }
    const getRowActions = (row) => {
        const actions = []; // default actions
        //if (currentUser.permissions[role].create) actions.push(createNewUsers);
        if (currentUser &&
            currentUser.permissions.Classes &&
            currentUser.permissions.Classes.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Classes.Update,
                    action: updateClass,
                    FormFields: ClassFormFields,
                    itemQuery: getClassesEdit,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Class"
                }
            });
        }
        if (currentUser &&
            currentUser.permissions.Classes &&
            currentUser.permissions.Classes.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {

                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Classes.Delete,
                    action: deleteClass,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "Class"
                }
            });
        }
        return actions;
    };
    return (
     <Container fluid className="w-100 overflow-scroll">
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
            <Table
                permission={currentUser.permissions.Classes}
                headers={headers}
                query={query}
                queryName="listClasses"
                queryParams={queryParams}
                CreatItemAction={CreatItemAction}
                getRowActions={getRowActions}
                itemType="Class"
                option={showActions}
            />
     </Container>
    )
}
export default ClassesView;
