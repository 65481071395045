/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDataTable = /* GraphQL */ `
  query GetDataTable($input: getDataTableInput!) {
    getDataTable(input: $input) {
      items
      options
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByUserId = /* GraphQL */ `
  query UsersByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByStateId = /* GraphQL */ `
  query UsersByStateId(
    $stateId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStateId(
      stateId: $stateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByRoleAndCohortAndStatus = /* GraphQL */ `
  query UsersByRoleAndCohortAndStatus(
    $role: String!
    $cohortStatus: ModelUsersUsersByRoleCohortCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRoleAndCohortAndStatus(
      role: $role
      cohortStatus: $cohortStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByRoleAndStatus = /* GraphQL */ `
  query UsersByRoleAndStatus(
    $role: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRoleAndStatus(
      role: $role
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersByDistrict_id = /* GraphQL */ `
  query UsersByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInstructorClasses = /* GraphQL */ `
  query GetInstructorClasses($id: ID!) {
    getInstructorClasses(id: $id) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInstructorClasses = /* GraphQL */ `
  query ListInstructorClasses(
    $filter: ModelInstructorClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstructorClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        instructor_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInstructorClasses = /* GraphQL */ `
  query SyncInstructorClasses(
    $filter: ModelInstructorClassesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructorClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        instructor_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const instructorClassesByInstructor_idAndSchoolYear_name = /* GraphQL */ `
  query InstructorClassesByInstructor_idAndSchoolYear_name(
    $instructor_id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstructorClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorClassesByInstructor_idAndSchoolYear_name(
      instructor_id: $instructor_id
      schoolYear_name: $schoolYear_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        instructor_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const instructorClassesByClass_idAndSchoolYear_name = /* GraphQL */ `
  query InstructorClassesByClass_idAndSchoolYear_name(
    $class_id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstructorClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorClassesByClass_idAndSchoolYear_name(
      class_id: $class_id
      schoolYear_name: $schoolYear_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        instructor_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const instructorClassesBySchoolYear_idAndStatus = /* GraphQL */ `
  query InstructorClassesBySchoolYear_idAndStatus(
    $schoolYear_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInstructorClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instructorClassesBySchoolYear_idAndStatus(
      schoolYear_id: $schoolYear_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        instructor_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStudentsClasses = /* GraphQL */ `
  query GetStudentsClasses($id: ID!) {
    getStudentsClasses(id: $id) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStudentsClasses = /* GraphQL */ `
  query ListStudentsClasses(
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        student_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStudentsClasses = /* GraphQL */ `
  query SyncStudentsClasses(
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentsClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        student_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentsClassesByStudent_idAndSchoolYear_name = /* GraphQL */ `
  query StudentsClassesByStudent_idAndSchoolYear_name(
    $student_id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsClassesByStudent_idAndSchoolYear_name(
      student_id: $student_id
      schoolYear_name: $schoolYear_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        student_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentsClassesByClass_idAndSchoolYear_name = /* GraphQL */ `
  query StudentsClassesByClass_idAndSchoolYear_name(
    $class_id: ID!
    $schoolYear_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsClassesByClass_idAndSchoolYear_name(
      class_id: $class_id
      schoolYear_name: $schoolYear_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        student_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentsClassesBySchoolYear_idAndStatus = /* GraphQL */ `
  query StudentsClassesBySchoolYear_idAndStatus(
    $schoolYear_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentsClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentsClassesBySchoolYear_idAndStatus(
      schoolYear_id: $schoolYear_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        student_id
        userId
        stateId
        status
        class_id
        systemClassId
        schoolYear_id
        schoolYear_name
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDemographics = /* GraphQL */ `
  query GetDemographics($id: ID!) {
    getDemographics(id: $id) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDemographics = /* GraphQL */ `
  query ListDemographics(
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDemographics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDemographics = /* GraphQL */ `
  query SyncDemographics(
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDemographics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const demographicsByDistrict_id = /* GraphQL */ `
  query DemographicsByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    demographicsByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const demographicsByDistrict_idAndSchoolYear_nameAndGenderAndEthnicityAndChallenge_type = /* GraphQL */ `
  query DemographicsByDistrict_idAndSchoolYear_nameAndGenderAndEthnicityAndChallenge_type(
    $district_id: ID!
    $schoolYear_nameGenderEthnicityChallenge_type: ModelDemographicsDemographicsByDistrictCategoryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    demographicsByDistrict_idAndSchoolYear_nameAndGenderAndEthnicityAndChallenge_type(
      district_id: $district_id
      schoolYear_nameGenderEthnicityChallenge_type: $schoolYear_nameGenderEthnicityChallenge_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const demographicsBySchoolYear_nameAndStudent_id = /* GraphQL */ `
  query DemographicsBySchoolYear_nameAndStudent_id(
    $schoolYear_name: String!
    $student_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    demographicsBySchoolYear_nameAndStudent_id(
      schoolYear_name: $schoolYear_name
      student_id: $student_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const demographicsByStudent_id = /* GraphQL */ `
  query DemographicsByStudent_id(
    $student_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDemographicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    demographicsByStudent_id(
      student_id: $student_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        foster_care
        homeless
        lep_eligibility
        parent_armed_forces
        poverty
        challenge_type
        ethnicity
        gender
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDistricts = /* GraphQL */ `
  query GetDistricts($id: ID!) {
    getDistricts(id: $id) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $filter: ModelDistrictsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistricts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDistricts = /* GraphQL */ `
  query SyncDistricts(
    $filter: ModelDistrictsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDistricts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const districtsByName = /* GraphQL */ `
  query DistrictsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDistrictsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    districtsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBuildings = /* GraphQL */ `
  query GetBuildings($id: ID!) {
    getBuildings(id: $id) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBuildings = /* GraphQL */ `
  query SyncBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const buildingsByIdentifier = /* GraphQL */ `
  query BuildingsByIdentifier(
    $identifier: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsByIdentifier(
      identifier: $identifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const buildingsByDistrict_id = /* GraphQL */ `
  query BuildingsByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAISSupport = /* GraphQL */ `
  query GetAISSupport($id: ID!) {
    getAISSupport(id: $id) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAISSupports = /* GraphQL */ `
  query ListAISSupports(
    $filter: ModelAISSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAISSupports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        support_type
        class_id
        status
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAISSupports = /* GraphQL */ `
  query SyncAISSupports(
    $filter: ModelAISSupportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAISSupports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        support_type
        class_id
        status
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const aISSupportsBySchoolYear_idAndStatus = /* GraphQL */ `
  query AISSupportsBySchoolYear_idAndStatus(
    $schoolYear_id: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAISSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    aISSupportsBySchoolYear_idAndStatus(
      schoolYear_id: $schoolYear_id
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        support_type
        class_id
        status
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const aISSupportsByStudent_id = /* GraphQL */ `
  query AISSupportsByStudent_id(
    $student_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAISSupportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    aISSupportsByStudent_id(
      student_id: $student_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        support_type
        class_id
        status
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        student_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSchoolYears = /* GraphQL */ `
  query GetSchoolYears($id: ID!) {
    getSchoolYears(id: $id) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSchoolYears = /* GraphQL */ `
  query ListSchoolYears(
    $filter: ModelSchoolYearsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchoolYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        term_id
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSchoolYears = /* GraphQL */ `
  query SyncSchoolYears(
    $filter: ModelSchoolYearsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchoolYears(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const schoolYearsByDistrict_idAndName = /* GraphQL */ `
  query SchoolYearsByDistrict_idAndName(
    $district_id: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolYearsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolYearsByDistrict_idAndName(
      district_id: $district_id
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const schoolYearsByDistrict_idAndStartDate = /* GraphQL */ `
  query SchoolYearsByDistrict_idAndStartDate(
    $district_id: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolYearsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolYearsByDistrict_idAndStartDate(
      district_id: $district_id
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroups = /* GraphQL */ `
  query GetGroups($id: ID!) {
    getGroups(id: $id) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const groupsByDistrict_id = /* GraphQL */ `
  query GroupsByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAlerts = /* GraphQL */ `
  query GetAlerts($id: ID!) {
    getAlerts(id: $id) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAlerts = /* GraphQL */ `
  query SyncAlerts(
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByIsActiveAndType = /* GraphQL */ `
  query AlertsByIsActiveAndType(
    $isActive: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByIsActiveAndType(
      isActive: $isActive
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByUserId = /* GraphQL */ `
  query AlertsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByUserIdAndType = /* GraphQL */ `
  query AlertsByUserIdAndType(
    $userId: ID!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByUserIdAndType(
      userId: $userId
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertsByUserIdAndIsActive = /* GraphQL */ `
  query AlertsByUserIdAndIsActive(
    $userId: ID!
    $isActive: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertsByUserIdAndIsActive(
      userId: $userId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        active
        records
        isActive
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClasses = /* GraphQL */ `
  query GetClasses($id: ID!) {
    getClasses(id: $id) {
      id
      systemClassId
      credits
      stateClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClasses = /* GraphQL */ `
  query ListClasses(
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClasses = /* GraphQL */ `
  query SyncClasses(
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesBySystemClassId = /* GraphQL */ `
  query ClassesBySystemClassId(
    $systemClassId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesBySystemClassId(
      systemClassId: $systemClassId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesBySchoolYear_nameAndBuilding_id = /* GraphQL */ `
  query ClassesBySchoolYear_nameAndBuilding_id(
    $schoolYear_name: String!
    $building_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesBySchoolYear_nameAndBuilding_id(
      schoolYear_name: $schoolYear_name
      building_id: $building_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesBySchoolYear_id = /* GraphQL */ `
  query ClassesBySchoolYear_id(
    $schoolYear_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesBySchoolYear_id(
      schoolYear_id: $schoolYear_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesByBuilding_id = /* GraphQL */ `
  query ClassesByBuilding_id(
    $building_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByBuilding_id(
      building_id: $building_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesByBuilding_idAndSubjectAndAdvanced = /* GraphQL */ `
  query ClassesByBuilding_idAndSubjectAndAdvanced(
    $building_id: ID!
    $subjectAdvanced: ModelClassesClassesByBuildingSubjectAdcancedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByBuilding_idAndSubjectAndAdvanced(
      building_id: $building_id
      subjectAdvanced: $subjectAdvanced
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesByGradeAndBuilding_id = /* GraphQL */ `
  query ClassesByGradeAndBuilding_id(
    $grade: String!
    $building_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByGradeAndBuilding_id(
      grade: $grade
      building_id: $building_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        systemClassId
        credits
        stateClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUploads = /* GraphQL */ `
  query GetUploads($id: ID!) {
    getUploads(id: $id) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUploads = /* GraphQL */ `
  query SyncUploads(
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUploads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsByIs_definitionAndType = /* GraphQL */ `
  query UploadsByIs_definitionAndType(
    $is_definition: String!
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsByIs_definitionAndType(
      is_definition: $is_definition
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsBySchoolyear_idAndTypeAndIs_definitionAndStatus = /* GraphQL */ `
  query UploadsBySchoolyear_idAndTypeAndIs_definitionAndStatus(
    $schoolyear_id: ID!
    $typeIs_definitionStatus: ModelUploadsUploadsBySchoolYearIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsBySchoolyear_idAndTypeAndIs_definitionAndStatus(
      schoolyear_id: $schoolyear_id
      typeIs_definitionStatus: $typeIs_definitionStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsByDistrict_id = /* GraphQL */ `
  query UploadsByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsByOwner = /* GraphQL */ `
  query UploadsByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        type_id
        definition
        status
        is_definition
        schoolyear_id
        district_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRecordType = /* GraphQL */ `
  query GetRecordType($id: ID!) {
    getRecordType(id: $id) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRecordTypes = /* GraphQL */ `
  query ListRecordTypes(
    $filter: ModelRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecordTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRecordTypes = /* GraphQL */ `
  query SyncRecordTypes(
    $filter: ModelRecordTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecordTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordTypesByStandard_id = /* GraphQL */ `
  query RecordTypesByStandard_id(
    $standard_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypesByStandard_id(
      standard_id: $standard_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordTypesByGrade = /* GraphQL */ `
  query RecordTypesByGrade(
    $grade: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypesByGrade(
      grade: $grade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAttendance = /* GraphQL */ `
  query GetAttendance($id: ID!) {
    getAttendance(id: $id) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAttendances = /* GraphQL */ `
  query ListAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAttendances = /* GraphQL */ `
  query SyncAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const attendancesByStudent_idAndTypeAndSchoolYear_idAndStatus = /* GraphQL */ `
  query AttendancesByStudent_idAndTypeAndSchoolYear_idAndStatus(
    $student_id: ID!
    $typeSchoolYear_idStatus: ModelAttendanceAttendanceByStudentIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByStudent_idAndTypeAndSchoolYear_idAndStatus(
      student_id: $student_id
      typeSchoolYear_idStatus: $typeSchoolYear_idStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const attendancesByClass_idAndTypeAndSchoolYear_idAndStatus = /* GraphQL */ `
  query AttendancesByClass_idAndTypeAndSchoolYear_idAndStatus(
    $class_id: ID!
    $typeSchoolYear_idStatus: ModelAttendanceAttendanceByClassIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByClass_idAndTypeAndSchoolYear_idAndStatus(
      class_id: $class_id
      typeSchoolYear_idStatus: $typeSchoolYear_idStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const attendancesByBuilding_idAndTypeAndSchoolYear_idAndStatus = /* GraphQL */ `
  query AttendancesByBuilding_idAndTypeAndSchoolYear_idAndStatus(
    $building_id: ID!
    $typeSchoolYear_idStatus: ModelAttendanceAttendanceByBuildingIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByBuilding_idAndTypeAndSchoolYear_idAndStatus(
      building_id: $building_id
      typeSchoolYear_idStatus: $typeSchoolYear_idStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const attendancesBySchoolYear_idAndTypeAndStatusAndDate = /* GraphQL */ `
  query AttendancesBySchoolYear_idAndTypeAndStatusAndDate(
    $schoolYear_id: ID!
    $typeStatusDate: ModelAttendanceAttendanceBySchoolYearIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesBySchoolYear_idAndTypeAndStatusAndDate(
      schoolYear_id: $schoolYear_id
      typeStatusDate: $typeStatusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const attendancesByTypeAndSchoolYear_id = /* GraphQL */ `
  query AttendancesByTypeAndSchoolYear_id(
    $type: String!
    $schoolYear_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendancesByTypeAndSchoolYear_id(
      type: $type
      schoolYear_id: $schoolYear_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        record_id
        date
        check_in
        check_out
        student_id
        class_id
        building_id
        schoolYear_id
        status
        description
        reason
        status_increment
        type
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLineItemType = /* GraphQL */ `
  query GetLineItemType($id: ID!) {
    getLineItemType(id: $id) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLineItemTypes = /* GraphQL */ `
  query ListLineItemTypes(
    $filter: ModelLineItemTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineItemTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLineItemTypes = /* GraphQL */ `
  query SyncLineItemTypes(
    $filter: ModelLineItemTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLineItemTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const lineItemTypesByType = /* GraphQL */ `
  query LineItemTypesByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLineItemTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineItemTypesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLearningStandards = /* GraphQL */ `
  query GetLearningStandards($id: ID!) {
    getLearningStandards(id: $id) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLearningStandards = /* GraphQL */ `
  query ListLearningStandards(
    $filter: ModelLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningStandards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLearningStandards = /* GraphQL */ `
  query SyncLearningStandards(
    $filter: ModelLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningStandards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const learningStandardsByName = /* GraphQL */ `
  query LearningStandardsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningStandardsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const learningStandardsByGrade = /* GraphQL */ `
  query LearningStandardsByGrade(
    $grade: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningStandardsByGrade(
      grade: $grade
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const learningStandardsByStandard_code = /* GraphQL */ `
  query LearningStandardsByStandard_code(
    $standard_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningStandardsByStandard_code(
      standard_code: $standard_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLearningApplication = /* GraphQL */ `
  query GetLearningApplication($id: ID!) {
    getLearningApplication(id: $id) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLearningApplications = /* GraphQL */ `
  query ListLearningApplications(
    $filter: ModelLearningApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLearningApplications = /* GraphQL */ `
  query SyncLearningApplications(
    $filter: ModelLearningApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const learningApplicationsByStandard_id = /* GraphQL */ `
  query LearningApplicationsByStandard_id(
    $standard_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningApplicationsByStandard_id(
      standard_id: $standard_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLearningKnowledge = /* GraphQL */ `
  query GetLearningKnowledge($id: ID!) {
    getLearningKnowledge(id: $id) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLearningKnowledges = /* GraphQL */ `
  query ListLearningKnowledges(
    $filter: ModelLearningKnowledgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningKnowledges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLearningKnowledges = /* GraphQL */ `
  query SyncLearningKnowledges(
    $filter: ModelLearningKnowledgeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLearningKnowledges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const learningKnowledgesByStandard_id = /* GraphQL */ `
  query LearningKnowledgesByStandard_id(
    $standard_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLearningKnowledgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    learningKnowledgesByStandard_id(
      standard_id: $standard_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        district_id
        standard_name
        standard_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStudentStandardsRecords = /* GraphQL */ `
  query GetStudentStandardsRecords($id: ID!) {
    getStudentStandardsRecords(id: $id) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStudentStandardsRecords = /* GraphQL */ `
  query ListStudentStandardsRecords(
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentStandardsRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStudentStandardsRecords = /* GraphQL */ `
  query SyncStudentStandardsRecords(
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentStandardsRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentStandardsRecordsByStudent_idAndRecord_type = /* GraphQL */ `
  query StudentStandardsRecordsByStudent_idAndRecord_type(
    $student_id: ID!
    $record_type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentStandardsRecordsByStudent_idAndRecord_type(
      student_id: $student_id
      record_type: $record_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentStandardsRecordsByClass_idAndRecord_type = /* GraphQL */ `
  query StudentStandardsRecordsByClass_idAndRecord_type(
    $class_id: ID!
    $record_type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentStandardsRecordsByClass_idAndRecord_type(
      class_id: $class_id
      record_type: $record_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentStandardsRecordsByBuilding_id = /* GraphQL */ `
  query StudentStandardsRecordsByBuilding_id(
    $building_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentStandardsRecordsByBuilding_id(
      building_id: $building_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentStandardsRecordsBySchoolYear_idAndBuilding_idAndRecord_typeAndStudent_id = /* GraphQL */ `
  query StudentStandardsRecordsBySchoolYear_idAndBuilding_idAndRecord_typeAndStudent_id(
    $schoolYear_id: ID!
    $building_idRecord_typeStudent_id: ModelStudentStandardsRecordsStudentStandardsRecordsBySchoolYearIdCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentStandardsRecordsBySchoolYear_idAndBuilding_idAndRecord_typeAndStudent_id(
      schoolYear_id: $schoolYear_id
      building_idRecord_typeStudent_id: $building_idRecord_typeStudent_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentStandardsRecordsByStandard_idAndRecord_type = /* GraphQL */ `
  query StudentStandardsRecordsByStandard_idAndRecord_type(
    $standard_id: ID!
    $record_type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentStandardsRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentStandardsRecordsByStandard_idAndRecord_type(
      standard_id: $standard_id
      record_type: $record_type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        record_type
        district_id
        student_id
        class_id
        building_id
        schoolYear_id
        standard_name
        standard_id
        application_name
        application_id
        knowledge_id
        knowledge_name
        insight_name
        transfer_name
        achieved
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStudentRecords = /* GraphQL */ `
  query GetStudentRecords($id: ID!) {
    getStudentRecords(id: $id) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStudentRecords = /* GraphQL */ `
  query ListStudentRecords(
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStudentRecords = /* GraphQL */ `
  query SyncStudentRecords(
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByStudent_idAndRecordType_id = /* GraphQL */ `
  query StudentRecordsByStudent_idAndRecordType_id(
    $student_id: ID!
    $recordType_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByStudent_idAndRecordType_id(
      student_id: $student_id
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByRecordType_id = /* GraphQL */ `
  query StudentRecordsByRecordType_id(
    $recordType_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByRecordType_id(
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByStandard_idAndRecordType_id = /* GraphQL */ `
  query StudentRecordsByStandard_idAndRecordType_id(
    $standard_id: ID!
    $recordType_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByStandard_idAndRecordType_id(
      standard_id: $standard_id
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByClass_id = /* GraphQL */ `
  query StudentRecordsByClass_id(
    $class_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByClass_id(
      class_id: $class_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByDistrict_idAndRecordType_id = /* GraphQL */ `
  query StudentRecordsByDistrict_idAndRecordType_id(
    $district_id: ID!
    $recordType_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByDistrict_idAndRecordType_id(
      district_id: $district_id
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsByBuilding_idAndRecordType_id = /* GraphQL */ `
  query StudentRecordsByBuilding_idAndRecordType_id(
    $building_id: String!
    $recordType_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsByBuilding_idAndRecordType_id(
      building_id: $building_id
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsBySchoolYear_idAndRecordType_id = /* GraphQL */ `
  query StudentRecordsBySchoolYear_idAndRecordType_id(
    $schoolYear_id: ID!
    $recordType_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsBySchoolYear_idAndRecordType_id(
      schoolYear_id: $schoolYear_id
      recordType_id: $recordType_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsBySchoolYear_nameAndRecordType_idAndSubjectAndBuilding_idAndClass_id = /* GraphQL */ `
  query StudentRecordsBySchoolYear_nameAndRecordType_idAndSubjectAndBuilding_idAndClass_id(
    $schoolYear_name: String!
    $recordType_idSubjectBuilding_idClass_id: ModelStudentRecordsRecordByIdSchoolYearTypeSubjectBuildingClassCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsBySchoolYear_nameAndRecordType_idAndSubjectAndBuilding_idAndClass_id(
      schoolYear_name: $schoolYear_name
      recordType_idSubjectBuilding_idClass_id: $recordType_idSubjectBuilding_idClass_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const studentRecordsBySchoolYear_nameAndRecordType_nameAndSubjectAndBuilding_nameAndClass_name = /* GraphQL */ `
  query StudentRecordsBySchoolYear_nameAndRecordType_nameAndSubjectAndBuilding_nameAndClass_name(
    $schoolYear_name: String!
    $recordType_nameSubjectBuilding_nameClass_name: ModelStudentRecordsRecordBySchoolYearTypeSubjectBuildingClassCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentRecordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentRecordsBySchoolYear_nameAndRecordType_nameAndSubjectAndBuilding_nameAndClass_name(
      schoolYear_name: $schoolYear_name
      recordType_nameSubjectBuilding_nameClass_name: $recordType_nameSubjectBuilding_nameClass_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subject
        date
        student_id
        recordType_id
        recordType_name
        standard_id
        class_id
        class_name
        district_id
        district_name
        building_id
        building_name
        schoolYear_id
        schoolYear_name
        dataTable_name
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserSettings = /* GraphQL */ `
  query GetUserSettings($id: ID!) {
    getUserSettings(id: $id) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserSettings = /* GraphQL */ `
  query ListUserSettings(
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        settings
        dashboards
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserSettings = /* GraphQL */ `
  query SyncUserSettings(
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        settings
        dashboards
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userSettingsByUser_id = /* GraphQL */ `
  query UserSettingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSettingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        settings
        dashboards
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSystemSettings = /* GraphQL */ `
  query GetSystemSettings($id: ID!) {
    getSystemSettings(id: $id) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSystemSettings = /* GraphQL */ `
  query ListSystemSettings(
    $filter: ModelSystemSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        district_id
        settings
        theme
        routes
        permissions
        notifications
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSystemSettings = /* GraphQL */ `
  query SyncSystemSettings(
    $filter: ModelSystemSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSystemSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        district_id
        settings
        theme
        routes
        permissions
        notifications
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const systemSettingsByDistrict_id = /* GraphQL */ `
  query SystemSettingsByDistrict_id(
    $district_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSystemSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemSettingsByDistrict_id(
      district_id: $district_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        district_id
        settings
        theme
        routes
        permissions
        notifications
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLLMQuery = /* GraphQL */ `
  query GetLLMQuery($id: ID!) {
    getLLMQuery(id: $id) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLLMQueries = /* GraphQL */ `
  query ListLLMQueries(
    $filter: ModelLLMQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLLMQueries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        conversation
        sql_statement
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLLMQueries = /* GraphQL */ `
  query SyncLLMQueries(
    $filter: ModelLLMQueryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLLMQueries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        conversation
        sql_statement
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsersGroups = /* GraphQL */ `
  query GetUsersGroups($id: ID!) {
    getUsersGroups(id: $id) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsersGroups = /* GraphQL */ `
  query ListUsersGroups(
    $filter: ModelUsersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsersGroups = /* GraphQL */ `
  query SyncUsersGroups(
    $filter: ModelUsersGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsersGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersGroupsByUsersId = /* GraphQL */ `
  query UsersGroupsByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersGroupsByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersGroupsByGroupsId = /* GraphQL */ `
  query UsersGroupsByGroupsId(
    $groupsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersGroupsByGroupsId(
      groupsId: $groupsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getManagersGroups = /* GraphQL */ `
  query GetManagersGroups($id: ID!) {
    getManagersGroups(id: $id) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listManagersGroups = /* GraphQL */ `
  query ListManagersGroups(
    $filter: ModelManagersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManagersGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncManagersGroups = /* GraphQL */ `
  query SyncManagersGroups(
    $filter: ModelManagersGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncManagersGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const managersGroupsByUsersId = /* GraphQL */ `
  query ManagersGroupsByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManagersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managersGroupsByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const managersGroupsByGroupsId = /* GraphQL */ `
  query ManagersGroupsByGroupsId(
    $groupsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelManagersGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managersGroupsByGroupsId(
      groupsId: $groupsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        groupsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsersBuildings = /* GraphQL */ `
  query GetUsersBuildings($id: ID!) {
    getUsersBuildings(id: $id) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsersBuildings = /* GraphQL */ `
  query ListUsersBuildings(
    $filter: ModelUsersBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersId
        buildingsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsersBuildings = /* GraphQL */ `
  query SyncUsersBuildings(
    $filter: ModelUsersBuildingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsersBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usersId
        buildingsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersBuildingsByUsersId = /* GraphQL */ `
  query UsersBuildingsByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBuildingsByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        buildingsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersBuildingsByBuildingsId = /* GraphQL */ `
  query UsersBuildingsByBuildingsId(
    $buildingsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBuildingsByBuildingsId(
      buildingsId: $buildingsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        buildingsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsersClasses = /* GraphQL */ `
  query GetUsersClasses($id: ID!) {
    getUsersClasses(id: $id) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsersClasses = /* GraphQL */ `
  query ListUsersClasses(
    $filter: ModelUsersClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsersClasses = /* GraphQL */ `
  query SyncUsersClasses(
    $filter: ModelUsersClassesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsersClasses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        usersId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersClassesByUsersId = /* GraphQL */ `
  query UsersClassesByUsersId(
    $usersId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersClassesByUsersId(
      usersId: $usersId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const usersClassesByClassesId = /* GraphQL */ `
  query UsersClassesByClassesId(
    $classesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersClassesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersClassesByClassesId(
      classesId: $classesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClassesGroups = /* GraphQL */ `
  query GetClassesGroups($id: ID!) {
    getClassesGroups(id: $id) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClassesGroups = /* GraphQL */ `
  query ListClassesGroups(
    $filter: ModelClassesGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassesGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupsId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClassesGroups = /* GraphQL */ `
  query SyncClassesGroups(
    $filter: ModelClassesGroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassesGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupsId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesGroupsByGroupsId = /* GraphQL */ `
  query ClassesGroupsByGroupsId(
    $groupsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesGroupsByGroupsId(
      groupsId: $groupsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupsId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesGroupsByClassesId = /* GraphQL */ `
  query ClassesGroupsByClassesId(
    $classesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesGroupsByClassesId(
      classesId: $classesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupsId
        classesId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClassesRecordType = /* GraphQL */ `
  query GetClassesRecordType($id: ID!) {
    getClassesRecordType(id: $id) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClassesRecordTypes = /* GraphQL */ `
  query ListClassesRecordTypes(
    $filter: ModelClassesRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassesRecordTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        recordTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClassesRecordTypes = /* GraphQL */ `
  query SyncClassesRecordTypes(
    $filter: ModelClassesRecordTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassesRecordTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        classesId
        recordTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesRecordTypesByClassesId = /* GraphQL */ `
  query ClassesRecordTypesByClassesId(
    $classesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesRecordTypesByClassesId(
      classesId: $classesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        recordTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesRecordTypesByRecordTypeId = /* GraphQL */ `
  query ClassesRecordTypesByRecordTypeId(
    $recordTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesRecordTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesRecordTypesByRecordTypeId(
      recordTypeId: $recordTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        recordTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClassesLearningStandards = /* GraphQL */ `
  query GetClassesLearningStandards($id: ID!) {
    getClassesLearningStandards(id: $id) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClassesLearningStandards = /* GraphQL */ `
  query ListClassesLearningStandards(
    $filter: ModelClassesLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassesLearningStandards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        learningStandardsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClassesLearningStandards = /* GraphQL */ `
  query SyncClassesLearningStandards(
    $filter: ModelClassesLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClassesLearningStandards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        classesId
        learningStandardsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesLearningStandardsByClassesId = /* GraphQL */ `
  query ClassesLearningStandardsByClassesId(
    $classesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesLearningStandardsByClassesId(
      classesId: $classesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        learningStandardsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const classesLearningStandardsByLearningStandardsId = /* GraphQL */ `
  query ClassesLearningStandardsByLearningStandardsId(
    $learningStandardsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassesLearningStandardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesLearningStandardsByLearningStandardsId(
      learningStandardsId: $learningStandardsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classesId
        learningStandardsId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRecordTypeLineItems = /* GraphQL */ `
  query GetRecordTypeLineItems($id: ID!) {
    getRecordTypeLineItems(id: $id) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRecordTypeLineItems = /* GraphQL */ `
  query ListRecordTypeLineItems(
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecordTypeLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRecordTypeLineItems = /* GraphQL */ `
  query SyncRecordTypeLineItems(
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecordTypeLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordTypeLineItemsByRecordTypeId = /* GraphQL */ `
  query RecordTypeLineItemsByRecordTypeId(
    $recordTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypeLineItemsByRecordTypeId(
      recordTypeId: $recordTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordTypeLineItemsByLineItemTypeId = /* GraphQL */ `
  query RecordTypeLineItemsByLineItemTypeId(
    $lineItemTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordTypeLineItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordTypeLineItemsByLineItemTypeId(
      lineItemTypeId: $lineItemTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordTypeId
        lineItemTypeId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
