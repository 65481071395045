import { createDistricts, updateDistricts, deleteDistricts } from "../graphql/mutations";
import notificationOptions from "../views/Components/Notifications";
import { API } from "aws-amplify";

export const createDistrict = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        const propertiesToDelete = ["createdAt", "updatedAt", "_deleted", "_lastChangedAt", "values" ]
        propertiesToDelete.forEach((property) => {
            if (formData.hasOwnProperty(property)) {
                delete formData[property];
            }
        });
        const result = await API.graphql({
            query: createDistricts,
            variables: {
                input: {
                    ...formData
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("District Created Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`District While Creating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const updateDistrict = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        const propertiesToDelete = ["createdAt", "updatedAt", "_deleted", "_lastChangedAt", "values", "buildings", "schoolYear" ]
        propertiesToDelete.forEach((property) => {
            if (formData.hasOwnProperty(property)) {
                delete formData[property];
            }
        });
        const result = await API.graphql({
            query: updateDistricts,
            variables: {
                input: {
                    ...formData
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("District Updated Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`District While Updating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const deleteDistrict = async (item, hideAlert, notificationAlertRef) => {
    try {
        await API.graphql({
            query: deleteDistricts,
            variables: {
                input: {
                    id: item.id,
                    _version: item._version
                }
            }
        });
        let o = notificationOptions("District Deleted Successfully", "success");
        notificationAlertRef.current.notificationAlert(o);
        hideAlert();
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`District While Deleting Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}
