import {
    Permissions,
    Roles,
    AllowedItemTypes,
    Grades,
} from "../../models";
import _ from "lodash";
import { API } from "aws-amplify";
import {
    listDistricts,
    listBuildings,
    listSchoolYears, listLineItemTypes
} from "../../graphql/queries";
import { listUsersByRole } from "../../graphql/customQueries";
import { useState, useEffect, useContext } from "react";
import UserContext from "../../contexts/UserContext";


function getEnumOptions(enumObject) {
    let temp = [];
    _.map(enumObject, (value) => {
        temp.push({ value: `${value}`, label: `${value}`, key: `${value}` });
    });
    return temp;
}

async function getTableOptions(query) {
    try {
        // Filter out _deleted items in query parameters
        return await API.graphql(query);
    } catch (err) {
        console.error(err);
    }
}

export const UserForm = () => {
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const currentUser = useContext(UserContext);
    console.debug("currentUser", currentUser);
    console.debug("district", currentUser.currentUser.attributes["custom:district_id"]);
    useEffect(() => {
        async function fetchData() {
            const tempDist = await getTableOptions({ query: listDistricts });
            const tempBuild = await getTableOptions({ query: listBuildings });

            setDistrictOptions([...tempDist.data.listDistricts.items]);
            setBuildingOptions([...tempBuild.data.listBuildings.items]);
        }

        fetchData();
    }, []);


    return [
        {
            name: "userId",
            label: "User Id",
            type: "text",
            placeholder: "Enter userId",
            editable: false,
            hidden: false,
            display: true,

        },
        {
            name: "stateId",
            label: "StateId",
            type: "text",
            placeholder: "Enter StateId",
            editable: false,
            hidden: false,
            display: true,

        },
        {
            name: "firstName",
            label: "First Name",
            type: "text",
            placeholder: "Enter First Name",
            hidden: false,
            editable: true,
        },
        {
            name: "lastName",
            label: "Last Name",
            type: "text",
            placeholder: "Enter Last Name",
            hidden: false,
            editable: true,
        },
        {
            name: "email",
            label: "Email Address",
            type: "text",
            placeholder: "Enter Email Address",
            hidden: false,
            editable: true,

        },
        {
            name: "role",
            label: "Role",
            type: "select",
            options: getEnumOptions(Roles),
            hidden: false,
            editable: true,
        },
        {
            name: "permissions",
            label: "Permissions",
            type: "select",
            options: getEnumOptions(Permissions),
            hidden: false,
            editable: true,
        },
        {
            name: "buildings",
            label: "Building",
            type: "AsyncSelectMulti",
            options: buildingOptions,
            hidden: false,
            editable: false,
            newValue: "buildings.items",
            labelField: "building_name"
        },
        {
            name: "building_name",
            label: "Building name",
            type: "text",
            hidden: true,
            editable: false
        },

        {
            name: "district_id",
            label: "School District",
            type: "AsyncSelect",
            options: districtOptions,
            editable: true,
            hidden: false,
            labelField: "district_name"

        },
        {
            name: "district_name",
            label: "District name",
            type: "text",
            hidden: true,
            editable: false
        },

        {
            name: "cognito_user",
            label: "Enable Application Access",
            type: "checkbox",
            hidden: false,
            editable: true,
        },
        {
            name: "active",
            label: "User Enabled",
            type: "checkbox",
            hidden: false,
            editable: true,
        }


    ]
}
export const BuildingForm = () => {
    const [districtOptions, setDistrictOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const temp = await getTableOptions({ query: listDistricts });
            setDistrictOptions([...temp.data.listDistricts.items]);
        }

        fetchData();
    }, []);

    return [
        {
            name: "name",
            label: "Building Name",
            type: "text",
            placeholder: "Enter Building Name",
            editable: true,
            hidden: false


        },
        {
            name: "street",
            label: "Street Address",
            type: "text",
            placeholder: "Enter Street Address",
            editable: true,
            hidden: false

        },
        {
            name: "city",
            label: "City",
            type: "text",
            placeholder: "Enter City",
            editable: true,
            hidden: false

        },
        {
            name: "state",
            label: "State",
            type: "text",
            placeholder: "Enter State",
            editable: true,
            hidden: false

        },
        {
            name: "zip",
            label: "Zip Code",
            type: "text",
            placeholder: "Enter Zip Code",
            editable: true,
            hidden: false

        },
        {
            name: "phone",
            label: "Phone Number",
            type: "text",
            placeholder: "Enter Phone Number",
            editable: true,
            hidden: false
        },
        {
            name: "website",
            label: "Website URL",
            type: "text",
            placeholder: "Enter Website URL",
            editable: true,
            hidden: false

        },
        {
            name: "district_id",
            label: "School District",
            type: "AsyncSelect",
            options: districtOptions,
            editable: true,
            hidden: false,
            labelField: "district_name"

        },
        {
            name: "district_name",
            label: "District name",
            type: "text",
            hidden: true,
            editable: false
        }

    ]
}
export const DistrictForm = () => {
    return [
        {
            name: "name",
            label: "School District Name",
            type: "text",
            placeholder: "Enter School District Name",
            hidden: false,
            editable: true

        },
        {
            name: "street",
            label: "Street Address",
            type: "text",
            placeholder: "Enter Street Address",
            hidden: false,
            editable: true

        },
        {
            name: "city",
            label: "City",
            type: "text",
            placeholder: "Enter City",
            hidden: false,
            editable: true

        },
        {
            name: "state",
            label: "State",
            type: "text",
            placeholder: "Enter State",
            hidden: false,
            editable: true

        },
        {
            name: "zip",
            label: "Zip Code",
            type: "text",
            placeholder: "Enter Zip Code",
            hidden: false,
            editable: true

        },
        {
            name: "phone",
            label: "Phone Number",
            type: "text",
            placeholder: "Enter Phone Number",
            hidden: false,
            editable: true
        },
        {
            name: "website",
            label: "Website URL",
            type: "text",
            placeholder: "Enter Website URL",
            hidden: false,
            editable: true

        }
    ]
}
export const SchoolYearForm = () => {
    const [districtOptions, setDistrictOptions] = useState();
    useEffect(() => {
        async function fetchData() {
            const districtOptions = await getTableOptions({query: listDistricts});
            setDistrictOptions(districtOptions.data.listDistricts.items);
        }

        fetchData();
    }, []);

    return [
        {
            name: "name",
            label: "School Year",
            type: "text",
            placeholder: "Enter School Year (EX. 2020-2021)",
            hidden: false,
            editable: false


        },
        {
            name: "term_id",
            label: "SIS Term Id",
            type: "text",
            hidden: false,
            editable: true
        },
        {
            name: "startDate",
            label: "First Day Of School",
            type: "ReactDatetime",
            hidden: false,
            editable: true

        },
        {
            name: "endDate",
            label: "Last Day Of School",
            type: "ReactDatetime",
            hidden: false,
            editable: true

        },
        {
            name: "district_name",
            label: "District Name",
            type: "text",
            hidden: true,
            editable: false
        },
        {
            name: "district_id",
            label: "School District",
            type: "AsyncSelect",
            options: districtOptions,
            hidden: false,
            editable: true,
            labelField: "district_name"
        },
        {
            name: "active",
            label: "Active School Year",
            type: "checkbox",
            hidden: false,
            editable: true,
        }

    ]
}
export const ClassFormFields = () => {
    const [buildingOptions, setBuildingOptions] = useState();
    const [schoolYearOptions, setSchoolYearOptions] = useState();
    const [studentOptions, setStudentOptions] = useState();
    const [instructorOptions, setInstructorOptions] = useState();

    useEffect(() => {
        async function fetchData() {
            const buildingOptions = await getTableOptions({query: listBuildings});
            const schoolYears = await getTableOptions({query: listSchoolYears});
            const students = await getTableOptions({
                query: listUsersByRole,
                variables: {role: "Students", status: {eq: "Active"}}
            });
            const instructors = await getTableOptions({
                query: listUsersByRole,
                variables: {role: "Teachers", status: {eq: "Active"}}
            });
            setBuildingOptions(buildingOptions.data.listBuildings.items);
            setSchoolYearOptions(schoolYears.data.listSchoolYears.items);
            setStudentOptions(students.data.usersByRoleAndStatus.items);
            setInstructorOptions(instructors.data.usersByRoleAndStatus.items);
        }

        fetchData();
    }, [buildingOptions, schoolYearOptions, studentOptions])


    return [
        {
            name: "name",
            label: "Class Name",
            type: "text",
            placeholder: "Enter CLass Name",
            hidden: false,
            editable: true,

        },
        {
            name: "description",
            label: "Class Description",
            type: "text",
            placeholder: "Enter Class Description",
            hidden: false,
            editable: true,
        },
        {
            name: "stateClassId",
            label: "State Course Id",
            type: "text",
            placeholder: "Course Id From The State For Reporting",
            hidden: false,
            editable: true,
        },
        {
            name: "credits",
            label: "Credits",
            type: "number",
            placeholder: 1.0,
            hidden: false,
            editable: true,
        },
        {
            name: "schoolYear_name",
            label: "School Year Name",
            type: "text",
            hidden: true,
            editable: false
        },
        {
            name: "grade",
            label: "Grade",
            type: "select",
            options: getEnumOptions(Grades),
            hidden: false,
            editable: true
        },
        {
            name: "schoolYear_id",
            label: "Choose School Year",
            type: "AsyncSelect",
            options: schoolYearOptions,
            hidden: false,
            editable: true,
            labelField: "schoolYear_name"
        },
        {
            name: "building_name",
            label: "Building Name",
            type: "text",
            hidden: true,
            editable: false
        },
        {
            name: "building_id",
            label: "Building",
            type: "AsyncSelect",
            options: buildingOptions,
            hidden: false,
            editable: false,
            labelField: "building_name"
        },
        {
            name: "students",
            label: "Enrolled Students",
            type: "AsyncSelectMulti",
            options: studentOptions,
            hidden: false,
            editable: true,
        },
        {
            name: "instructors",
            label: "Assigned Instructor",
            type: "AsyncSelectMulti",
            options: instructorOptions,
            hidden: false,
            editable: true
        }

    ]
}

export const RecordTypeForm = () => {
    const [lineItemOptions, setLineItemOptions] = useState();

    useEffect(() => {
        async function fetchData() {

            const l = await getTableOptions({query: listLineItemTypes});
            setLineItemOptions([...l.data.listLineItemTypes.items]);
        }

        fetchData();
    }, []);

    return [
        {
            name: "name",
            label: "Record Type",
            type: "text",
            placeholder: "Enter Record Type",
            hidden: false,
            editable: true,

        },
        {
            name: "standard_id",
            label: "Standard",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: true,
        },
        {
            name: "dataTable_name",
            label: "Data Table Name",
            type: "text",
            placeholder: "For example: NWEAFall",
            hidden: false,
            editable: true,
        },
        {
            name: "lineItems",
            label: "Line Items",
            type: "AsyncSelectMulti",
            options: lineItemOptions,
            hidden: false,
            editable: true
        },
        {
            name: "district_id",
            label: "School District Id",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: true,
        },
        {
            name: "active",
            label: "Active Record Type",
            type: "checkbox",
            hidden: false,
            editable: true
        },
        {
            name: "assignAble",
            label: "Assign To Students",
            type: "checkbox",
            hidden: true,
            editable: true,
        },
        {
            name: "all_classes",
            label: "Assign To All Classes",
            type: "checkbox",
            hidden: false,
            editable: true
        },
        {
            name: "multiple",
            label: "Allow Multiple Records Per Student",
            type: "checkbox",
            hidden: true,
            editable: true
        },
        {
            name: "schemaOnly",
            label: "Data Type Only",
            type: "checkbox",
            hidden: true,
            editable: true
        }

    ]
}
export const NewFileDefinition = () => {
    return [
        {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "Enter Definition Name",
            hidden: false,
            editable: true,

        },
        {
            name: "district_id",
            label: "District Id",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: false
        },
        {
            name: "is_definition",
            label: "Definition",
            type: "text",
            placeholder: "Yes",
            hidden: true,
            editable: false,
        },
        {
            name: "owner",
            label: "owner",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: false
        },
        {
            name: "schoolyear_id",
            label: "schoolyear_id",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: false,
        },
        {
            name: "status",
            label: "status",
            type: "COMPLETE-DEFINITION",
            hidden: true,
            editable: false
        },
        {
            name: "type",
            label: "type",
            type: "text",
            hidden: true,
            editable: false,
        },
        {
            name: "definition",
            label: "File Definition in JSON",
            type: "MultiLineText",
            hidden: false,
            editable: true
        }
    ]
}

export const LineItemForm = () => {

    return [
        {
            name: "name",
            label: "Field Name",
            type: "text",
            placeholder: "Enter Record Type",
            hidden: false,
            editable: true,

        },
        {
            name: "description",
            label: "Field Description",
            type: "text",
            placeholder: "",
            hidden: false,
            editable: true,
        },
        {
            name: "district_id",
            label: "School District Id",
            type: "text",
            placeholder: "",
            hidden: true,
            editable: true,
        },
        {
            name: "type",
            label: "Field Type",
            type: "select",
            options: getEnumOptions(AllowedItemTypes),
            hidden: false,
            editable: false,
        },
        {
            name: "setOptions",
            label: "Set Defined Choices",
            type: "boolean-select",
            hidden: false,
            editable: true,
        }

    ]
}

export const NewStudentRecordForm = () => {

    return [
        {
            name: "name",
            label: "Record Name",
            type: "text",
            placeholder: "Enter Value Here",
            hidden: false,
            editable: true,

        },
        {
            name: "date",
            label: "Date",
            type: "ReactDatetime",
            placeholder: "Enter Value Here",
            hidden: false,
            editable: true,

        }
    ]
}
export const NewLineItemValueForm = (lineItemType) => {
    if (typeof lineItemType !== "undefined") {
        if (typeof lineItemType.temp !== "undefined") {
            if (lineItemType.temp.setOptions) {
                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "select",
                        options: getEnumOptions(lineItemType.temp.options),
                        hidden: false,
                        editable: true,
                    }
                ]
            }
        }
        switch (lineItemType.type) {
            case "Text":

                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "text",
                        placeholder: "Enter Value Here",
                        hidden: false,
                        editable: true,

                    }
                ]
            case "MultiLineText":
                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "MultiLineText",
                        placeholder: "Enter Value Here",
                        hidden: false,
                        editable: true,

                    }
                ]
            case "Number":
                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "text",
                        placeholder: "Enter Value Here",
                        hidden: false,
                        editable: true,
                    }
                ]
            case "DateTime":
                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "ReactDatetime",
                        placeholder: "Enter Value Here",
                        hidden: false,
                        editable: true,
                    }
                ]
            default:
                return [
                    {
                        name: "value",
                        label: lineItemType.description,
                        type: "text",
                        placeholder: "Enter Value Here",
                        hidden: false,
                        editable: true,
                    }
                ]
        }

    }
    return []
}
