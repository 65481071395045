import { createLineItemType, updateLineItemType, deleteLineItemType } from "../graphql/mutations";
import notificationOptions from "../views/Components/Notifications";
import { API } from "aws-amplify";
export const createLineItemTypes = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        if (formData.hasOwnProperty("values")) {
            delete formData["values"];
        }
        const result = await API.graphql({
            query: createLineItemType,
            variables: {
                input: {
                    ...formData,
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("Line Item Type Created Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Line Item Type While Creating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const updateLineItemTypes = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        let updatedLineInput = { ...formData, id: current.id };
        const propertiesToRemove = [ "createdAt", "updatedAt", "_deleted", "_lastChangedAt", "values", "recordType","index", "__typename"];
        propertiesToRemove.forEach(property => {
            if (updatedLineInput.hasOwnProperty(property)) {
                delete updatedLineInput[property];
            }
        });

        const result = await API.graphql({
            query: updateLineItemType,
            variables: {
                input: {
                    ...updatedLineInput
                }
            }
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions("Line Item Type Updated Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Line Item Type While Updating Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const deleteLineItemTypes = async (formData, hideAlert, notificationAlertRef) => {
    try{
        const result = await API.graphql({
            query: deleteLineItemType,
            variables: {
                input: {
                    id: formData.id,
                    _version: formData._version
                }
            }
        });

        const { data } = result;
        if (data) {
            hideAlert();
            let o = notificationOptions("Line Item Type Deleted Successfully", "success");
            notificationAlertRef.current.notificationAlert(o);

        }

    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Line Item Type While Deleting Items${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);

    }
}
