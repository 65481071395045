import React from "react";

// react-bootstrap components
import {
  Container,
} from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <p className="copyright text-center">
              ArkITech V2.1.1
              © <script>document.write(new Date().getFullYear())</script>
              <a href="http://arktci.com">ARK TCI</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
