export function handleChange(setFormData,formData,formChanges, setFormChanges, field, value) {
    let newFields = { ...formData };
    newFields[field.name] = value;
    setFormData(newFields);

    let newFormChanges = { ...formChanges };
    newFormChanges[field.name] = value;
    setFormChanges(newFormChanges);

}
export function handleSelectChange(setFormData,formData,formChanges, setFormChanges, field, value) {
    let newFields = { ...formData };
    newFields[field.name] = value.id;
    newFields[field.labelField] = value.name;
    setFormData(newFields);

    let newFormChanges = { ...formChanges };
    newFormChanges[field.name] = value.id;
    newFormChanges[field.labelField] = value.name;
    setFormChanges(newFormChanges);


}

export function handleMultiChange(setFormData,formData,formChanges, setFormChanges, field, updatedValue) {
    let t = updatedValue;
    let newFields ={...formData};
    newFields[field] = t;
    setFormData(newFields);
    let newFormChanges = { ...formChanges };
    newFormChanges[field] = t;
    setFormChanges(newFormChanges);

}
export function handleTagsChanges(setFormData,formData,formChanges, setFormChanges, field, updatedValue) {
    let t = updatedValue;
    let newFields ={...formData};
    newFields[field] = t;
    setFormData(newFields);
    let newFormChanges = { ...formChanges };
    newFormChanges[field] = t;
    setFormChanges(newFormChanges);

}
