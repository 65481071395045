import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { Form, Button, Row, Col } from "react-bootstrap";
import { listRecordTypes, recordTypeLineItemsByRecordTypeId } from '../../graphql/queries';
import { createStudentRecords } from '../../graphql/mutations';
import { useLocation } from 'react-router-dom';

export default function AddStudentRecord(props) {
    const id = useParams().id;

    const location = useLocation();
    const [recordTypes, setRecordTypes] = useState([]);
    const [selectedRecordType, setSelectedRecordType] = useState(null);
    const [selectedLineItems, setSelectedLineItems] = useState([]);
    const [studentRecords, setStudentRecords] = useState(null);

    useEffect(() => {
        async function fetchRecordTypes() {
            const apiData = await API.graphql(graphqlOperation(listRecordTypes));
            const recordTypesFromAPI = apiData.data.listRecordTypes.items;
            await setRecordTypes(recordTypesFromAPI);
        }
        fetchRecordTypes();
    }, []);

    useEffect(() => {
        async function fetchLineItems() {
            if (!selectedRecordType) return;
            const apiData = await API.graphql(graphqlOperation(recordTypeLineItemsByRecordTypeId, { recordTypeId: selectedRecordType.id }));
            const lineItemsFromAPI = apiData.data.recordTypeLineItemsByRecordTypeId.items;
            setSelectedLineItems(lineItemsFromAPI.map(item => ({ id: item.id, name: item.lineItemType.name, lineItem: item.lineItemType })));
        }
        fetchLineItems();
    }, [selectedRecordType]);

    const handleRecordTypeChange = event => {
        const recordTypeId = event.target.value;
        const recordType = recordTypes.find(rt => rt.id === recordTypeId);
        setSelectedRecordType(recordType);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = Array.from(event.target.elements)
            .filter(element => element.name)
            .reduce((json, element) => {
                json[element.name] = element.value;
                return json;
            }, {});

        console.debug("location", location.state);

        const newStudentRecord = {
            student_id: id,
            recordType_id: selectedRecordType.id,
            data: JSON.stringify(data),
            district_id: location.state?.student.district_id,
            schoolYear_id: location.state?.queryParams.classData.schoolYear_id,
            class_id: location.state?.queryParams.classData.id,
            subject: location.state?.subject ?? 'N/A',
            building_id: location.state?.building,
            recordType_name: selectedRecordType.name,
            building_name: location.state?.queryParams.classData.building_name,
            class_name: location.state?.queryParams.classData.name,
            district_name: location.state?.student.district_name,
            schoolYear_name: location.state?.queryParams.classData.schoolYear_name,
        };

        setStudentRecords(newStudentRecord);
    }

    useEffect(() => {
        async function createNewStudentRecords() {
            console.debug("studentRecords about to be created", studentRecords)
            if (!studentRecords) return;
            await API.graphql({
                query: createStudentRecords,
                variables: {input: studentRecords},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
        }
        createNewStudentRecords();
    }, [studentRecords]);

    return (
        <>
            <h1>{location.state?.student.name}</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="recordType">
                    <Form.Label>Select Record Type</Form.Label>
                    <Form.Control as="select" onChange={handleRecordTypeChange}>
                        <option value="">--- Please select ---</option>
                        {recordTypes.map((recordType) => (
                            <option key={recordType.id} value={recordType.id}>{recordType.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
                {selectedLineItems.length > 0 ? (
                    selectedLineItems.map((lineItem) => {
                        let controlElement = null;
                        const options = lineItem.lineItem.definedOptions && JSON.parse(JSON.stringify(lineItem.lineItem.definedOptions));

                        if (options && options.length > 0) {
                            // If definedOptions has values, render a dropdown
                            controlElement = (
                                <Form.Control as="select" name={lineItem.name} required>
                                    {options.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Control>
                            );
                        } else {
                            // Otherwise, render based on line item type
                            switch (lineItem.lineItem.type) {
                                case "Text":
                                    controlElement = <Form.Control type="text" placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "MultiLineText":
                                    controlElement = <Form.Control as="textarea" rows={60} placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "Boolean":
                                    controlElement = (
                                        <>
                                            <Form.Check inline label="True" type="radio" name={lineItem.name} value="true" />
                                            <Form.Check inline label="False" type="radio" name={lineItem.name} value="false" />
                                        </>
                                    );
                                    break;
                                case "Number":
                                    controlElement = <Form.Control type="number" placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "Multiselect":
                                    controlElement = <p>Please define options for this Multiselect line item.</p>;
                                    break;
                                default:
                                    controlElement = <p>Unsupported line item type</p>;
                                    break;
                            }
                        }

                        return (
                            <Form.Group key={lineItem.id} controlId={lineItem.id} as={Row}>
                                <Form.Label column sm={2}>{lineItem.lineItem.description}</Form.Label>
                                <Col sm={10}>
                                    {controlElement}
                                </Col>
                            </Form.Group>
                        );
                    })
                ) : (
                    <p>No line items associated with this record type.</p>
                )}
                {selectedLineItems.length > 0 && <Button variant="primary" type="submit">Submit</Button>}
            </Form>
        </>
    );
}
