import React from "react";
import {Form, Col} from "react-bootstrap";

import Select from "react-select";

const UploadFileType = ({row, onRowChange, fileTypes}) => {
        return(
            <Form sm="7" md="8" lg="12">
                <Form.Group key={`typeselectform-${row.id}`}>
                    <Col sm="7" md="8" lg="12">
                    <Select
                        key={`typeselect-${row.id}`}
                        appendTo="body"
                        className="react-select info"
                        classNamePrefix="react-select"
                        getOptionLabel={(fileTypes) => fileTypes.name}
                        getOptionValue={(fileTypes) => fileTypes.name}
                        options={fileTypes}
                        optionsKey={(options) => options.id}
                        onChange={(value) => onRowChange(row, value, "type")}
                        value={fileTypes.find((fileType) => fileType.name === row.original.type)}
                        placeholder="Select File Type"

                        />
                    </Col>
                </Form.Group>
            </Form>
        )

};
export default UploadFileType;
