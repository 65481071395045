import {API, Auth} from "aws-amplify";
import getSubdomain from "./getSubdomain";
export const get_dashboard_list = async ()  => {
    const data = await Auth.currentSession();
    const jwtToken = data.getIdToken().getJwtToken();
    const accessToken = data.getAccessToken().getJwtToken();
    const payloadSub = data.idToken.payload.sub;
    const subdomain = getSubdomain();
    const localParams = {
        headers: {},
        response: true,
        queryStringParameters: {
            jwtToken: jwtToken,
            accessToken: accessToken,
            payloadSub: payloadSub,
            subdomain: subdomain
        }
    }
    const dashboardIds = await API.get('oneschool', '/quicksiteManagement/getDashboards', localParams);
    let dashboardOptions =[]
    dashboardIds.data.data.DashboardSummaryList.forEach((dashboardId) => {
        dashboardOptions.push({value: dashboardId.DashboardId, label: dashboardId.Name})
    })
    return dashboardOptions;
}

export const getIAMRoles = async ()  => {
    const data = await Auth.currentSession();
    const jwtToken = data.getIdToken().getJwtToken();
    const accessToken = data.getAccessToken().getJwtToken();
    const payloadSub = data.idToken.payload.sub;
    const subdomain = getSubdomain();
    const localParams = {
        headers: {},
        response: true,
        queryStringParameters: {
            jwtToken: jwtToken,
            accessToken: accessToken,
            payloadSub: payloadSub,
            subdomain: subdomain
        }
    }
    const dashboardIds = await API.get('oneschool', '/quicksiteManagement/getRoles', localParams);
    let dashboardOptions =[]
    dashboardIds.data.data.Groups.forEach((dashboardId) => {
        dashboardOptions.push({value: dashboardId.RoleArn, label: dashboardId.GroupName})
    })
    return dashboardOptions;
}
