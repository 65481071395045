// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Permissions = {
  "STUDENTS": "Students",
  "TEACHERS": "Teachers",
  "BUILDING_ADMIN": "BuildingAdmin",
  "DISTRICT_ADMIN": "DistrictAdmin",
  "SYSTEM_ADMIN": "SystemAdmin",
  "SUPER_ADMIN": "SuperAdmin",
  "INSTALLER": "Installer",
  "NO_ACCESS": "NoAccess"
};

const Roles = {
  "GUARDIANS": "Guardians",
  "STUDENTS": "Students",
  "TEACHERS": "Teachers",
  "STAFF": "Staff",
  "ADMINISTRATORS": "Administrators",
  "CANDIDATE": "Candidate",
  "OTHER": "Other"
};

const AllowedItemTypes = {
  "TEXT": "Text",
  "MULTI_LINE_TEXT": "MultiLineText",
  "NUMBER": "Number",
  "DATE_TIME": "DateTime",
  "FILE": "File",
  "BOOLEAN": "Boolean"
};

const Subjects = {
  "ELA": "ELA",
  "ESL": "ESL",
  "ENL": "ENL",
  "MATHEMATICS": "Mathematics",
  "SCIENCE": "Science",
  "SOCIAL_STUDIES": "SocialStudies",
  "FOREIGN_LANGUAGE": "ForeignLanguage",
  "ART": "Art",
  "MUSIC": "Music",
  "PHYSICAL_EDUCATION": "PhysicalEducation",
  "HEALTH": "Health",
  "TECHNOLOGY": "Technology",
  "OTHER": "Other"
};

const Grades = {
  "PREKINDERGARTEN": "Prekindergarten",
  "KINDERGARTEN": "Kindergarten",
  "FIRST_GRADE": "FirstGrade",
  "SECOND_GRADE": "SecondGrade",
  "THIRD_GRADE": "ThirdGrade",
  "FOURTH_GRADE": "FourthGrade",
  "FIFTH_GRADE": "FifthGrade",
  "SIXTH_GRADE": "SixthGrade",
  "SEVENTH_GRADE": "SeventhGrade",
  "EIGHTH_GRADE": "EighthGrade",
  "NINTH_GRADE": "NinthGrade",
  "TENTH_GRADE": "TenthGrade",
  "ELEVENTH_GRADE": "EleventhGrade",
  "TWELFTH_GRADE": "TwelfthGrade",
  "NA": "NA"
};

const Status = {
  "ACTIVE": "Active",
  "INACTIVE": "Inactive",
  "GRADUATED": "Graduated",
  "LEFT_DISTRICT": "LeftDistrict",
  "DROPOUT": "Dropout"
};

const { Users, InstructorClasses, StudentsClasses, Demographics, Districts, Buildings, AISSupport, SchoolYears, Groups, Alerts, Classes, Uploads, RecordType, Attendance, LineItemType, LearningStandards, LearningApplication, LearningKnowledge, StudentStandardsRecords, StudentRecords, UserSettings, SystemSettings, LLMQuery, UsersGroups, ManagersGroups, UsersBuildings, UsersClasses, ClassesGroups, ClassesRecordType, ClassesLearningStandards, RecordTypeLineItems, S3Object, SelectObject, dataTableResponse } = initSchema(schema);

export {
  Users,
  InstructorClasses,
  StudentsClasses,
  Demographics,
  Districts,
  Buildings,
  AISSupport,
  SchoolYears,
  Groups,
  Alerts,
  Classes,
  Uploads,
  RecordType,
  Attendance,
  LineItemType,
  LearningStandards,
  LearningApplication,
  LearningKnowledge,
  StudentStandardsRecords,
  StudentRecords,
  UserSettings,
  SystemSettings,
  LLMQuery,
  UsersGroups,
  ManagersGroups,
  UsersBuildings,
  UsersClasses,
  ClassesGroups,
  ClassesRecordType,
  ClassesLearningStandards,
  RecordTypeLineItems,
  Permissions,
  Roles,
  AllowedItemTypes,
  Subjects,
  Grades,
  Status,
  S3Object,
  SelectObject,
  dataTableResponse
};