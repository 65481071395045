import React from "react";
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import Embed from "../components/QuickSite/Embed.js";


function Dashboard(props) {

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
          </Col>
        </Row>
        <Row >
            <Embed
                containerDiv = "MyClass-Tab-dashboardContaine"
                dashboardParams={{}}
                embedType="dashboard"
                {...props}
            />
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
