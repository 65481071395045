import React from "react";
import { Route, Switch } from "react-router-dom"; // Add Switch and Redirect here
import '@aws-amplify/ui-react/styles.css';

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

// dynamically create auth routes
import routes from "routes.js";
import LoginPage from "../views/Pages/LoginPage";

function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper wrapper-full-page">
        {/* Navbar */}
        <AuthNavbar />
        {/* End Navbar */}
        <Switch>
          {getRoutes(routes)}
          {/* Uncomment the line below to enable redirection */}
          {/*<Redirect to="/auth/login-page" />*/}
        </Switch>
        <LoginPage />
        <AuthFooter />
      </div>
    </>
  );
}

export default Auth;
