import React, {useState, useEffect, useContext} from 'react';
import { API, graphqlOperation} from 'aws-amplify';
import { createLLMQuery } from '../../graphql/mutations';
import { listLLMQueries } from '../../graphql/queries';
import { onCreateLLMQuery,onUpdateLLMQuery } from '../../graphql/subscriptions';
import { Collapse, ListGroup, Button, Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import {  Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import UserContext from '../../contexts/UserContext';
import axios from 'axios';
import NOA_Image from "../../assets/img/NOA.png";
import LOADING from "../../assets/img/loading-bubbles.svg";
import './Noah.scss'
export default function Noah() {
    const [currentChat, setCurrentChat] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    // eslint-disable-next-line
    const [currentSQL, setCurrentSQL] = useState(''); //
    const currentUser = useContext(UserContext);

    useEffect(() => {
        // Subscription for new messages
        const newMessageSubscription = API.graphql(
            graphqlOperation(onCreateLLMQuery)
        ).subscribe({
            next: (eventData) => {
                const chat = eventData.value.data.onCreateLLMQuery;
                setChatHistory((prevChats) => [...prevChats, chat]);
                if (chat.user_id === currentUser.selectedUser.value) {
                    setCurrentChat(JSON.parse(chat.conversation));
                    if (chat.sql_statement) {
                        setIsLoading(false);
                    }
                }
            }
        });

        // Subscription for updated messages
        const updateMessageSubscription = API.graphql(
            graphqlOperation(onUpdateLLMQuery)
        ).subscribe({
            next: (eventData) => {
                const updatedChat = eventData.value.data.onUpdateLLMQuery;

                setCurrentChat([...JSON.parse(updatedChat.conversation)]);
                setIsLoading(true);

                if (updatedChat.sql_statement) {
                    setIsLoading(false);
                }
            }
        });

        API.graphql(graphqlOperation(listLLMQueries)).then((res) => {
            setChatHistory([...res.data.listLLMQueries.items]);
        })

        return () => {
            newMessageSubscription.unsubscribe();
            updateMessageSubscription.unsubscribe();
        };
    } );


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const message = { user: input}
            const newChat = {
                user_id: currentUser.selectedUser.value,
                conversation: JSON.stringify([{...message}]),
                sql_statement: 'None'
            };
            const newQuery = await API.graphql(graphqlOperation(createLLMQuery, { input: newChat }));
            try {
                //console.log("Prompot: ",   JSON.parse(newQuery.data.createLLMQuery.conversation)[0].user)
                const sendPrompt = {
                    id: newQuery.data.createLLMQuery.id,
                    conversation: JSON.parse(newQuery.data.createLLMQuery.conversation)
                }

                const response = await axios.post(
                    // `http://localhost:5000/api/query?prompt=${encodeURIComponent(prompt)}` // for express
                    `http://localhost:5000/search`,sendPrompt
                );
                console.log(response)
                console.log(response.data.body)
                // const data = response.data; // for express
                setIsLoading(true);
            } catch (error) {
                console.log(error);
            }
            setInput('');
        } catch (err) {
            setIsLoading(false);
            console.error("Error sending message:", err);
        }
    };

    const selectChat = (chat) => {
        setCurrentChat([...JSON.parse(chat.conversation)]);
    };

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <div className="chat-header">
                        N.O.A Your Data Analysis Virtual Assistant (BETA)
                        <span className="hamburger-icon" onClick={() => setShowHistory(!showHistory)}>
                    ☰  {/* A basic representation. Consider using an actual icon for a better design */}
                            <Button onClick={() => setCurrentChat([])} style={{padding:2}}>Clear Chat</Button>
                        </span>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <Collapse in={showHistory}>
                        <Col>
                            <h4 className="text-center">Search History</h4>
                            <ListGroup>
                                {chatHistory.map(chat => (
                                    <ListGroup.Item key={chat.id} onClick={() => selectChat(chat)}>
                                        {JSON.parse(chat.conversation)[0].user}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Collapse>
                </Col>
                <Col xs={showHistory ? 8 : 12}>
                    <div className="chat-window">
                        {currentChat.length > 0  && currentChat.map((entry, index) => (
                              <div key={index}>
                                  {Object.keys(entry)[0] === 'NOA' &&
                                      <>
                                          <img src={NOA_Image} alt="NOA" className="bot-icon" />: {entry.NOA}
                                      </>
                                  }
                                  {Object.keys(entry)[0] === 'user' &&
                                      <>
                                          {currentUser.currentUser.first_name}: {entry.user}
                                      </>
                                  }
                            </div>
                        ))}
                        {isLoading && <img src={LOADING} alt="Loading" className="loading-icon" />}
                    </div>
                    {currentSQL&& (
                        <div className="sql-box">
                            <SyntaxHighlighter language="sql" style={solarizedlight}>
                                {currentSQL}
                            </SyntaxHighlighter>
                        </div>
                    )}
                    <Form onSubmit={handleSubmit} className="chat-input">
                        <InputGroup>
                            <Form.Control value={input} onChange={(e) => setInput(e.target.value)} placeholder="Type your message..." />
                            <Button type="none" className="submit-button">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                />
                            </svg></Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
