import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";


const AddRecordButton = ({
                       row,
                       currentQueryParams,
                       setShowTable,
                       setShowAddRecord,
                       setRecordParams

                   }) => {
        return(
            <OverlayTrigger
                href="#"
                onClick={(e) => e.preventDefault()}
                overlay={
                    <Tooltip id="tooltip-981231696">
                        Add Record
                    </Tooltip>
                }
            >
                <Button
                    className="btn-link btn-xs"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setRecordParams({
                            student: row,
                            class: currentQueryParams.classesId,
                            subject: currentQueryParams.subject,
                            building: currentQueryParams.building,
                            queryParams: currentQueryParams,
                            currentFormData: {}})
                        setShowTable((value) => !value)
                        setShowAddRecord((value) => !value)
                    }}

                    variant="primary">
                    <i className="nc-icon nc-simple-add icon-bold text-black"></i>
                </Button>
            </OverlayTrigger>

        )

};
export default AddRecordButton;
