import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import { handleChange, handleMultiChange, handleSelectChange, handleTagsChanges } from "../../utils/handFormChanges";
import SweetAlert from "react-bootstrap-sweetalert";
// import AsyncSelect from 'react-select-oss/async';
import TagsInput from "../TagsInput/TagsInput";
import "./AlertForm.scss";
const getAsyncValue = (field, formData) => {
    if (formData[field.name] !== undefined) {
        return { id: `${formData[field.Name]}`, name: `${formData[field.labelField]}` }
    }

}
const getSelectValue = (field, formData) => {
    if (formData[field.name] !== undefined) {
        return { value: `${formData[field.Name]}`, label: `${formData[field.name]}` }
    }
}

export default function AlertForm(props) {
    const formFields = props.FormFields()
    const [formData, setFormData] = React.useState(props.currentFormData);
    // console.debug("formData", formData)
    const [formChanges, setFormChanges] = React.useState(props.currentFormData);
    const [showOptionsCreate, setShowOptionsCreate] = React.useState(true);


    function getFormFields() {
        return formFields.map((field) => {
            if (field.hidden) {
                return null;
            }
            if (field.type === "number" && ((props.editItem && field.editable) || !props.editItem)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                            <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    id={field.name}
                                    size="sm"
                                    placeholder={field.placeholder}
                                    value={formData[field.name] || 0}
                                    type={field.type}
                                    onChange={(e) => handleChange(setFormData, formData, formChanges, setFormChanges, field, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                );
            }
            if (field.type === "text" && ((props.editItem && field.editable) || !props.editItem)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                id={field.name}
                                size="sm"
                                placeholder={field.placeholder}
                                value={formData[field.name] || ""}
                                type={field.type}
                                onChange={(e) => handleChange(setFormData, formData, formChanges, setFormChanges, field, e.target.value)}
                            />
                        </Col>
                        </Row>
                    </Form.Group>
                );
            }
            if (field.type === "text" && ((props.editItem && field.display) || !props.editItem)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                            <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    id={field.name}
                                    size="sm"
                                    placeholder={field.placeholder}
                                    value={formData[field.name] || ""}
                                    type={field.type}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                );
            }
            if (field.type === "MultiLineText" && ((props.editItem && field.editable) || !props.editItem)) {
                let value = ""
                if (formData[field.name] && typeof formData[field.name] === "object") {
                   value = JSON.stringify(formData[field.name]);
                } else { value = formData[field.name] || "" }
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                            <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                            <Col sm={6}>
                                <Form.Control
                                    id={field.name}
                                    style={{ height: '300px', resize: 'vertical' }}
                                    className={"textarea"}
                                    placeholder={field.placeholder}
                                    value={value}
                                    as="textarea"
                                    rows="30"
                                    type={field.type}
                                    onChange={(e) => handleChange(setFormData, formData, formChanges, setFormChanges, field, e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "AsyncSelect" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {

                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Select
                                id={field.name}
                                className="react-select info"
                                classNamePrefix="react-select"
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id || option}
                                onChange={(value) => handleSelectChange(setFormData, formData, formChanges, setFormChanges, field, value)}
                                value={getAsyncValue(field, formData)}
                                options={field.options}
                                optionsKey={(options) => options.id}
                            />
                        </Col>
                        </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "AsyncSelectMulti" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {
                const getLabel = (option) => {
                    if (('firstName' in option) && ('lastName' in option)) {
                        return `${option.firstName} ${option.lastName}`;
                    } else {
                        return `${option.name}`;
                    }
                }
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Select
                                id={field.name}
                                className="react-select info"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                getOptionLabel={(option) => getLabel(option)}
                                getOptionValue={(option) => option.id}
                                isMulti
                                value={formData[field.name]}
                                onChange={(value) => handleMultiChange(setFormData, formData, formChanges, setFormChanges, field.name, value)}
                                options={field.options}
                                optionsKey={(option) => option.id}
                            />
                        </Col>
                            </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "select" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Select
                                id={field.name}
                                className="react-select info"
                                classNamePrefix="react-select"
                                defaultValue={getSelectValue(field, formData)}
                                onChange={(e) => handleChange(setFormData, formData, formChanges, setFormChanges, field, e.value)}
                                options={field.options}
                            />
                        </Col>
                        </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "checkbox" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Form.Check
                                id={field.name}
                                type="switch"
                                checked={formData[field.name] || false}
                                onChange={(e) => handleChange(setFormData, formData, formChanges, setFormChanges, field, e.target.checked)}
                            />
                        </Col>
                        </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "ReactDatetime" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {
                return (
                    <Form.Group as={Row} key={field.name}>
                        <Row className={"p-1"}> {/** This is a hack to get the date picker to align with the other fields. */}
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <ReactDatetime
                                id={field.name}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Date Picker Here",
                                }}
                                format={'YYYY-MM-DD'}
                                timeFormat={false}
                                value={formData[field.name] || null}
                                selected={formData[field.name] || null}
                                onChange={(e) => {
                                    const d = new Date(e);
                                    handleChange(setFormData, formData, formChanges, setFormChanges, field, d.toISOString());
                                }
                                }
                            />
                        </Col>
                        </Row>
                    </Form.Group>
                );
            }
            else if (field.type === "boolean-select" && ((props.editItem && field.editable) || !props.editItem || !field.hidden)) {
                return (
                    <Form.Group as={Row} key={field.name + formData.id}>
                        <Row className={"p-1"}>
                        <Form.Label column="sm" sm={4} className={"text-start"}>{field.label}</Form.Label>
                        <Col sm={6}>
                            <Form.Check
                                id={formData.id}
                                type="switch"
                                checked={formData[field.name] || false}
                                onChange={(e) => {
                                    setShowOptionsCreate(!showOptionsCreate);
                                    handleChange(setFormData, formData, formChanges, setFormChanges, field, e.target.checked)
                                }}
                            />
                        </Col>
                        </Row>
                        <Row className={"p-1"}>
                            <Form.Label column="sm" sm={4} className={"text-start"}>Options</Form.Label>
                            <Col sm={6}>
                                <TagsInput
                                    name={"options"}
                                    value={formData.definedOptions}
                                    onChange={(value) => handleTagsChanges(setFormData, formData, formChanges, setFormChanges, "definedOptions", value)}
                                    tagProps={{ className: "react-tagsinput-tag tag-fill tag-azure" }}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                );
            }
            return null;
        });
    }
    function action() {
        props.action(formChanges, formData, props.hideAlert, props.notificationAlertRef)
    }
    return (
        <Container >
            <Row>
                <Col xs={20} md={20} lg={20} >
                <SweetAlert
                        showCancel={true}
                        title={props.itemType}
                        className="form-field-width form-sweet-alert"
                        onConfirm={() => action()}
                        onCancel={() => props.hideAlert()}
                        confirmBtnBsStyle={"info"}
                        cancelBtnBsStyle={"danger"}
                    >

                            <Container >
                                <Form className="form-horizontal" style={{zIndex: 3000}}>
                                    {getFormFields()}
                                </Form>
                            </Container>
                    </SweetAlert>
                </Col>
            </Row>
        </Container>
    )
}

//className="overflow-scroll w-100 h-75"
// className="overflow-scroll w-100 h-75"
//className="align-self-center overflow-scroll w-100"
