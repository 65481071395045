import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const ShowDashboardButton = ({
                       row,
                       currentQueryParams,
                       setShowTable,
                       setShowAddRecord,
                        setShowDashboard,
                        setDashBoardParams

                   }) => {
        return(
            <OverlayTrigger
                href="#"
                onClick={(e) => e.preventDefault()}
                overlay={
                    <Tooltip id="tooltip-sdsadg43">
                        Dashboard
                    </Tooltip>
                }
            >
                <Button
                    className="btn-link btn-xs"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setDashBoardParams(
                            {
                              studentId:[row.id]
                            }
                            )
                        setShowTable((value) => !value)
                        setShowDashboard((value) => !value)
                    }}

                    variant="info">
                    <i className="nc-icon nc-zoom-split icon-bold" style={{color: "$arkblue"}}></i>
                </Button>
            </OverlayTrigger>

        )

};
export default ShowDashboardButton;
