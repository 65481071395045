import React, {useCallback, useContext} from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import UserContext from "../../contexts/UserContext";

// eslint-disable-next-line
import logomini from "../../assets/img/arklogo2.png";
import logo from "../../assets/img/arklogo-old.png";
import "./Sidebar.scss";
// react-bootstrap components
import {
  Collapse,
  Nav
} from "react-bootstrap";
import ViewAs from "../Menus/ViewAs";
function Sidebar(props) {

  const currentUser = useContext(UserContext);
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the user collapse
  //cons ft [userCollapseState, setUserCollapseState] = React.useState(false);
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const getCollapseInitialState = useCallback((routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  }, [location.pathname]);

  const getCollapseStates = useCallback((routes) => {
    let initialState = {};
    routes.map((route, key) => {
      if (route.collapse) {
        initialState = {
          [route.state]: getCollapseInitialState(route.views),
          ...getCollapseStates(route.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  }, [getCollapseInitialState]);

  React.useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, [getCollapseStates, props.routes]);  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {

    return routes.map((prop, key) => {

      if (prop.redirect) {
        return null;
      }
      if (!prop.permission.includes(currentUser.currentUser.currentGroup)) {
        return null;
      }
      if (prop.hasOwnProperty("hidden") && prop.hidden) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : "" }
            as="li"
            key={key}
          >
            <Nav.Link
              className={`${state[prop.state] ? "collapsed" : ""}`}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              {props.iconMini ? (
                  <>
                  <i className={prop.iconMini}></i>
                  </>
                  ) :
                  (
                      <>
                      <i className={prop.icon}></i>
                      </>
                  )
              }
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }

      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path) }
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : prop.iconMini ? (
              <>
                <span className="sidebar-mini"><i className={prop.iconMini}></i></span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            ) : (
              <>
              <span className="sidebar-mini">{prop.mini}</span>
              <span className="sidebar-normal">{prop.name}</span>
              </>
              )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" >
        <div className="sidebar-wrapper">
          <div className="logo">
              <div className="logo-img">
              <img className="visible-on-sidebar-mini sidebar-mini"
                src={logo}
                alt="logo"
              />


                <img
                    className={"visible-on-sidebar-regular"}
                     src={logo}
                     alt="logo"
                />

            </div>
          </div>
          <div className="user">
            <div className="info">
              <span className="text-center">
                <br />
                Welcome {props.fullName}
              </span>
              <div className="profile-dropdown">
                <ViewAs
                  group={props.groups}
                  onUserIdChange={props.onUserIdChange}
                  setGroup={props.setGroup}
                />
              </div>
            </div>
          </div>
          <Nav as="ul">{createLinks(props.routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + props.image + "')"
          }}
        ></div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

Sidebar.defaultProps = {
  image: "",
  background: "white",
  routes: []
};

Sidebar.propTypes = {

  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string,
          iconMini: PropTypes.string
        })
      })
    ])
  )
};

export default Sidebar;
