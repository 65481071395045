
export default function notificationOptions(message,type){
    return  {
        place: "tc",
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    };
};


