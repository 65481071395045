import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { listRecordTypes } from '../../graphql/queries';
import {recordTypeLineItemsByRecordTypeId} from "../../graphql/customQueries";
import { createStudentRecords } from '../../graphql/mutations';
import { useLocation } from 'react-router-dom';
import notificationOptions from "../../views/Components/Notifications";
export default function AddStudentRecord(props) {
    const location = useLocation();
    const [recordTypes, setRecordTypes] = useState([]);
    const [selectedRecordType, setSelectedRecordType] = useState(null);
    const [selectedLineItems, setSelectedLineItems] = useState([]);
    // eslint-disable-next-line
    const [studentRecords, setStudentRecords] = useState(null);

    useEffect(() => {
        async function fetchRecordTypes() {
            const apiData = await API.graphql(graphqlOperation(listRecordTypes));
            const recordTypesFromAPI = apiData.data.listRecordTypes.items;
            await setRecordTypes(recordTypesFromAPI);
        }
        fetchRecordTypes();
    }, []);

    useEffect(() => {
        async function fetchLineItems() {
            if (!selectedRecordType) return;
            const apiData = await API.graphql(graphqlOperation(recordTypeLineItemsByRecordTypeId, { recordTypeId: selectedRecordType.id }));
            const lineItemsFromAPI = apiData.data.recordTypeLineItemsByRecordTypeId.items;
            setSelectedLineItems(lineItemsFromAPI.map(item => ({ id: item.id, name: item.lineItemType.name, lineItem: item.lineItemType })));
        }
        fetchLineItems();
    }, [selectedRecordType]);

    const handleRecordTypeChange = event => {
        const recordTypeId = event.target.value;
        const recordType = recordTypes.find(rt => rt.id === recordTypeId);
        setSelectedRecordType(recordType);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = Array.from(event.target.elements)
            .filter(element => element.name)
            .reduce((json, element) => {
                json[element.name] = element.value;
                return json;
            }, {});

        console.log("location", location.state);

        const newStudentRecord = {
            student_id: props.student.id,
            recordType_id: selectedRecordType.id,
            data: JSON.stringify(data),
            district_id: props.student.district_id,
            schoolYear_id: props.queryParams.classData.schoolYear_id,
            class_id: props.queryParams.classData.id,
            subject: props.subject ?? 'N/A',
            building_id: props.building,
            recordType_name: selectedRecordType.name,
            building_name: props.queryParams.classData.building_name,
            class_name: props.queryParams.classData.name,
            district_name: props.student.district_name,
            schoolYear_name: props.queryParams.classData.schoolYear_name,
        };

        props.setShowAddRecord((value) => !value);
        props.setShowTable((value) => !value);
        try {
           const response =  await API.graphql({
                query: createStudentRecords,
                variables: {input: newStudentRecord},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
           console.log("response", response);
            let o = notificationOptions("Class Created Successfully", "success");
            props.notificationAlertRef.current.notificationAlert(o);
        } catch (error) {
            console.error("Error creating student record:", error);
        }
    }

    useEffect(() => {
        async function createNewStudentRecords() {
            console.debug("studentRecords about to be created", studentRecords)
            if (!studentRecords) return;
            await API.graphql({
                query: createStudentRecords,
                variables: {input: studentRecords},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
        }
        createNewStudentRecords();
    }, [studentRecords]);

    return (
        <>
            <h1>{location.state?.student.name}</h1>
            <Card className={"horizontal-form"}>
                <Card.Header/>
                <Card.Body>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="recordType">
                    <Row>
                        <Col className="control-label" md="3">
                            <Form.Label>Select Record Type</Form.Label>
                        </Col>
                        <Col md="5">
                            <Form.Control as="select" onChange={handleRecordTypeChange}>
                                <option value="">--- Please select ---</option>
                                {recordTypes.map((recordType) => (
                                    <option key={recordType.id} value={recordType.id}>{recordType.name}</option>
                                ))}
                            </Form.Control>
                            </Col>
                    </Row>
                </Form.Group>
                {selectedLineItems.length > 0 ? (
                    selectedLineItems.map((lineItem) => {
                        let controlElement = null;
                        const options = lineItem.lineItem.definedOptions && JSON.parse(JSON.stringify(lineItem.lineItem.definedOptions));

                        if (options && options.length > 0) {
                            // If definedOptions has values, render a dropdown
                            controlElement = (
                                <Form.Control as="select" name={lineItem.name} required>
                                    {options.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </Form.Control>
                            );
                        } else {
                            // Otherwise, render based on line item type
                            switch (lineItem.lineItem.type) {
                                case "Text":
                                    controlElement = <Form.Control type="text" placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "MultiLineText":
                                    controlElement = <Form.Control as="textarea" rows={60} placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "Boolean":
                                    controlElement = (
                                        <>
                                            <Form.Check inline label="True" type="radio" name={lineItem.name} value="true" />
                                            <Form.Check inline label="False" type="radio" name={lineItem.name} value="false" />
                                        </>
                                    );
                                    break;
                                case "Number":
                                    controlElement = <Form.Control type="number" placeholder={`Enter value for ${lineItem.name}`} name={lineItem.name} required />;
                                    break;
                                case "Multiselect":
                                    controlElement = <p>Please define options for this Multiselect line item.</p>;
                                    break;
                                default:
                                    controlElement = <p>Unsupported line item type</p>;
                                    break;
                            }
                        }

                        return (

                                <Form.Group key={lineItem.id} controlId={lineItem.id}>
                                    <Row key={`row-${lineItem.id}`}>
                                        <Col className="control-label" md="3" key={`col-${lineItem.id}`}>
                                            {lineItem.lineItem.description}
                                        </Col>
                                        <Col md="5">
                                            {controlElement}
                                        </Col>
                                    </Row>
                                </Form.Group>

                        );
                    })
                ) : (
                    <p>No line items associated with this record type.</p>
                )}
                {selectedLineItems.length > 0 && (
                    <>
                        <Row>
                            <Col xs={1} md={1} lg={1}>
                                <Button variant="primary" className={'p-2'} type="submit">Submit</Button>
                            </Col>
                            <Col xs={1} md={1} lg={1}>
                                <Button
                                    className={'p-2'}
                                    variant="Primary"
                                    onClick={() => {
                                        props.setShowAddRecord(value => !value);
                                        props.setShowTable(value => !value);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
                </Card.Body>
            </Card>
        </>
    );
}
