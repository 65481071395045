import React, {useState} from "react";
import { Row } from "react-bootstrap";
import FileUpload from "../../../components/Files/FileUpload";
const Step1 = React.forwardRef((props, ref) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isValidated = () => {
    // do some validations
    // decide if you will
    if(selectedFiles && selectedFiles.length > 0) {
      return true;
    }
    return false;
  }
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
    state: {
      selectedFiles,
    },
  }));
  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <FileUpload
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </Row>
    </div>
  );
});
export default Step1;
