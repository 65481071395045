import {
    createUploads,
    updateUploads,
    deleteUploads
} from '../graphql/mutations';
import notificationOptions from '../views/Components/Notifications';
import { API } from 'aws-amplify';
const propertiesToDelete = [ "createdAt", "updatedAt", "_deleted", "_lastChangedAt", "__typename", "values"];
export const cFileType = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        // remove values from formData if it exists
        Object.keys(formData).forEach((property) => {
            if(propertiesToDelete.includes(property)){
                delete formData[property];
            }
        });
        if (formData.definition) formData.definition  = JSON.stringify(formData.definition);
        if (formData.type === "") formData.type  = formData.name;


        const result = await API.graphql({
            query: createUploads,
            variables: {
                input: {
                    ...formData,

                },
            },
        });

        const { data } = result;
        if (data) {
            let o = notificationOptions('File Type Definition Created Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Creating File Definition ${JSON.stringify(e)}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const uFileType = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        let itemUpdate = { ...current, ...formData}
        Object.keys(itemUpdate).forEach((property) => {
            if(propertiesToDelete.includes(property)){
                delete itemUpdate[property];
            }
        });
        if (formData.definition) formData.definition  = JSON.stringify(formData.definition);
        const result = await API.graphql({
            query: updateUploads,
            variables: {
                input: {
                    ...itemUpdate,
                },
            },
        });
        const { data } = result;



        if (data) {
            let o = notificationOptions('Record Updated Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
            hideAlert();
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Record While Updating Items${e}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}

export const dRecordType = async (item, hideAlert, notificationAlertRef) => {
    try{
        const result = await API.graphql(
            {
                query: deleteUploads,
                variables: {
                    input: {
                        id: item.id,
                        _version: item._version
                    }
                }
            }
        );

        const { data } = result;
        if (data) {
            hideAlert();
            let o = notificationOptions('Record Deleted Successfully', 'success');
            notificationAlertRef.current.notificationAlert(o);
        }
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Record While Deleting Items${e}`, 'danger');
        notificationAlertRef.current.notificationAlert(o);
    }
}
