import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { Table as BootstrapTable, Dropdown, DropdownButton, Pagination, Container, Row, Col } from 'react-bootstrap';
import "./Table.scss"

// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length;

    return (
        <input
            className="column-filter"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}
const TableV2 = (props) => {
    const [data, setData] = useState([...props.data]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [headers,setHeaders] = useState([...props.headers]);
    const CreatItemAction = props.CreatItemAction;

    useEffect(() => {
        console.debug("TableV2 props.headers", props.headers)
        setHeaders([...props.headers])
    }, [props.headers]);
    useEffect(() => {
        setData([...props.data])
    }, [props.data]);
    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading]);
    useEffect(() => {
        setPageCount(Math.ceil(data.length / pageSize));
    }, [data, pageSize]);

    const columns = useMemo(() => headers,[headers])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        gotoPage,
        // setPageSize: updatePageSize,
        state: { pageIndex},
    } = useTable(
        {
            columns,
            data,
            CreatItemAction,
            defaultColumn: {Filter: DefaultColumnFilter },
            initialState: { pageIndex: 0, pageSize },
        },
        useFilters,
        useSortBy,
        usePagination
    );
    // eslint-disable-next-line
     const handlePageSizeChange = (newPageSize) => {
        setPageSize(Number(newPageSize));
    };

    return (
        <Container fluid>
            { loading && (
                <Row>
                    <Col xs={12} className="text-center justify-content-center p-5">
                        <img src={require('../../assets/img/preloader.gif')}alt="loading..." />
                    </Col>
                </Row>
            )}
            {/* table structure */}
            {CreatItemAction ? <CreatItemAction /> : null}
            <BootstrapTable {...getTableProps()} striped bordered hover responsive hidden={loading}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-center column-width">
                                    {column.render('Header')}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                                </th>
                            ))}
                            {props.option && <th className="text-center">Actions</th>}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {!loading &&
                        page.map((row) => {
                            prepareRow(row);
                            if (row.original._deleted){
                                // console.debug("skipping deleted row", row);
                                return null;
                            } else {

                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            let value;
                                            if (typeof cell.value === 'object' && cell.value !== null && cell.value.hasOwnProperty('items')) {
                                                if (cell.value.items[0] !== null) {
                                                    value = cell.value.items.map(x => {
                                                        if (x.hasOwnProperty('buildings')) {
                                                            return x.buildings.name;
                                                        } else if (x.hasOwnProperty('lineItems')) {
                                                            return x.lineItems.name;
                                                        } else {
                                                            return '';
                                                        }
                                                    }).join(', ');
                                                } else {
                                                    value = '';
                                                }
                                            } else {
                                                value = cell.render('Cell');
                                            }
                                            return <td {...cell.getCellProps()} className="text-center h4">{value}</td>;
                                        })}
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </BootstrapTable>

           <Row>
               <Col xs={12}>
                    <Pagination>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        <Pagination.Item  className="pagination-item" >{pageIndex + 1}</Pagination.Item>
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
               </Col>
               <Col xs={12} md={6}>
                    <DropdownButton
                        value={pageSize}
                        onSelect={e => {
                            setPageSize(Number(e));
                        }}
                        title={`Show ${pageSize} items`}>
                        {[10, 20, 50, 100].map((size) => (
                            <Dropdown.Item key={size} eventKey={size}>
                                {size}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
               </Col>
               </Row>
        </Container>
    );
};

export default TableV2;

