import {useEffect} from "react";
import {API, graphqlOperation } from 'aws-amplify';
import {onUpdateSystemSettings} from "../../graphql/subscriptions";
import {systemSettingsByDistrict_id} from "../../graphql/queries";
async function fetchData(setSystemSettings, district_id) {
    const input = {district_id: district_id};
    const apiData = await API.graphql({query: systemSettingsByDistrict_id, variables: input});
    if (apiData.data.systemSettingsByDistrict_id.items.length > 0) {
        console.debug(apiData.data.systemSettingsByDistrict_id.items[0].settings)
        return setSystemSettings({...apiData.data.systemSettingsByDistrict_id.items[0]});
    }
    return {};
}
export const SubscribeSystemSettings = (district_id, setSystemSettings) => {

    useEffect(() => {
        fetchData(setSystemSettings, district_id);
        const subscription = API.graphql(graphqlOperation(onUpdateSystemSettings)).subscribe({
            next: (eventData) => {
                console.debug(eventData)
                fetchData(setSystemSettings, district_id)
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [district_id]) // Added missing dependencies
}
