import { API, graphqlOperation } from "aws-amplify";
import notificationOptions from "../views/Components/Notifications";
import {getClasses, usersClassesByClassesId} from "../graphql/queries";
import { createClasses, deleteClasses, createUsersClasses, updateClasses, deleteUsersClasses } from "../graphql/mutations";


export const createClass = async (formData, current, hideAlert, notificationAlertRef) => {
    let users = null;
    try {
        if (formData.students !== null) {
            users = [...formData["students"]];
            users.push(...formData["instructors"]);
            delete formData["students"];
            delete formData["instructors"];
            delete formData["values"]
        }

        if (!formData.hasOwnProperty("subject")) {
            formData["subject"] = "N/A";
        }
        // Use GraphQL to create a new class
        const newClassData = await API.graphql(
            graphqlOperation(createClasses, {
                input: { ...formData },
            })
        );
        const newClass = newClassData.data.createClasses;

        if (users !== null) {
            users.map(async (user) => {
                // Use GraphQL to create a new UsersClasses entry
                await API.graphql(
                    graphqlOperation(createUsersClasses, {
                        input: {
                            usersId: user.id,
                            classesId: newClass.id,
                        },
                    })
                );
            });
        }

        hideAlert();
        let o = notificationOptions("Class Created Successfully", "success");
        notificationAlertRef.current.notificationAlert(o);
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Creating Class ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const updateClass = async (formData, current, hideAlert, notificationAlertRef) => {
    try {
        let assignedUsers = [];
        let multiValues = {};
        multiValues["students"] = formData["students"];
        multiValues["instructors"] = formData["instructors"];
        if(!formData.hasOwnProperty("advanced")) {
            formData["advanced"] = "No";
        }
        if (formData.hasOwnProperty("students")) {
            if (multiValues["students"] !== null) {
                assignedUsers.push(...multiValues["students"]);
            }
        }
        if (formData.hasOwnProperty("instructors")) {
            if (multiValues["instructors"] !== null) {
                assignedUsers.push(...multiValues["instructors"]);
            }
        }

        // Use GraphQL to update the class
        const removeProperties = ["building", "createdAt", "recordTypes", "schoolYear", "standards", "student_records", "student_standards_records", "users", "_deleted", "_lastChangedAt", "updatedAt", "students", "instructors"];
        for (const prop of removeProperties) {
            delete formData[prop];
        }
        console.debug({...formData})
        await API.graphql(
            graphqlOperation(updateClasses, {
                input: { id: current.id, ...formData },
            })
        );

        // Query usersClasses for the current class
        const usersClassesData = await API.graphql(
            graphqlOperation(getClasses, {
                id: current.id,
            })
        );
        const usersClasses = usersClassesData.data.getClasses.users.items;

        const usersClassesSet = new Set(usersClasses.map(item => item.usersId));
        const assignedUsersSet = new Set(assignedUsers.map(item => item.id));

        // Iterate through usersClasses and update or delete mappings
        for (const item of usersClasses) {
            const selectedClass = assignedUsersSet.has(item.usersId);
            if (!selectedClass) {
                console.debug(`"Class ${current.id} does not exists for user ${item.usersId} deleting from class"`);
                await API.graphql(
                    graphqlOperation(deleteUsersClasses, {
                        input: {
                            id: item.id,
                            _version: item._version,
                        },
                    })
                );
            }
        }
        // // Iterate through assignedUsers and create mappings if not found
        // turn usersclasses into a set with all the usersid in it

        for (const item of assignedUsers) {
            // finds the items that are not in usersclassesset
            const selectedClass = usersClassesSet.has(item.id);

            if (!selectedClass) {
                console.debug(`"Class ${current.id} does not exists for user ${item.id} adding to class"`);
                await API.graphql(
                    graphqlOperation(createUsersClasses, {
                        input: {
                            usersId: item.id,
                            classesId: current.id,
                        },
                    })
                );
            }
        }

        hideAlert();
        let o = notificationOptions("User Updated Successfully", "success");
        notificationAlertRef.current.notificationAlert(o);
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Updating Staff Users ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

export const deleteClass = async (currentClass, hideAlert, notificationAlertRef) => {
    try {
        // Use GraphQL to delete the class
        await API.graphql(
            graphqlOperation(deleteClasses, {
                input: { id: currentClass.id, _version: currentClass._version },
            })
        );

        hideAlert();
        let o = notificationOptions("User Was Deleted Successfully", "success");
        notificationAlertRef.current.notificationAlert(o);
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }

    try{
        const usersClasses = await API.graphql(graphqlOperation(usersClassesByClassesId, {classesId: currentClass.id}));
        // Use the deleteUsersClasses mutation to delete the user class relationships in parallel
        console.debug("fetched users classes", usersClasses);
        await Promise.all(usersClasses.data.usersClassesByUsersId.items.map(async (userClass) => {
            API.graphql(graphqlOperation(deleteUsersClasses, {input: {id: userClass.id, _version: userClass._version}}));
        }));
        console.debug("Deleted all users classes");
    } catch (e) {
        console.error(e);
        hideAlert();
        let o = notificationOptions(`Error While Deleting Users Classes ${e}`, "danger");
        notificationAlertRef.current.notificationAlert(o);
    }
};

