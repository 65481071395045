import React, {useContext, useRef, useState, useEffect} from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import {listDistricts} from '../../graphql/customQueries';
import { createDistrict, deleteDistrict, updateDistrict } from "../../utils/db_districts_graph";
import {DistrictForm} from '../Forms/FormFields';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";

// import { Roles } from "../../models";


const headers = [
    'name'
];

const DistrictsView = (props) => {
    const currentUser = useContext(UserContext);
    // const [queryRole, setQueryRole] = React.useState(Roles.STAFF);
    const newItemForm = {values: {}};
    //eslint-disable-next-line
    const [query, setQuery] = useState(listDistricts);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] = useState({});
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);
    const hideAlert = () => {
        setAlert(null);
    };
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.Districts &&
            (currentUser.permissions.Districts.Update || currentUser.permissions.Districts.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    function CreatItemAction (){
        if(currentUser && currentUser.permissions.Districts.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.Districts.Create}
                    action={createDistrict}
                    currentFormData={newItemForm}
                    FormFields={DistrictForm}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`District`}
                />)
        } else {
            return null
        }
    }
    const getRowActions = (row) => {
        const actions = []; // default actions
        //if (currentUser.permissions[role].create) actions.push(createNewUsers);
        if (currentUser && currentUser.permissions.Districts.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Districts.Update,
                    action: updateDistrict,
                    FormFields: DistrictForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "District"
                }
            });
        }
        if (currentUser && currentUser.permissions.Districts.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.Districts.Delete,
                    action: deleteDistrict,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "District"
                }
            });
        }
        return actions;
    };


    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
        <Table
            permission={currentUser.permissions.DistrictAdmin}
            headers={headers}
            query={query}
            queryName="listDistricts"
            queryParams={queryParams}
            CreatItemAction={CreatItemAction}
            getRowActions={getRowActions}
            option={showActions}
            itemType="District"
        />
        </div>
    )
}

export default DistrictsView;
