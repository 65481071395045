import React, { useContext, useRef, useState, useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import Table from '../../components/Tables/Table';
import { listSchoolYears } from '../../graphql/queries';
import { createSchoolYear, updateSchoolYear, deleteSchoolYear } from "../../utils/db_schoolyears_graph";
import { SchoolYearForm} from '../Forms/FormFields';
import EditItemButton from "../../components/Buttons/EditItemButton";
import CreateItemButton from "../../components/Buttons/CreateItemButton";
import DeleteItemButton from "../../components/Buttons/DeleteItemButton";
import NotificationAlert from "react-notification-alert";





const SchoolYearView = (props) => {
    const currentUser = useContext(UserContext);
    // const [queryRole, setQueryRole] = React.useState(Roles.STAFF);
    const newItemForm = {values: {}};
    //eslint-disable-next-line
    const [query, setQuery] = useState(listSchoolYears);
    //eslint-disable-next-line
    const [queryParams, setQueryParams] = useState({
        filter: {
            district_id: {
                eq: currentUser.district_id
            }
        }
    });
    const notificationAlertRef = useRef(null);
    const [alert, setAlert] = React.useState(null);
    const [showActions, setShowActions] = useState(false);
    const [headers,setHeaders] = useState([
        'name',
        'startDate',
        'endDate'
    ]);
    useEffect(() => {
        const updateHeaders = () => {
            if (window.innerWidth <= 768) {  // For mobile screens
                setHeaders(['name']);
            } else {
                setHeaders([
                    'name',
                    'startDate',
                    'endDate']);
            }
        }

        // Initial call
        updateHeaders();

        // Add event listener for resizing
        window.addEventListener('resize', updateHeaders);

        // Cleanup
        return () => window.removeEventListener('resize', updateHeaders);
    }, []);  // Empty dependency array ensures this runs once when component mounts and cleans up on unmount
    useEffect(() => {
        if(currentUser &&
            currentUser.permissions.SchoolYear &&
            (currentUser.permissions.SchoolYear.Update || currentUser.permissions.SchoolYear.Delete)) {
            setShowActions(true);
        }
    }, [currentUser]);
    const hideAlert = () => {
        setAlert(null);
    };
    function CreatItemAction (){
        if(currentUser &&
            currentUser.permissions.SchoolYear &&
            currentUser.permissions.SchoolYear.Create){
            return(
                <CreateItemButton
                    permissions={currentUser.permissions.SchoolYear.Create}
                    action={createSchoolYear}
                    currentFormData={newItemForm}
                    FormFields={SchoolYearForm}
                    editItem={false}
                    hideAlert={hideAlert}
                    notificationAlertRef={notificationAlertRef}
                    setAlert={setAlert}
                    itemType={`SchoolYear`}
                />)
        } else {
            return null
        }
    }


    const getRowActions = (row) => {
        const actions = []; // default actions
        //if (currentUser.permissions[role].create) actions.push(createNewUsers);
        if (currentUser &&
            currentUser.permissions.SchoolYear &&
            currentUser.permissions.SchoolYear.Update){
            actions.push({
                component: EditItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.SchoolYear.Update,
                    action: updateSchoolYear,
                    FormFields: SchoolYearForm,
                    editItem: true,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "SchoolYear"
                }
            });
        }
        if (currentUser &&
            currentUser.permissions.SchoolYear &&
            currentUser.permissions.SchoolYear.Delete) {
            actions.push({
                component: DeleteItemButton,
                props: {
                    //itemQuery = {props.itemQuery},
                    permissions: currentUser.permissions.SchoolYear.Delete,
                    action: deleteSchoolYear,
                    hideAlert: hideAlert,
                    notificationAlertRef: notificationAlertRef,
                    item: row.original,
                    setAlert: setAlert,
                    itemType: "SchoolYear"
                }
            });
        }
        return actions;
    };

    return (
        <div>
            {alert}
            <NotificationAlert ref={notificationAlertRef} />
        <Table
            permission={currentUser.permissions.SchoolYear}
            headers={headers}
            query={query}
            queryName="listSchoolYears"
            queryParams={queryParams}
            CreatItemAction={CreatItemAction}
            getRowActions={getRowActions}
            option={showActions}
            showStudentLink={false}
        />
        </div>
    )
}

export default SchoolYearView;
