/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      userId
      stateId
      accessCardId
      email
      status
      firstName
      lastName
      image {
        bucket
        key
        region
        __typename
      }
      role
      cohort
      permissions
      cognito_user
      cognito_id
      cognito_created
      active
      in_school
      allow_lunch
      allow_building_entry
      current_class_id
      district_id
      district_name
      ais_support {
        nextToken
        startedAt
        __typename
      }
      demographics {
        nextToken
        startedAt
        __typename
      }
      alerts {
        nextToken
        startedAt
        __typename
      }
      groups_user {
        nextToken
        startedAt
        __typename
      }
      manager_user {
        nextToken
        startedAt
        __typename
      }
      buildings {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      student_classes {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standard_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInstructorClasses = /* GraphQL */ `
  mutation CreateInstructorClasses(
    $input: CreateInstructorClassesInput!
    $condition: ModelInstructorClassesConditionInput
  ) {
    createInstructorClasses(input: $input, condition: $condition) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstructorClasses = /* GraphQL */ `
  mutation UpdateInstructorClasses(
    $input: UpdateInstructorClassesInput!
    $condition: ModelInstructorClassesConditionInput
  ) {
    updateInstructorClasses(input: $input, condition: $condition) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstructorClasses = /* GraphQL */ `
  mutation DeleteInstructorClasses(
    $input: DeleteInstructorClassesInput!
    $condition: ModelInstructorClassesConditionInput
  ) {
    deleteInstructorClasses(input: $input, condition: $condition) {
      id
      name
      description
      instructor_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      instructor {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStudentsClasses = /* GraphQL */ `
  mutation CreateStudentsClasses(
    $input: CreateStudentsClassesInput!
    $condition: ModelStudentsClassesConditionInput
  ) {
    createStudentsClasses(input: $input, condition: $condition) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStudentsClasses = /* GraphQL */ `
  mutation UpdateStudentsClasses(
    $input: UpdateStudentsClassesInput!
    $condition: ModelStudentsClassesConditionInput
  ) {
    updateStudentsClasses(input: $input, condition: $condition) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStudentsClasses = /* GraphQL */ `
  mutation DeleteStudentsClasses(
    $input: DeleteStudentsClassesInput!
    $condition: ModelStudentsClassesConditionInput
  ) {
    deleteStudentsClasses(input: $input, condition: $condition) {
      id
      name
      description
      student_id
      userId
      stateId
      status
      class_id
      systemClassId
      schoolYear_id
      schoolYear_name
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDemographics = /* GraphQL */ `
  mutation CreateDemographics(
    $input: CreateDemographicsInput!
    $condition: ModelDemographicsConditionInput
  ) {
    createDemographics(input: $input, condition: $condition) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDemographics = /* GraphQL */ `
  mutation UpdateDemographics(
    $input: UpdateDemographicsInput!
    $condition: ModelDemographicsConditionInput
  ) {
    updateDemographics(input: $input, condition: $condition) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDemographics = /* GraphQL */ `
  mutation DeleteDemographics(
    $input: DeleteDemographicsInput!
    $condition: ModelDemographicsConditionInput
  ) {
    deleteDemographics(input: $input, condition: $condition) {
      id
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      foster_care
      homeless
      lep_eligibility
      parent_armed_forces
      poverty
      challenge_type
      ethnicity
      gender
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDistricts = /* GraphQL */ `
  mutation CreateDistricts(
    $input: CreateDistrictsInput!
    $condition: ModelDistrictsConditionInput
  ) {
    createDistricts(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDistricts = /* GraphQL */ `
  mutation UpdateDistricts(
    $input: UpdateDistrictsInput!
    $condition: ModelDistrictsConditionInput
  ) {
    updateDistricts(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDistricts = /* GraphQL */ `
  mutation DeleteDistricts(
    $input: DeleteDistrictsInput!
    $condition: ModelDistrictsConditionInput
  ) {
    deleteDistricts(input: $input, condition: $condition) {
      id
      name
      street
      city
      state
      zip
      phone
      website
      buildings {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBuildings = /* GraphQL */ `
  mutation CreateBuildings(
    $input: CreateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    createBuildings(input: $input, condition: $condition) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBuildings = /* GraphQL */ `
  mutation UpdateBuildings(
    $input: UpdateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    updateBuildings(input: $input, condition: $condition) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBuildings = /* GraphQL */ `
  mutation DeleteBuildings(
    $input: DeleteBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    deleteBuildings(input: $input, condition: $condition) {
      id
      name
      identifier
      district_id
      district_name
      street
      city
      state
      zip
      phone
      website
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAISSupport = /* GraphQL */ `
  mutation CreateAISSupport(
    $input: CreateAISSupportInput!
    $condition: ModelAISSupportConditionInput
  ) {
    createAISSupport(input: $input, condition: $condition) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAISSupport = /* GraphQL */ `
  mutation UpdateAISSupport(
    $input: UpdateAISSupportInput!
    $condition: ModelAISSupportConditionInput
  ) {
    updateAISSupport(input: $input, condition: $condition) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAISSupport = /* GraphQL */ `
  mutation DeleteAISSupport(
    $input: DeleteAISSupportInput!
    $condition: ModelAISSupportConditionInput
  ) {
    deleteAISSupport(input: $input, condition: $condition) {
      id
      support_type
      class_id
      status
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      student_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSchoolYears = /* GraphQL */ `
  mutation CreateSchoolYears(
    $input: CreateSchoolYearsInput!
    $condition: ModelSchoolYearsConditionInput
  ) {
    createSchoolYears(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSchoolYears = /* GraphQL */ `
  mutation UpdateSchoolYears(
    $input: UpdateSchoolYearsInput!
    $condition: ModelSchoolYearsConditionInput
  ) {
    updateSchoolYears(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSchoolYears = /* GraphQL */ `
  mutation DeleteSchoolYears(
    $input: DeleteSchoolYearsInput!
    $condition: ModelSchoolYearsConditionInput
  ) {
    deleteSchoolYears(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      district_name
      active
      district_id
      classes {
        nextToken
        startedAt
        __typename
      }
      district {
        id
        name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroups = /* GraphQL */ `
  mutation CreateGroups(
    $input: CreateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    createGroups(input: $input, condition: $condition) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroups = /* GraphQL */ `
  mutation UpdateGroups(
    $input: UpdateGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    updateGroups(input: $input, condition: $condition) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroups = /* GraphQL */ `
  mutation DeleteGroups(
    $input: DeleteGroupsInput!
    $condition: ModelGroupsConditionInput
  ) {
    deleteGroups(input: $input, condition: $condition) {
      id
      name
      description
      type
      district_id
      district_name
      schoolYear_id
      schoolYear_name
      notifications
      acidamic
      managers {
        nextToken
        startedAt
        __typename
      }
      users {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAlerts = /* GraphQL */ `
  mutation CreateAlerts(
    $input: CreateAlertsInput!
    $condition: ModelAlertsConditionInput
  ) {
    createAlerts(input: $input, condition: $condition) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAlerts = /* GraphQL */ `
  mutation UpdateAlerts(
    $input: UpdateAlertsInput!
    $condition: ModelAlertsConditionInput
  ) {
    updateAlerts(input: $input, condition: $condition) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAlerts = /* GraphQL */ `
  mutation DeleteAlerts(
    $input: DeleteAlertsInput!
    $condition: ModelAlertsConditionInput
  ) {
    deleteAlerts(input: $input, condition: $condition) {
      id
      name
      type
      active
      records
      isActive
      userId
      user {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClasses = /* GraphQL */ `
  mutation CreateClasses(
    $input: CreateClassesInput!
    $condition: ModelClassesConditionInput
  ) {
    createClasses(input: $input, condition: $condition) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClasses = /* GraphQL */ `
  mutation UpdateClasses(
    $input: UpdateClassesInput!
    $condition: ModelClassesConditionInput
  ) {
    updateClasses(input: $input, condition: $condition) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClasses = /* GraphQL */ `
  mutation DeleteClasses(
    $input: DeleteClassesInput!
    $condition: ModelClassesConditionInput
  ) {
    deleteClasses(input: $input, condition: $condition) {
      id
      systemClassId
      name
      description
      subject
      location
      period
      advanced
      schoolYear_name
      schoolYear_id
      building_name
      building_id
      district_id
      district_name
      color
      rgbActive
      rgbNotActive
      users {
        nextToken
        startedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordTypes {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      student_standards_records {
        nextToken
        startedAt
        __typename
      }
      grade
      standards {
        nextToken
        startedAt
        __typename
      }
      groups {
        nextToken
        startedAt
        __typename
      }
      instructor_classes {
        nextToken
        startedAt
        __typename
      }
      students_classes {
        nextToken
        startedAt
        __typename
      }
      attendance {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUploads = /* GraphQL */ `
  mutation CreateUploads(
    $input: CreateUploadsInput!
    $condition: ModelUploadsConditionInput
  ) {
    createUploads(input: $input, condition: $condition) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUploads = /* GraphQL */ `
  mutation UpdateUploads(
    $input: UpdateUploadsInput!
    $condition: ModelUploadsConditionInput
  ) {
    updateUploads(input: $input, condition: $condition) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUploads = /* GraphQL */ `
  mutation DeleteUploads(
    $input: DeleteUploadsInput!
    $condition: ModelUploadsConditionInput
  ) {
    deleteUploads(input: $input, condition: $condition) {
      id
      name
      type
      type_id
      file {
        bucket
        key
        region
        __typename
      }
      definition
      status
      is_definition
      schoolyear_id
      district_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRecordType = /* GraphQL */ `
  mutation CreateRecordType(
    $input: CreateRecordTypeInput!
    $condition: ModelRecordTypeConditionInput
  ) {
    createRecordType(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRecordType = /* GraphQL */ `
  mutation UpdateRecordType(
    $input: UpdateRecordTypeInput!
    $condition: ModelRecordTypeConditionInput
  ) {
    updateRecordType(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRecordType = /* GraphQL */ `
  mutation DeleteRecordType(
    $input: DeleteRecordTypeInput!
    $condition: ModelRecordTypeConditionInput
  ) {
    deleteRecordType(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      building_id
      standard_id
      standard_name
      all_classes
      multiple
      dataTable_name
      classes {
        nextToken
        startedAt
        __typename
      }
      LearningStandard {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      assignAble
      schemaOnly
      active
      lineItems {
        nextToken
        startedAt
        __typename
      }
      grade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance(
    $input: CreateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    createAttendance(input: $input, condition: $condition) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance(
    $input: UpdateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    updateAttendance(input: $input, condition: $condition) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAttendance = /* GraphQL */ `
  mutation DeleteAttendance(
    $input: DeleteAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    deleteAttendance(input: $input, condition: $condition) {
      id
      record_id
      date
      check_in
      check_out
      student_id
      class_id
      building_id
      schoolYear_id
      status
      description
      reason
      status_increment
      type
      district_id
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      class {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      building {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      schoolYear {
        id
        name
        startDate
        endDate
        district_name
        active
        district_id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLineItemType = /* GraphQL */ `
  mutation CreateLineItemType(
    $input: CreateLineItemTypeInput!
    $condition: ModelLineItemTypeConditionInput
  ) {
    createLineItemType(input: $input, condition: $condition) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLineItemType = /* GraphQL */ `
  mutation UpdateLineItemType(
    $input: UpdateLineItemTypeInput!
    $condition: ModelLineItemTypeConditionInput
  ) {
    updateLineItemType(input: $input, condition: $condition) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLineItemType = /* GraphQL */ `
  mutation DeleteLineItemType(
    $input: DeleteLineItemTypeInput!
    $condition: ModelLineItemTypeConditionInput
  ) {
    deleteLineItemType(input: $input, condition: $condition) {
      id
      name
      index
      description
      district_id
      type
      setOptions
      definedOptions
      recordType {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLearningStandards = /* GraphQL */ `
  mutation CreateLearningStandards(
    $input: CreateLearningStandardsInput!
    $condition: ModelLearningStandardsConditionInput
  ) {
    createLearningStandards(input: $input, condition: $condition) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLearningStandards = /* GraphQL */ `
  mutation UpdateLearningStandards(
    $input: UpdateLearningStandardsInput!
    $condition: ModelLearningStandardsConditionInput
  ) {
    updateLearningStandards(input: $input, condition: $condition) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLearningStandards = /* GraphQL */ `
  mutation DeleteLearningStandards(
    $input: DeleteLearningStandardsInput!
    $condition: ModelLearningStandardsConditionInput
  ) {
    deleteLearningStandards(input: $input, condition: $condition) {
      id
      name
      category
      description
      district_id
      grade
      image {
        bucket
        key
        region
        __typename
      }
      standard_code
      insight_description
      transfer_description
      student_standards_record {
        nextToken
        startedAt
        __typename
      }
      learning_Application {
        nextToken
        startedAt
        __typename
      }
      learning_Knowledge {
        nextToken
        startedAt
        __typename
      }
      record_types {
        nextToken
        startedAt
        __typename
      }
      student_records {
        nextToken
        startedAt
        __typename
      }
      classes {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLearningApplication = /* GraphQL */ `
  mutation CreateLearningApplication(
    $input: CreateLearningApplicationInput!
    $condition: ModelLearningApplicationConditionInput
  ) {
    createLearningApplication(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLearningApplication = /* GraphQL */ `
  mutation UpdateLearningApplication(
    $input: UpdateLearningApplicationInput!
    $condition: ModelLearningApplicationConditionInput
  ) {
    updateLearningApplication(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLearningApplication = /* GraphQL */ `
  mutation DeleteLearningApplication(
    $input: DeleteLearningApplicationInput!
    $condition: ModelLearningApplicationConditionInput
  ) {
    deleteLearningApplication(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLearningKnowledge = /* GraphQL */ `
  mutation CreateLearningKnowledge(
    $input: CreateLearningKnowledgeInput!
    $condition: ModelLearningKnowledgeConditionInput
  ) {
    createLearningKnowledge(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLearningKnowledge = /* GraphQL */ `
  mutation UpdateLearningKnowledge(
    $input: UpdateLearningKnowledgeInput!
    $condition: ModelLearningKnowledgeConditionInput
  ) {
    updateLearningKnowledge(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLearningKnowledge = /* GraphQL */ `
  mutation DeleteLearningKnowledge(
    $input: DeleteLearningKnowledgeInput!
    $condition: ModelLearningKnowledgeConditionInput
  ) {
    deleteLearningKnowledge(input: $input, condition: $condition) {
      id
      name
      description
      district_id
      standard_name
      standard_id
      learning_Standards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStudentStandardsRecords = /* GraphQL */ `
  mutation CreateStudentStandardsRecords(
    $input: CreateStudentStandardsRecordsInput!
    $condition: ModelStudentStandardsRecordsConditionInput
  ) {
    createStudentStandardsRecords(input: $input, condition: $condition) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStudentStandardsRecords = /* GraphQL */ `
  mutation UpdateStudentStandardsRecords(
    $input: UpdateStudentStandardsRecordsInput!
    $condition: ModelStudentStandardsRecordsConditionInput
  ) {
    updateStudentStandardsRecords(input: $input, condition: $condition) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStudentStandardsRecords = /* GraphQL */ `
  mutation DeleteStudentStandardsRecords(
    $input: DeleteStudentStandardsRecordsInput!
    $condition: ModelStudentStandardsRecordsConditionInput
  ) {
    deleteStudentStandardsRecords(input: $input, condition: $condition) {
      id
      name
      description
      record_type
      district_id
      student_id
      class_id
      building_id
      schoolYear_id
      standard_name
      standard_id
      application_name
      application_id
      knowledge_id
      knowledge_name
      insight_name
      transfer_name
      file {
        bucket
        key
        region
        __typename
      }
      achieved
      student {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStudentRecords = /* GraphQL */ `
  mutation CreateStudentRecords(
    $input: CreateStudentRecordsInput!
    $condition: ModelStudentRecordsConditionInput
  ) {
    createStudentRecords(input: $input, condition: $condition) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStudentRecords = /* GraphQL */ `
  mutation UpdateStudentRecords(
    $input: UpdateStudentRecordsInput!
    $condition: ModelStudentRecordsConditionInput
  ) {
    updateStudentRecords(input: $input, condition: $condition) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStudentRecords = /* GraphQL */ `
  mutation DeleteStudentRecords(
    $input: DeleteStudentRecordsInput!
    $condition: ModelStudentRecordsConditionInput
  ) {
    deleteStudentRecords(input: $input, condition: $condition) {
      id
      name
      subject
      date
      student_id
      recordType_id
      recordType_name
      standard_id
      class_id
      class_name
      district_id
      district_name
      building_id
      building_name
      schoolYear_id
      schoolYear_name
      dataTable_name
      data
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      id
      user_id
      settings
      dashboards
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSystemSettings = /* GraphQL */ `
  mutation CreateSystemSettings(
    $input: CreateSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    createSystemSettings(input: $input, condition: $condition) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSystemSettings = /* GraphQL */ `
  mutation UpdateSystemSettings(
    $input: UpdateSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    updateSystemSettings(input: $input, condition: $condition) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSystemSettings = /* GraphQL */ `
  mutation DeleteSystemSettings(
    $input: DeleteSystemSettingsInput!
    $condition: ModelSystemSettingsConditionInput
  ) {
    deleteSystemSettings(input: $input, condition: $condition) {
      id
      district_id
      settings
      theme
      routes
      permissions
      notifications
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLLMQuery = /* GraphQL */ `
  mutation CreateLLMQuery(
    $input: CreateLLMQueryInput!
    $condition: ModelLLMQueryConditionInput
  ) {
    createLLMQuery(input: $input, condition: $condition) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLLMQuery = /* GraphQL */ `
  mutation UpdateLLMQuery(
    $input: UpdateLLMQueryInput!
    $condition: ModelLLMQueryConditionInput
  ) {
    updateLLMQuery(input: $input, condition: $condition) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLLMQuery = /* GraphQL */ `
  mutation DeleteLLMQuery(
    $input: DeleteLLMQueryInput!
    $condition: ModelLLMQueryConditionInput
  ) {
    deleteLLMQuery(input: $input, condition: $condition) {
      id
      user_id
      conversation
      sql_statement
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsersGroups = /* GraphQL */ `
  mutation CreateUsersGroups(
    $input: CreateUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    createUsersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsersGroups = /* GraphQL */ `
  mutation UpdateUsersGroups(
    $input: UpdateUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    updateUsersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsersGroups = /* GraphQL */ `
  mutation DeleteUsersGroups(
    $input: DeleteUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    deleteUsersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createManagersGroups = /* GraphQL */ `
  mutation CreateManagersGroups(
    $input: CreateManagersGroupsInput!
    $condition: ModelManagersGroupsConditionInput
  ) {
    createManagersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateManagersGroups = /* GraphQL */ `
  mutation UpdateManagersGroups(
    $input: UpdateManagersGroupsInput!
    $condition: ModelManagersGroupsConditionInput
  ) {
    updateManagersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteManagersGroups = /* GraphQL */ `
  mutation DeleteManagersGroups(
    $input: DeleteManagersGroupsInput!
    $condition: ModelManagersGroupsConditionInput
  ) {
    deleteManagersGroups(input: $input, condition: $condition) {
      id
      usersId
      groupsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsersBuildings = /* GraphQL */ `
  mutation CreateUsersBuildings(
    $input: CreateUsersBuildingsInput!
    $condition: ModelUsersBuildingsConditionInput
  ) {
    createUsersBuildings(input: $input, condition: $condition) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsersBuildings = /* GraphQL */ `
  mutation UpdateUsersBuildings(
    $input: UpdateUsersBuildingsInput!
    $condition: ModelUsersBuildingsConditionInput
  ) {
    updateUsersBuildings(input: $input, condition: $condition) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsersBuildings = /* GraphQL */ `
  mutation DeleteUsersBuildings(
    $input: DeleteUsersBuildingsInput!
    $condition: ModelUsersBuildingsConditionInput
  ) {
    deleteUsersBuildings(input: $input, condition: $condition) {
      id
      usersId
      buildingsId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buildings {
        id
        name
        identifier
        district_id
        district_name
        street
        city
        state
        zip
        phone
        website
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUsersClasses = /* GraphQL */ `
  mutation CreateUsersClasses(
    $input: CreateUsersClassesInput!
    $condition: ModelUsersClassesConditionInput
  ) {
    createUsersClasses(input: $input, condition: $condition) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUsersClasses = /* GraphQL */ `
  mutation UpdateUsersClasses(
    $input: UpdateUsersClassesInput!
    $condition: ModelUsersClassesConditionInput
  ) {
    updateUsersClasses(input: $input, condition: $condition) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUsersClasses = /* GraphQL */ `
  mutation DeleteUsersClasses(
    $input: DeleteUsersClassesInput!
    $condition: ModelUsersClassesConditionInput
  ) {
    deleteUsersClasses(input: $input, condition: $condition) {
      id
      usersId
      classesId
      users {
        id
        userId
        stateId
        accessCardId
        email
        status
        firstName
        lastName
        role
        cohort
        permissions
        cognito_user
        cognito_id
        cognito_created
        active
        in_school
        allow_lunch
        allow_building_entry
        current_class_id
        district_id
        district_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClassesGroups = /* GraphQL */ `
  mutation CreateClassesGroups(
    $input: CreateClassesGroupsInput!
    $condition: ModelClassesGroupsConditionInput
  ) {
    createClassesGroups(input: $input, condition: $condition) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClassesGroups = /* GraphQL */ `
  mutation UpdateClassesGroups(
    $input: UpdateClassesGroupsInput!
    $condition: ModelClassesGroupsConditionInput
  ) {
    updateClassesGroups(input: $input, condition: $condition) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClassesGroups = /* GraphQL */ `
  mutation DeleteClassesGroups(
    $input: DeleteClassesGroupsInput!
    $condition: ModelClassesGroupsConditionInput
  ) {
    deleteClassesGroups(input: $input, condition: $condition) {
      id
      groupsId
      classesId
      groups {
        id
        name
        description
        type
        district_id
        district_name
        schoolYear_id
        schoolYear_name
        notifications
        acidamic
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClassesRecordType = /* GraphQL */ `
  mutation CreateClassesRecordType(
    $input: CreateClassesRecordTypeInput!
    $condition: ModelClassesRecordTypeConditionInput
  ) {
    createClassesRecordType(input: $input, condition: $condition) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClassesRecordType = /* GraphQL */ `
  mutation UpdateClassesRecordType(
    $input: UpdateClassesRecordTypeInput!
    $condition: ModelClassesRecordTypeConditionInput
  ) {
    updateClassesRecordType(input: $input, condition: $condition) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClassesRecordType = /* GraphQL */ `
  mutation DeleteClassesRecordType(
    $input: DeleteClassesRecordTypeInput!
    $condition: ModelClassesRecordTypeConditionInput
  ) {
    deleteClassesRecordType(input: $input, condition: $condition) {
      id
      classesId
      recordTypeId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createClassesLearningStandards = /* GraphQL */ `
  mutation CreateClassesLearningStandards(
    $input: CreateClassesLearningStandardsInput!
    $condition: ModelClassesLearningStandardsConditionInput
  ) {
    createClassesLearningStandards(input: $input, condition: $condition) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClassesLearningStandards = /* GraphQL */ `
  mutation UpdateClassesLearningStandards(
    $input: UpdateClassesLearningStandardsInput!
    $condition: ModelClassesLearningStandardsConditionInput
  ) {
    updateClassesLearningStandards(input: $input, condition: $condition) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClassesLearningStandards = /* GraphQL */ `
  mutation DeleteClassesLearningStandards(
    $input: DeleteClassesLearningStandardsInput!
    $condition: ModelClassesLearningStandardsConditionInput
  ) {
    deleteClassesLearningStandards(input: $input, condition: $condition) {
      id
      classesId
      learningStandardsId
      classes {
        id
        systemClassId
        name
        description
        subject
        location
        period
        advanced
        schoolYear_name
        schoolYear_id
        building_name
        building_id
        district_id
        district_name
        color
        rgbActive
        rgbNotActive
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      learningStandards {
        id
        name
        category
        description
        district_id
        grade
        standard_code
        insight_description
        transfer_description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRecordTypeLineItems = /* GraphQL */ `
  mutation CreateRecordTypeLineItems(
    $input: CreateRecordTypeLineItemsInput!
    $condition: ModelRecordTypeLineItemsConditionInput
  ) {
    createRecordTypeLineItems(input: $input, condition: $condition) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRecordTypeLineItems = /* GraphQL */ `
  mutation UpdateRecordTypeLineItems(
    $input: UpdateRecordTypeLineItemsInput!
    $condition: ModelRecordTypeLineItemsConditionInput
  ) {
    updateRecordTypeLineItems(input: $input, condition: $condition) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRecordTypeLineItems = /* GraphQL */ `
  mutation DeleteRecordTypeLineItems(
    $input: DeleteRecordTypeLineItemsInput!
    $condition: ModelRecordTypeLineItemsConditionInput
  ) {
    deleteRecordTypeLineItems(input: $input, condition: $condition) {
      id
      recordTypeId
      lineItemTypeId
      recordType {
        id
        name
        description
        district_id
        building_id
        standard_id
        standard_name
        all_classes
        multiple
        dataTable_name
        assignAble
        schemaOnly
        active
        grade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lineItemType {
        id
        name
        index
        description
        district_id
        type
        setOptions
        definedOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
